import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { IPatientSummary } from "../../../../services/modules/queryBuilder/patientSummary";
import startCase from "lodash.startcase";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PatientDetails = ({ item }: { item: IPatientSummary; value: string }) => {
  return (
    <Box
      mt={2}
      sx={{
        overflowY: "auto",
      }}
    >
      <Stack>
        <Typography variant="h4" color="textSecondary">
          Summary
        </Typography>
        {item.summary}
      </Stack>
      {item?.data.length > 0 && (
        <TableContainer sx={{ mt: 3, maxHeight: "1000px" }}>
          <Table
            size="small"
            sx={{
              tableLayout: "auto",
              border: "1px solid gray",
              borderRadius: "6px",
              borderCollapse: "collapse",
            }}
          >
            <TableBody>
              {Object.keys(item.data[0]).map((key) => (
                <StyledTableRow key={key}>
                  <TableCell
                    sx={(theme) => ({
                      backgroundColor: theme.palette.common.brandBackground,
                      position: "sticky",
                      top: 0,
                      left: 0,
                      zIndex: 800,
                      border: "1px solid gray",
                      whiteSpace: "nowrap",
                    })}
                  >
                    {startCase(key)}:
                  </TableCell>
                  {item.data.map((items) => (
                    <TableCell
                      sx={{
                        border: "1px solid gray",
                      }}
                    >
                      {items[key]}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default PatientDetails;
