import { Box, Chip, Grid } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import React, { FC } from "react";
import StyledDataGrid from "../../components/Datagrid/StyledDatagrid";
import { IUserDetails } from "../../../services/modules/admin/getUsersAll";
import ActionMenu from "../../components/Common/ActionMenu";
import dayjs from "dayjs";

interface UserTableProps {
  users: IUserDetails[];
  onEdit: (a: IUserDetails) => void;
  onDelete: (a: IUserDetails) => void;
  isLoading: boolean;
  isDisplay?: boolean;
}

const UserTable: FC<UserTableProps> = ({
  users,
  onDelete,
  onEdit,
  isLoading,
  isDisplay,
}) => {
  const columns = React.useMemo<GridColDef<IUserDetails>[]>(() => {
    return [
      {
        field: "first_name",
        headerName: "First Name",
        minWidth: 200,
      },
      {
        field: "last_name",
        headerName: "Last Name",
        minWidth: 200,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 300,
      },
      {
        field: "group_name",
        headerName: "Group Name",
        minWidth: 200,
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 200,
      },
      {
        field: "Type",
        headerName: "Type",
        minWidth: 200,
        renderCell: (params) => {
          const { is_group_admin, is_super_admin } = params.row;
          if (is_super_admin) {
            return (
              <Chip
                size="small"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.warning,
                })}
                label={"Super Admin"}
              />
            );
          } else if (is_group_admin) {
            return (
              <Chip
                size="small"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.info,
                })}
                label={"Group Admin"}
              />
            );
          } else {
            return (
              <Chip
                size="small"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.success,
                })}
                label={"Group User"}
              />
            );
          }
        },
      },
      {
        field: "Last_Modified",
        headerName: "Last Updated",
        minWidth: 200,
        renderCell: (params) =>
          dayjs(params.row.Last_Modified).format("MM-DD-YYYY hh:mm a"),
      },
      {
        field: " ",
        width: 100,
        flex: 1,
        renderCell: (params) => (
          <Grid container alignItems={"center"} justifyContent={"flex-end"}>
            <ActionMenu
              items={[
                {
                  label: "Edit",
                  onClick: () => {
                    onEdit(params.row);
                  },
                },
                {
                  label: "Delete",
                  disabled:
                    params.row.is_group_admin || params.row.is_super_admin,
                  onClick: () => {
                    onDelete(params.row);
                  },
                },
              ]}
            />
          </Grid>
        ),
      },
    ];
  }, []);
  return (
    <div style={{ height: 450, width: "100%", marginTop: "20px" }}>
      <StyledDataGrid
        rows={users}
        //@ts-expect-error
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        isRowSelectable={(params: GridRowParams) =>
          !(params.row.is_group_admin || params.row.is_super_admin)
        }
        onRowSelectionModelChange={(a) => {
  
        }}
        getRowId={(row) => row.email}
        checkboxSelection={!isDisplay}
        disableRowSelectionOnClick
        loading={isLoading}
      />
    </div>
  );
};

export default UserTable;
