import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2IconContainer,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import {
  unstable_useTreeItem2 as useTreeItem,
  UseTreeItem2Parameters,
} from "@mui/x-tree-view/useTreeItem2";
import clsx from "clsx";
import * as React from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { MdGrading } from "react-icons/md";
import SearchBar from "../../../components/Search/SearchBar";
import AppDrawer from "../../../components/SideDrawer";
import useDebounce from "../../../hooks/useDebounceCallBack";
import { DRAWER_WIDTH } from "../../../layout/DashboardNavbar";
import { DEFAULT_SELECTED_COLUMNS } from "../../../constants";

const DEFAULT_SELECTED_ITEMS = [
  "C_Demographics_Patient Id",
  "C_Demographics_Patient First Name",
  "C_Demographics_Patient Last Name",
  "C_Demographics_Patient Age",
  "C_Visit_Time Since Last Visit",
];

interface StyledTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    React.HTMLAttributes<HTMLLIElement> {
  bgColor?: string;
  color?: string;
  onItemSelect: (any: any) => {};
  parent?: string;
  expanded: boolean;
  isParent: boolean;
}

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }): any => ({
  marginBottom: 0,
  paddingRight: theme.spacing(1),
  borderBottom: `1px solid #C3C3C3`,
  borderRadius: 0,
  fontWeight: theme.typography.fontWeightMedium,
  "&.expanded": {
    fontWeight: theme.typography.fontWeightRegular,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.focused, &.selected, &.selected.focused": {
    backgroundColor: "white",
  },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  })
);

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
  ({ theme }) => ({
    marginLeft: 0,
    [`& .content`]: {
      paddingLeft: theme.spacing(2),
    },
  })
);

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: StyledTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    onItemSelect,
    parent,
    expanded,
    isParent,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });

  return (
    // @ts-ignore
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps({ ...other })}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx("content", {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
            }),
            onClick: (e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
            },
            status: status,
          })}
          sx={{
            padding: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              p: 0.5,
              pr: 0,
              minHeight: "48px",
            }}
          >
            {!isParent && (
              <Checkbox
                disabled={
                  label === "Patient_Id" ||
                  label === "Patient Id" ||
                  itemId === "Patient_Id" ||
                  itemId === "Patient Id"
                }
                checked={status.selected}
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onItemSelect({ label, parent, itemId });
                }}
                data-testid="custome-tree"
              />
            )}
            <Typography
              {...getLabelProps({
                sx: {
                  display: "flex",
                  flexGrow: 1,
                  fontSize: "0.729vw",
                  textTransform: "capitalize",
                  fontWeight: parent ? "normal" : "bold",
                },
              })}
            />
          </Box>
          <CustomTreeItemIconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </CustomTreeItemIconContainer>
        </CustomTreeItemContent>
        {children && (
          <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

function EndIcon() {
  return <div style={{ width: 24 }} />;
}

export default function GmailTreeView({
  staticData,
  applyColumnFilters,
  appliedColumns,
}: {
  staticData: any;
  applyColumnFilters: any;
  appliedColumns: any;
}) {
  const [filterStaticData, setFilterStaticData] = React.useState(staticData);
  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    DEFAULT_SELECTED_ITEMS
  );
  const [filters, setFilterData] = React.useState<any>(
    DEFAULT_SELECTED_COLUMNS
  );
  const [showMaxSelected, setShowMaxSelected] = React.useState(false);
  const [parentIds, setParentItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    var parentIds: string[] = [];
    Object.keys(staticData).forEach((item) => {
      parentIds = [...parentIds, "P_" + item];
    });
    setParentItems(parentIds);
  }, []);

  React.useEffect(() => {
    setFilterStaticData(staticData);
    var parentIds: string[] = [];
    Object.keys(staticData).forEach((item) => {
      parentIds = [...parentIds, "P_" + item];
    });
    setParentItems(parentIds);
  }, [staticData]);

  const [value, setValue] = React.useState("");
  const debouncedValue = useDebounce(value, 500);

  const searchFilter = (searchString: string) => {
    const filtered: any = {};
    Object.keys(staticData).forEach((key) => {
      // Match the Parent keys
      if (key?.toLowerCase().includes(searchString?.toLowerCase())) {
        filtered[key] = staticData[key];
      } else {
        /* istanbul ignore next */
        const filteredChild: any = {};
        // Match the child key and add them to the parent and push
        /* istanbul ignore next */
        Object.keys(staticData[key]).forEach((child) => {
          if (child?.toLowerCase().includes(searchString?.toLowerCase())) {
            filteredChild[child] = staticData[key][child];
          }
        });
        /* istanbul ignore next */
        if (Object.keys(filteredChild).length > 0) {
          filtered[key] = filteredChild;
        }
      }
    });
    setFilterStaticData(filtered);
  };

  React.useEffect(() => {
    searchFilter(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleSelectedItemsChange = ({
    label,
    itemId,
    parent: parentId, // parent element
  }: {
    label: string;
    parent: string;
    itemId: string;
  }) => {
    let items: string[] = [];
    const parent = parentId ? parentId.substring(2) : undefined;

    const parentItems = Object.keys(staticData[parent ?? ""]).map(
      (key) => `C_${parent}_${key}`
    );

    // if not parent
    // Check if label is already selected
    if (selectedItems.indexOf(itemId) !== -1) {
      items = selectedItems.filter((item) => item !== itemId);
      // remove parent from selected if all the child items are removed
      const check = items.filter((item) => parentItems.includes(item));
      /* istanbul ignore next */
      if (check.length === 0) {
        items = items.filter((item) => item !== parentId);
      }
    } else {
      /* istanbul ignore next */
      items = selectedItems.concat(itemId);
      // check if the parent is selected
      // Check if all child items are selected under the parent then select the parent
      /* istanbul ignore next */
      if (parentItems.every((item) => items.includes(item))) {
        items = items.concat(parentId);
      }
    }

    // Check
    const filterData = JSON.parse(JSON.stringify(filters));

    if (parent) {
      // CHILD ITEM
      // this is a child item
      if (filterData[parent] && filterData[parent][label]) {
        // filter already present
        filterData[parent][label] = undefined;
        delete filterData[parent][label];
      } else {
        /* istanbul ignore next */
        if (
          staticData[parent] &&
          staticData[parent][label] &&
          staticData[parent][label].ActualTable
        ) {
          // filter not present
          /* istanbul ignore next */
          if (!filterData[parent]) {
            filterData[parent] = {};
          }
          filterData[parent][label] = {
            ActualTable: staticData[parent][label].ActualTable,
            ActualColumn: staticData[parent][label].ActualColumn,
          };
        }
      }
    }

    setSelectedItems(items);
    setFilterData(filterData);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setValue("");
    setOpen(false);
  };

  React.useEffect(() => {
    let groupUnique = {};
    Object.keys(filters).forEach((items) => {
      const itemsChild = Object.keys(filters[items]);
      if (itemsChild.length > 0) {
        // groupSelected = groupSelected + 1;
        itemsChild.forEach((item) => {
          //@ts-ignore
          if (groupUnique[filters[items][item]?.ActualTable]) {
            //@ts-ignore
            groupUnique[filters[items][item].ActualTable] = +1;
          } else {
            // @ts-ignore
            groupUnique[filters[items][item].ActualTable] = 1;
          }
        });
      }
    });
    const groupSelected = Object.keys(groupUnique).length;
    setShowMaxSelected(groupSelected > 4);
  }, [filters]);

  const getNoOfColumnsSelected = () => {
    return selectedItems.length;
  };

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"}>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<MdGrading />}
        data-testid="open-app"
      >
        Select Attributes
      </Button>
      <AppDrawer
        open={open}
        toggleDrawer={() => {
          setOpen(!open);
        }}
        clearModify={() => {
          //Not Overridden
        }}
      >
        <Stack direction={"row"}>
          <Box
            sx={{
              height: "100vh",
              minWidth: DRAWER_WIDTH,
              p: 2,
              overflow: "auto",
            }}
          >
            <Typography variant="h2" mb={2}>
              Select Columns to display
            </Typography>
            <SearchBar
              placeholder="Search Columns"
              onSearch={(value) => {
                setValue(value);
              }}
              onBlur={() => {
                setValue("");
              }}
              clearOnBlur
              data-testid="search"
            />
            <Box>
              <SimpleTreeView
                aria-label="patient-filters"
                slots={{
                  expandIcon: BiPlus,
                  collapseIcon: BiMinus,
                  endIcon: EndIcon,
                }}
                sx={{ flexGrow: 1 }}
                multiSelect
                selectedItems={selectedItems}
                defaultExpandedItems={parentIds}
              >
                {Object.keys(filterStaticData)?.map((fl) => (
                  <CustomTreeItem
                    itemId={`P_${fl}`}
                    key={`P_${fl}`}
                    label={fl}
                    isParent
                    // @ts-expect-error
                    onItemSelect={handleSelectedItemsChange}
                    //expanded={expanded}
                  >
                    {Object.keys(filterStaticData[fl])?.map((fVal: string) =>
                      staticData[fl][fVal]["ActualTable"] !== null ? (
                        <CustomTreeItem
                          itemId={`C_${fl}_${fVal}`}
                          label={fVal}
                          parent={`P_${fl}`}
                          key={`C_${fl}_${fVal}`}
                          // @ts-expect-error
                          onItemSelect={handleSelectedItemsChange}
                          // expanded={expanded}
                        />
                      ) : null
                    )}
                  </CustomTreeItem>
                ))}
              </SimpleTreeView>
            </Box>
          </Box>
          <Divider orientation="vertical" />
          {selectedItems.length > 0 && (
            <Box
              sx={{ height: "100vh", width: "30vw", p: 2, overflow: "auto" }}
            >
              <Typography variant="h2" mb={2}>
                Selected Columns
              </Typography>
              <Table size="small">
                {selectedItems.map((item) => {
                  if (item.startsWith("P_")) {
                    return null;
                  }

                  const label = item.startsWith("C_")
                    ? item.substring(item.lastIndexOf("_") + 1)
                    : item.replace("P_", "");
                  return (
                    <TableRow key={item}>
                      <TableCell>{label}</TableCell>
                      <TableCell>
                        {label.toLowerCase() === "patient_id" ||
                        label.toLowerCase() === "patient id" ? null : (
                          <IconButton
                            size="small"
                            disabled={
                              label.toLowerCase() === "patient_id" ||
                              label.toLowerCase() === "patient id"
                            }
                            onClick={() => {
                              handleSelectedItemsChange({
                                label,
                                itemId: item,
                                parent: "P_" + item.split("_")[1],
                              });
                            }}
                            data-testid="items-select"
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
              <Stack
                my={1}
                px={1}
                py={0.5}
                sx={{
                  position: "sticky",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "white",
                  zIndex: 50,
                  maxWidth: "30vw",
                }}
              >
                {showMaxSelected || getNoOfColumnsSelected() > 10 ? (
                  <Typography variant="body1" sx={{ color: "#E41F35" }}>
                    {getNoOfColumnsSelected() > 10
                      ? "Selecting more than 10 Attributes can impact tool performance"
                      : "Maximum number of groups selected can be 4. Please deselect and select new group to continue."}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ m: 0.5, mb: 1 }}
                  >
                    {getNoOfColumnsSelected() === 0
                      ? "Please select at least 1 column to continue"
                      : getNoOfColumnsSelected() + " Columns Selected"}
                  </Typography>
                )}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      // handleSelectedItemsChange({label:'All',parent:'',itemId: ALL_ITEM_ID})
                      setSelectedItems(["C_Demographics_Patient Id"]);
                      setFilterData({
                        Demographics: {
                          "Patient Id": {
                            ActualTable: "patient",
                            ActualColumn: "Patient_Id",
                          },
                        },
                      });
                      setValue("");
                    }}
                    data-testid="clear-btn"
                  >
                    Clear All
                  </Button>
                  <Stack direction={"row"} spacing={2}>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      size="small"
                      data-testid="cancel-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleClose();
                        applyColumnFilters(filters);
                      }}
                      disabled={
                        showMaxSelected ||
                        selectedItems.length === 0 ||
                        selectedItems.length > 10
                      }
                      data-testid="apply-filter"
                    >
                      Apply
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>
      </AppDrawer>
    </Stack>
  );
}
