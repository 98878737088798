/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetTokenMutation,
  useLazyAuthenticateUserQuery,
  useLazyGetFacilitiesQuery,
} from "../../services/modules/apgee";
import { setApToken, setUserAppToken } from "../../store/reducers/LoginReducer";
import config from "../config";
import { RootState } from "../../store/configureStore";
import { useGetAppTokenMutation } from "../../services/modules/queryBuilder";
import { checkAlabamaFaciltyUser, getUserFacility } from "./utils";

const ProtectedRoute = ({ children }: { children: React.ReactNode | any }) => {
  const { userInfo } = useSelector((state: RootState) => state.LoginReducer);
  const [loadingFacility, setLoadingFacility] = React.useState(false);
  const [tok, setTok] = React.useState("");
  const [userAccess, setUserAcess] = React.useState(false);
  const [getToken, { isLoading, isError, data: tokenData }] =
    useGetTokenMutation();
  const [
    getAppToken,
    {
      isLoading: isLoadingAppToken,
      isError: isErrorAppToken,
      data: tokenDataAppToken,
    },
  ] = useGetAppTokenMutation();
  const [getUserData, { isLoading: userDataLoading }] =
    useLazyAuthenticateUserQuery();
  const [getFacilities, { isLoading: facilitiesLoading }] =
    useLazyGetFacilitiesQuery();

  const apToken: string = useSelector(
    (state: RootState) => state.LoginReducer.apToken
  );
  const userAppToken: string = useSelector(
    (state: RootState) => state.LoginReducer.token
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    getToken({
      username: config.apigee.apiGeeUserName,
      password: config.apigee.apiGeePassword,
    });
  }, []);

  React.useEffect(() => {
    getAppToken({
      username: config.apigee.apiAuthUsername,
      password: config.apigee.apiAuthPassword,
    });
  }, []);

  React.useEffect(() => {
    if (tokenData) {
      dispatch(setApToken(tokenData.access_token));
    }
  }, [tokenData]);

  React.useEffect(() => {
    if (tokenDataAppToken) {
      dispatch(setUserAppToken(tokenDataAppToken.access_token));
      sessionStorage.setItem("AppToken", tokenDataAppToken.access_token);
    }
  }, [tokenDataAppToken]);

  const verifyUser = async () => {
    setLoadingFacility(true);
    const resp = await getUserData({
      userId: userInfo?.preferred_username ?? "",
    });

    const facilites = await getFacilities({
      userId: userInfo?.preferred_username ?? "",
    });

    // Check if the user has access
    const facilityId = getUserFacility(resp.data?.userAccess.split(",") ?? []);
    if (!facilityId) {
        setLoadingFacility(false)
       window.history.back()
      // Show error message
      return;
    }

    // Check if the facility exists
    const hasAccess: any = checkAlabamaFaciltyUser(
      facilites?.data ?? [],
      facilityId
    );
    if (hasAccess) {
      setUserAcess(true);
      setLoadingFacility(false);
      return;
    } else {
      setUserAcess(false);
      setLoadingFacility(false);
      window.history.back();
    }
  };

  React.useEffect(() => {
    if (apToken) {
      verifyUser();
    }
  }, [apToken]);

  React.useEffect(() => {
    setTok(userAppToken);
  }, [userAppToken]);

  if (
    isLoading ||
    isLoadingAppToken ||
    userDataLoading ||
    facilitiesLoading ||
    loadingFacility
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !apToken || isErrorAppToken || !tok) {
    return <div>Error fetching token</div>;
  }

  if (!userAccess && !loadingFacility) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h3">
          You do not have access to this resource
        </Typography>
      </Box>
    );
  }

  return children;
};

export default ProtectedRoute;
