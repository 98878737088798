import config from "../config";

const CONSTANTS = {
  APP_TITLE: "Practice Intelligence",
  TEST_USER: config.test.testUser,
  PWD: config.test.testP,
};

//// ENV SPECIFIC CONSTANTS ////////////////////////////////////////////////////////////////

export const API_BASE_URL = config.api.apiBaseUrl;
export const LOOKER_BASE_URL = config.api.lookerBaseUrl;


export const DEFAULT_SELECTED_COLUMNS = {
  Demographics: {
    "Patient Id": {
      ActualTable: "encounter",
      ActualColumn: "Patient_Id",
    },
    "Patient First Name": {
      ActualTable: "patient",
      ActualColumn: "Patient_First_Name",
    },
    "Patient Last Name": {
      ActualTable: "patient",
      ActualColumn: "Patient_Last_Name",
    },
    "Patient Age": {
      ActualTable: "patient",
      ActualColumn: "Patient_Age",
    },
  },
  Visit: {
    "Time Since Last Visit": {
      ActualTable: "encounter",
      ActualColumn: "Time_Since_Last_Visit",
    },
  },
};

// --STAGE
// export const API_BASE_URL =
//   "https://api.stage.cardinalhealth.net/specialty/practice-intelligence/v1/api";
// export const LOOKER_BASE_URL =
//   "https://api.stage.cardinalhealth.net/specialty/practice-intelligence/v1/api";

////////////////////////////////////////////////////////////////

export default CONSTANTS;
