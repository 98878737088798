import Logout from "../Context/Logout";
import { Outlet } from "react-router-dom";

const AuthRoutes = {
  path: "/auth",
  title: "auth",
  element: <Outlet />,
  children: [
    {
      path: "logout",
      element: <Logout />,
    },
  ],
};

export default AuthRoutes;
