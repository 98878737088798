import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LOOKER_BASE_URL } from "../../constants";
import ExpandedView from "./ExpandedView";
import ModifiedFilters from "./NormalMofifyFilters";
import DOMPurify from 'dompurify';


const PatientProfiling = (props) => {
  const [url, setUrl] = useState();
 const [token,setToken]=useState(sessionStorage.getItem("AppToken") ?? "")
 useEffect(()=>{
  setToken(sessionStorage.getItem("AppToken"))
 },[sessionStorage.getItem("AppToken")])
  let FiltersToPass = "";

  const buildExpressionGF = (key, f, fls) => {
    let i = `${key}=`;
    let temp = "";

    if (fls[f]["type"] === "Multi Select") {
      if (fls[f]["condition"] === "Excludes") {
        temp = `${fls[f]["val"]
          .map((str) => `-${str}`.replace(/ /g, "+"))
          .join(", ")}&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Includes") {
        temp = `${fls[f]["val"]
          .map((str) => str.replace(/ /g, "+"))
          .join(", ")}&`;
        i = i + temp;
        return i;
      }
    }
    if (fls[f]["type"] === "Number Range") {
      if (fls[f]["condition"] === "Greater Than") {
        temp = `>${fls[f]["val"]}&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Less Than") {
        temp = `<${fls[f]["val"]}&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Equal To") {
        temp = `${fls[f]["val"]}&`;
        i = i + temp;
        return i;
      }
    }
    if (fls[f]["type"] === "Text Box") {
      if (fls[f]["condition"] === "Like") {
        temp = `%${fls[f]["val"]}%&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Equal To") {
        temp = `${fls[f]["val"]}&`;
        i = i + temp;
        return i;
      }
    }

    //.......................................................................................................//
    if (fls[f]["type"] === "Date Range") {
      if (fls[f]["condition"] === "Greater Than") {
        temp = `after+${fls[f]["val"].split("-").reverse().join("%2F")}&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Less Than") {
        temp = `before+${fls[f]["val"].split("-").reverse().join("%2F")}&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Equal To") {
        temp = `is+${fls[f]["val"].split("-").reverse().join("%2F")}&`;
        i = i + temp;
        return i;
      } else if (fls[f]["condition"] === "Between") {
        temp = `${fls[f]["val"][0].split("-").reverse().join("%2F")}+to+${fls[
          f
        ]["val"][1]
          .split("-")
          .reverse()
          .join("%2F")}&`;
        i = i + temp;
        return i;
      }
    }
  };

  const ModifiedFiltersGF = (a, gf) => {
    let fp = a;
    Object.keys(gf).forEach((f) => {
      let key = f.replaceAll(" ", "+");
      if (fp.includes(`${key}=&`)) {
        let ifp = fp.replace(`${key}=&`, buildExpressionGF(key, f, gf));
        fp = ifp;
      } else {
        let key = f.replace(" ", "+");
        let ifp = `${fp}&${buildExpressionGF(key, f, gf)}`;
        fp = ifp;
      }
    });

    return fp.replaceAll("&&", "&").slice(0, -1).slice(1);
  };

  useEffect(() => {
   
    let u =
      props?.SDNO === 0
        ? `${LOOKER_BASE_URL}/create_embed_sso_static_dashboards_url_Patient_Population_Profiling`
        : props?.SDNO === 1
          ? `${LOOKER_BASE_URL}/create_embed_sso_static_dashboards_url_diagnosis_disease_staging`
          : `${LOOKER_BASE_URL}/create_embed_sso_static_dashboards_url_therapy_pathways_analysis`;
    setUrl();

    const url = u;
    const formdata = new FormData();
    if (props?.cnm?.trim() === "No Cohort" && props?.GlobalFilters) {
  
      formdata.append(
        "pass_filter_values",
        `${
          Object.keys(props?.GlobalFilters)?.length === 0
            ? " "
            : ModifiedFiltersGF(FiltersToPass, props?.GlobalFilters)
        }`
      );
    }
    else if (
      props?.cnm &&
      Object.keys(props?.cohortAll)?.length > 0 &&
      props?.cnm?.trim() !== "No Cohort"
    ) {
      formdata.append(
        "pass_filter_values",
        ModifiedFilters(props, FiltersToPass)
      );
    }
  
    fetch(url, {
      method: "POST",
      body: formdata,
      headers:{
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        setUrl(data);
        props?.setStaticUrl(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props?.cnm, props?.cohortAll, props?.GlobalFilters, props?.SDNO,token]);

  return (
    <Stack
      sx={{
        height: "90vh",
        width: "98vw",
        padding: "0vw",

        overflow: "hidden",
        mx: "auto",
      }}
    >
      {/* {props?.cnm} */}
      {url && (
        <>
          <iframe
            // src="https://2198d9a2-dbfb-4db5-8720-d5e2025bf73a.looker.app/embed/dashboards/36?Race=&Tumor+Type=&Tumor+Categories+By+Physical+Site=&Gender=&Tumor+Histological+Categories=&Year=&Tumor+Stage=&Metastatic+Indicator+%28Yes+%2F+No%29=&Practice+Location=&+Treatment+Regimen=&Drug+Category="
            src={DOMPurify.sanitize(url)}
            sandbox="allow-scripts allow-same-origin"
            title="test"
            height="100%"
            width="100%"
            frameBorder={0}
          ></iframe>
        </>
      )}
    </Stack>
  );
};

export default PatientProfiling;
