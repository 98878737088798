import {
  Box,
  DialogActions,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRenameCohortMutation } from "../../../services/modules/viewAllCohorts";
import { useSelector } from "../../../store/configureStore";
import { resetCohortData } from "../../../store/reducers/CohortReducer";

const RenameModal = (props) => {
  const [name, setName] = useState(props?.name);
  const oldName = props?.name;
  const [renameCohort] = useRenameCohortMutation();
  const [errorMsg, setErrorMsg] = useState("");
  const { userName } = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const { cohortData } = useSelector((state) => state.CohortReducer);
  //const { setListChange } = UseStaticDash();

  React.useEffect(() => {
    setName(props?.name);
  }, [props]);

  const handleClose = () => {
    setErrorMsg("");
    props?.onClose();
  };

  const handleRename = () => {
    const formdata = new FormData();
    formdata.append("user_id", userName);
    formdata.append("old_cohort_name", oldName);
    formdata.append("new_cohort_name", name);
    renameCohort(formdata)
      //.unwrap()
      .then((result) => {
        if (result === "New cohort name already exists") {
          setErrorMsg(result);
        } else {
          if (cohortData.CohortName === oldName) {
            //Clear stored data if the opened cohort is renamed
            dispatch(resetCohortData());
          }
          props?.onRename();
          // setListChange((prev) => {
          //   let a = prev;
          //   a = prev + 1;
          //   return a;
          // });
          props?.onClose();
        }
      })
      .catch((error) => {
        setErrorMsg(error.status);
      
      });
  };

  return (
    <React.Fragment>
      <Dialog open={true} onClose={handleClose}>
        <Box
          sx={{
            border: "1px solid #EAEBEA",
            margin: "1.5vw",
            minWidth: "30vw",
            borderRadius: "6px",
          }}
        >
          <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">Rename Cohort</Typography>
          </DialogTitle>
          {/* <Divider sx={{ mx: "1vw" }} /> */}
          <DialogContent
            sx={{
              minWidth: "20vw",
              maxWidth: "40vw",
              overflowX: "hidden",
              py: 3,
            }}
          >
            <FormControl sx={{ m: 0, width: "100%" }}>
              <FormLabel>Name</FormLabel>
              <TextField
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%", fontSize: "1vw" }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                size="small"
                error={errorMsg}
                helperText={errorMsg}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} sx={{ pb: "1vw", px: "1vw" }} spacing={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: "auto" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ ml: "1" }}
                onClick={handleRename}
              >
                Rename
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default RenameModal;
