import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  GridSize,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { IPatientDetails } from "../../../../services/modules/queryBuilder/patientDetails";
import WeightIcon from "../../../assets/icon-weight.png";
import HeightIcon from "../../../assets/icon-height.png";
import BMIIcon from "../../../assets/icon-bmi.png";
import BloodSugarIcon from "../../../assets/icon-bloodsugar.png";
import BloodPressureIcon from "../../../assets/icon-bloodpressure.png";
import MinusIcon from "../../../assets/minus.png";
import UpArrowIcon from "../../../assets/up-arrow.png";
import { OverflowTypography } from "../../../components/Common/OverflowTypography";

export interface IAboutPatientProps {
  item: IPatientDetails["data"];
}

export const CardTitle = ({ title }: { title: string }) => (
  <Typography
    variant={"h4"}
    sx={{
      fontWeight: "bold",
      mb: 1,
    }}
  >
    {title}
  </Typography>
);

export const DetailItem = ({
  label,
  value,
  gridSize,
}: {
  label: string;
  value: string | number | undefined;
  gridSize?: GridSize;
}) => (
  <Grid xs={6}>
    <OverflowTypography
      color="primary"
      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
    >
      {label}
    </OverflowTypography>
    <OverflowTypography>{value ? value : "-"}</OverflowTypography>
  </Grid>
);

export const DetailHighLightItem = ({
  label,
  value,
  caption,
  icon,
}: {
  label: string;
  value: string | number | undefined;
  caption?: string;
  icon: HTMLImageElement["src"];
}) => (
  <Grid xs={2}>
    <Stack
      sx={(theme) => ({
        textAlign: "center",
        boxShadow: "0px 3px 6px #00000014",
        border: "1px solid #d0d0d0",
        borderRadius: "12px",
        px: 2,
        py: 0.5,
      })}
      spacing={1}
      direction="row"
      alignItems="center"
    >
      <Box>
        {icon && <img src={icon} style={{ height: "40px", width: "40px" }} />}
      </Box>
      <Box>
        <Stack direction="row" spacing={0.3} alignItems="center">
          <Typography variant={"h5"} sx={{ fontWeight: "regular" }}>
            {label}
          </Typography>
          <Typography color="textSecondary" variant="h6">
            {caption ? `{${caption ?? "-"}}` : ""}
          </Typography>
        </Stack>
        <Typography sx={{ fontWeight: "bold" }} variant={"h3"}>
          {value ?? "-"}
        </Typography>
      </Box>
    </Stack>
  </Grid>
);

export const TableDetailItemCard = ({
  items,
  title,
}: {
  items: Record<string, string | number | undefined>;
  title: string;
}) => (
  <Card>
    <CardContent>
      <CardTitle title={title} />
      <TableContainer
        sx={{
          td: {
            border: "1px solid gray",
            borderCollapse: "collapse",
            maxWidth: "0px",
            display: "table-cell",
          },
          width: "100%",
        }}
      >
        <Table>
          <TableBody>
            {Object.keys(items).map((item) => (
              <TableRow sx={{ width: "100%" }}>
                <TableCell sx={{ backgroundColor: "#cacaca" }}>
                  {item}
                </TableCell>
                <TableCell>
                  {/* @ts-ignore */}
                  <Typography color={"primary"}>
                    {items[item] ?? "-"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
);

export default function AboutPatient({ item }: IAboutPatientProps) {
  return (
    <Grid container gap={3} rowGap={1} alignItems={"center"} mt={2}>
      <DetailItem value={item.Gender} label="GENDER" gridSize="auto" />
      <DetailItem value={item.Age} label="AGE" gridSize="auto" />
      <DetailItem value={item.DOB} label="DOB" gridSize="auto" />
      <DetailItem
        value={item.Language_Preference}
        label="LANGUAGE"
        gridSize="auto"
      />
      <DetailItem
        value={item.Contact_Preference}
        label="CONTACT PREF."
        gridSize="auto"
      />
      <DetailItem value={item.Preferred_Phone} label="PHONE" gridSize="auto" />
      <DetailItem value={item.Address} label="ADDRESS" gridSize="auto" />
      <DetailItem value={item.Zip} label="ZIP CODE" gridSize="auto" />
      <DetailItem value={item.Preferred_Mail} label="EMAIL" gridSize="auto" />
      <Grid xs={12} />

      <DetailHighLightItem
        label={"Weight"}
        value={item.Weight}
        icon={WeightIcon}
        caption="lbs"
      />
      <DetailHighLightItem
        label={"Height"}
        value={item.Height}
        icon={HeightIcon}
        caption="ft"
      />
      <DetailHighLightItem
        label={"BMI"}
        value={item?.BMI?.toFixed(2)}
        icon={BMIIcon}
      />
      <DetailHighLightItem
        label={"Blood pressure"}
        value={item.BP}
        icon={BloodPressureIcon}
        caption="mm/hg"
      />
      <DetailHighLightItem
        label={"Blood Sugar"}
        value={item.Blood_Sugar}
        icon={BloodSugarIcon}
        caption="mg/dl"
      />
    </Grid>
  );
}
