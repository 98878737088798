import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback } from "react";

const PREFIX = "SuccessSnackbar";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  typography: `${PREFIX}-typography`,
  actionRoot: `${PREFIX}-actionRoot`,
};

const StyledSnackbarContent = styled(SnackbarContent)(() => ({
  [`&.${classes.root}`]: {
    "@media (min-width:600px)": {
      minWidth: "300px !important",
    },
  },

  [`& .${classes.card}`]: {
    width: "70%",
  },

  [`& .${classes.actionRoot}`]: {
    padding: "16px", // 8px 16px 16px",
    // justifyContent: "center",
    // alignItems: "center",
  },
}));

interface SuccessSnackbarProps extends CustomContentProps {
  subMessage?: string;
}

const ICON_MAPPING = {
  success: <DoneIcon fontSize="small" />,
  error: <ErrorIcon fontSize="small" color="error" />,
  warning: <WarningIcon fontSize="small" />,
  info: <InfoIcon fontSize="small" color="info" />,
};

// success, error, warning and info.
const CustomSnackbar = forwardRef<HTMLDivElement, SuccessSnackbarProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const { variant } = props;

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <StyledSnackbarContent ref={ref} className={classes.root}>
        <Card
          className={classes.card}
          style={{
            //@ts-expect-error
            backgroundColor: theme.palette.common[props?.variant] ?? "#E2F0D7",
            marginTop: "8.5vh",
            minWidth: "65vw",
            minHeight: "6vh",
          }}
        >
          <CardActions classes={{ root: classes.actionRoot }}>
            <Stack direction="row" spacing={2}>
              {
                //@ts-expect-error
                ICON_MAPPING[variant] ?? <></>
              }
              <Stack
                direction="column"
                spacing={2}
                sx={{ textAlign: "center" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", fontSize: "14px", pb: 0 }}
                >
                  {props.message}
                </Typography>
                {props?.subMessage && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {props.subMessage}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <IconButton
              size="small"
              onClick={handleDismiss}
              sx={{
                right: 5,
                position: "absolute",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </CardActions>
        </Card>
      </StyledSnackbarContent>
    );
  }
);

export default CustomSnackbar;
