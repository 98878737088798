import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";

export default function UserDetails() {
  const { userInfo } = useSelector((state: RootState) => state.LoginReducer);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <Stack gap={0.5} sx={{ m: 1, alignItems: "center", cursor: "help" }}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">User Details</Typography>
            <b>{userInfo?.name}</b>
            <br />
            <b>{userInfo?.email}</b>
          </React.Fragment>
        }
      >
        <Avatar sx={{ bgcolor: "primary" }}>
          {userInfo?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </HtmlTooltip>
    </Stack>
  );
}
