import {
  Box,
  Skeleton,
  Stack,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../../store/configureStore";
import {
  resetCohortData,
  setCohortData,
  setCohortType,
  setExportable,
  setPatientCount,
} from "../../../store/reducers/CohortReducer";
import PageContainer from "../../components/PageContainer/PageContainer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import {
  useDeleteCohortMutation,
  useLazyGetAllCohortsQuery,
  useLazyGetCohortsDataQuery,
} from "../../../services/modules/viewAllCohorts";
import AlertDialog from "../../components/Dialogs/AlertDialog";
import { UseStaticDash } from "../../Context/StaticDashboardsContext";
import { USER_ROUTES } from "../../routes/UserRoutes";
import SaveMDNew from "../Builder/SaveModalNew";
import ExportModal from "./ExportModal";
import OptionsButton from "./OptionsButton";
import RenameModal from "./RenameModal";
import ShareModal from "./ShareModal";

function AllCohortsScreen() {
  const nav = useNavigate();
  const { userName } = useSelector((state) => state.LoginReducer);
  const [getAllCohorts, { data: allCohorts, isLoading }] =
    useLazyGetAllCohortsQuery();
  const [getCohort] = useLazyGetCohortsDataQuery();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteCohort] = useDeleteCohortMutation();
  const [cohortName, setCohortName] = useState("");
  const [sortingOrderName, setSortingOrderName] = useState("");
  const [sortingOrderDate, setSortingOrderDate] = useState("desc");
  const [sortedByDate, setSortedByDate] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [copiedCohort, setCopiedCohort] = useState();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const { setListChange } = UseStaticDash();
  const getCohortData = (cohortName) => {
    getCohort({ user_id: userName, cohort_name: cohortName }).then((result) => {
      dispatch(setCohortData(result.data));
      dispatch(
        setPatientCount({
          patientCount: 0,
        })
      );
      dispatch(setCohortType(""));
      dispatch(setExportable(false));
      nav(USER_ROUTES.builder);
    });
  };

  useEffect(() => {
    getAllCohorts(userName);
  }, []);

  useEffect(() => {
    if (allCohorts) {
      setRowData(allCohorts.data);
    }
  }, [allCohorts]);

  const handleCohortDelete = (cohortName) => {
    setCohortName(cohortName);
    setShowDeleteDialog(true);
  };

  const handleMenuClick = (cohort, option) => {
    if (option === "Copy") {
      setCopiedCohort(cohort);
      setShowCopyDialog(true);
    } else {
      const Cohort = {
        Filters: JSON.parse(cohort.Filters),
        CohortName: cohort.CohortName,
        Dashboards: JSON.parse(cohort.Dashboards),
        Columns: JSON.parse(cohort.Columns),
      };
      dispatch(setCohortData(Cohort));
      dispatch(setExportable(true));
      setShowExportModal(true);
    }
  };

  const handleRename = (cohortName) => {
    setCohortName(cohortName);
    setShowRenameDialog(true);
  };

  const handleShare = (cohortName) => {
    setCohortName(cohortName);
    setShowShareDialog(true);
  };

  const handleDelete = () => {
    const formdata = new FormData();
    formdata.append("user_id", userName);
    formdata.append("cohort_name", cohortName);
    deleteCohort(formdata)
      .then(() => {
        setShowDeleteDialog(false);
        getAllCohorts(userName);
        setAlertMsg(cohortName + " deleted successfully!");
        setListChange((prev) => {
          let a = prev;
          a = prev + 1;
          return a;
        });
        setShowSuccessAlert(true);
      })
      .catch((error) => {
     
      });
  };

  const handleClose = () => {
    setShowDeleteDialog(false);
  };

  const handleNameSort = () => {
    setSortedByDate(false);
    if (sortingOrderName === "desc") {
      setSortingOrderName("asc");
      const sorted = [...rowData];
      sorted.sort((a, b) => (a.CohortName < b.CohortName ? -1 : 0));
 
      setRowData(sorted);
    } else {
      setSortingOrderName("desc");
      const sorted = [...rowData];
      sorted.sort((a, b) => (a.CohortName > b.CohortName ? -1 : 0));
    
      setRowData(sorted);
    }
  };

  const handleDateSort = () => {
    setSortedByDate(true);
    if (sortingOrderDate === "desc") {
      setSortingOrderDate("asc");
      const sorted = [...rowData];
      sorted.sort((a, b) =>
        new Date(a.Timestamp) < new Date(b.Timestamp) ? -1 : 0
      );
 
      setRowData(sorted);
    } else {
      setSortingOrderDate("desc");
      const sorted = [...rowData];
      sorted.sort((a, b) =>
        new Date(a.Timestamp) > new Date(b.Timestamp) ? -1 : 0
      );

      setRowData(sorted);
    }
  };

  const handleExportClose = () => {
    dispatch(resetCohortData());
    dispatch(setExportable(false));
    setShowExportModal(false);
  };
  const DateFormatter = (d) => {
    let a = d.split("-");
    return `${a[1]}-${a[0]}-${a[2]}`;
  };

  return (
    <PageContainer>
      {showSuccessAlert && (
        <Box sx={{ display: "flex", width: "100%", alignSelf: "flex-end" }}>
          <Stack
            direction={"row"}
            sx={{
              minWidth: "50vw",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: "auto",
              mr: "1vw",
              backgroundColor: "#E2F0D7",
              p: 2,
            }}
          >
            <Stack direction={"row"} gap={1} sx={{ alignItems: "center" }}>
              <CheckCircleIcon color="success" />
              <Typography variant="body1">{alertMsg}</Typography>
            </Stack>
            <CloseIcon
              style={{ alignSelf: "flex-end" }}
              onClick={() => setShowSuccessAlert(false)}
            />
          </Stack>
        </Box>
      )}
      <Typography
        variant="h2"
        sx={{
          mt: "1vh",
        }}
      >
        Cohort Detail List
      </Typography>

      <TableContainer sx={{ maxHeight: "65vh", pt: "0", pb: "1vh", mt: "2vh" }}>
        <Table
          stickyHeader
          sx={{
            minWidth: 650,
            borderCollapse: "separate",
          }}
          aria-label="sticky table"
        >
          <TableHead
            sx={{ backgroundColor: "common.tableHead", maxHeight: "2vh" }}
          >
            <TableRow
              sx={{
                backgroundColor: "common.tableHead",
                boxShadow: "0px 3px 6px #00000014",
                border: "1px solid #C3C3C3",
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: "common.tableHead",
                  fontWeight: "bold",
                  minHeight: "1vh",
                  fontSize: "0.729vw",
                }}
                sortDirection={"asc"}
              >
                <TableSortLabel
                  active={!sortedByDate}
                  direction={sortingOrderName}
                  onClick={handleNameSort}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "common.tableHead",
                  fontWeight: "bold",
                  minHeight: "1vh",
                  fontSize: "0.729vw",
                }}
                align="left"
              >
                <TableSortLabel
                  active={sortedByDate}
                  direction={sortingOrderDate}
                  onClick={handleDateSort}
                >
                  Created on
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "common.tableHead",
                  fontWeight: "bold",
                  minHeight: "1vh",
                  fontSize: "0.729vw",
                }}
                align="left"
              >
                Description
              </TableCell>

              <TableCell
                sx={{
                  backgroundColor: "common.tableHead",
                  fontWeight: "bold",
                  minHeight: "1vh",
                  fontSize: "0.729vw",
                }}
                align="left"
              >
                Created by
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "common.tableHead",
                  fontWeight: "bold",
                  minHeight: "1vh",
                }}
                align="left"
              ></TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody data-testid="cohort-loading">
              {[0, 1, 2, 3, 4, 5].map((item) => (
                <TableRow key={item}>
                  <TableCell>
                    <Skeleton height={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={50} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={50} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {rowData.map((row, index) => {
                const rowIndex = index;
                return (
                  <TableRow
                    key={row.CohortName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      minHeight: "1vh",
                      maxHeight: "1vh",
                      borderColor: "common.tableDivider",

                      backgroundColor:
                        rowIndex % 2 === 0 ? "white" : "common.tableRow",
                      borderSpacing: 0,
                    }}
                  >
                    <TableCell
                      sx={{
                        maxWidth: "40%",
                        backgroundColor:
                          rowIndex % 2 === 0 ? "white" : "common.tableRow",
                        minHeight: "1vh",
                        maxHeight: "1vh",
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        variant="body1"
                        onClick={() => getCohortData(row.CohortName)}
                      >
                        {" "}
                        {row.CohortName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "25%",
                        color: "text.primary",
                        minHeight: "1vh",
                        maxHeight: "1vh",
                      }}
                      align="left"
                    >
                      <Typography variant="caption">
                        {" "}
                        {DateFormatter(
                          row.Timestamp.split("-").reverse().join("-")
                        ) ?? "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "30%",
                        color: "text.primary",
                        minHeight: "1vh",
                        maxHeight: "1vh",
                      }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {row.Description ?? ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        maxWidth: "20%",
                        color: "text.primary",
                        minHeight: "1vh",
                        maxHeight: "1vh",
                      }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {row?.Shared ? row?.SharedBy : userName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ maxWidth: "30%", py: "2.2vh" }}
                      align="right"
                    >
                      <OptionsButton
                        row={row}
                        onDelete={handleCohortDelete}
                        onMenuClick={handleMenuClick}
                        onRename={handleRename}
                        handleShare={handleShare}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showDeleteDialog && (
        <AlertDialog
          title={"Delete Cohort"}
          description={"Are you sure you want to delete this cohort?"}
          onClose={handleClose}
          onButtonClick={handleDelete}
          buttonName={"Delete"}
        />
      )}
      {showCopyDialog && (
        <SaveMDNew
          open={true}
          name={
            copiedCohort.CohortName.includes("Copy of ")
              ? copiedCohort.CohortName
              : "Copy of " + copiedCohort.CohortName
          }
          description={copiedCohort.Description}
          filters={copiedCohort?.Filters}
          dashboards={copiedCohort?.Dashboards}
          columns={copiedCohort?.Columns}
          onClose={() => {
            setShowCopyDialog(false);
          }}
          isCopy={true}
          isNew={true}
          onOk={() => {
            getAllCohorts(userName);
            setAlertMsg(copiedCohort.CohortName + " copied successfully!");
            setShowSuccessAlert(true);
          }}
        />
      )}
      {showRenameDialog && (
        <RenameModal
          name={cohortName}
          onClose={() => setShowRenameDialog(false)}
          onRename={() => {
            getAllCohorts(userName);
            setAlertMsg(cohortName + " renamed successfully!");
            setShowSuccessAlert(true);
          }}
        />
      )}

      {showShareDialog && (
        <ShareModal
          name={cohortName}
          onClose={() => setShowShareDialog(false)}
          OnShared={() => {
            setAlertMsg(cohortName + " shared successfully!");
            setShowSuccessAlert(true);
          }}
        />
      )}
      {showExportModal && <ExportModal onClose={handleExportClose} embedUrl={""} VisualsArr={[]} />}
    </PageContainer>
  );
}

export default AllCohortsScreen;
