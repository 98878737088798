import {
  Autocomplete,
  Box,
  DialogActions,
  FormControl,
  FormLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useSelector } from "../../../store/configureStore";
import { API_BASE_URL } from "../../constants";

const ShareModal = (props) => {
  const [name, setName] = useState();
  const [cohortName, setCohortName] = useState(props?.name);
  const { userName} = useSelector((state) => state.LoginReducer);
  const [usersWithGroups, setUsers] = useState([]);


  const getUsersWithSamePractice = () => {
    const url = `${API_BASE_URL}/get_user_team`;
    const formdata = new FormData();
    formdata.append("email_id", userName);

    fetch(url, {
      method: "POST",
      body: formdata,
      headers:{
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        let arr = [];
        data?.map((e) => {
          if (e.email !== userName) {
            arr.push(e.email);
          }
        });
        setUsers(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShare = () => {
    const url = `${API_BASE_URL}/shared_cohort`;
    const formdata = new FormData();
    formdata.append("user_id", userName);
    formdata.append("cohort_name", cohortName);
    formdata.append("shared_with", [name]);

    fetch(url, {
      method: "POST",
      body: formdata,
      headers:{
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        handleClose();
        props?.OnShared();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    setCohortName(props?.name);
  }, [props]);

  React.useEffect(() => {
    if (userName) {
      getUsersWithSamePractice();
    }
  }, [userName]);

  const handleClose = () => {
    props?.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={true} onClose={handleClose}>
        <Box
          sx={{
            border: "1px solid #EAEBEA",
            margin: "1.5vw",
            minWidth: "30vw",
            borderRadius: "6px",
          }}
        >
          <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">Share Cohort</Typography>
          </DialogTitle>
          {/* <Divider sx={{ mx: "1vw" }} /> */}
          <DialogContent
            sx={{
              minWidth: "20vw",
              maxWidth: "40vw",
              overflowX: "hidden",
              py: 3,
            }}
          >
            <FormControl sx={{ m: 0, width: "100%" }}>
              <FormLabel>Share to</FormLabel>

              <Autocomplete
                disabled={!userName || usersWithGroups.length < 1}
                value={name}
                onChange={(event, newValue) => {
                  setName(newValue);
                }}
                id="controllable-states-demo"
                data-testid="share-users"
                options={usersWithGroups}
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  fontSize: "1vw",
                  mt: "1vh",
                  p: "0",
                  "& .MuiInputBase-root": {
                    height: "5vh",
                    mt: "1.5vh",
                    fontSize: "1vw",
                    fontWeight: "bold",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ borderRadius: "0", p: "0", fontSize: "1vw" }}
                    {...params}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      "data-testid": "select-user",
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props}>{option}</MenuItem>
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} sx={{ pb: "1vw", px: "1vw" }} spacing={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: "auto" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disabled={!name}
                variant="contained"
                size="small"
                sx={{ ml: "1" }}
                onClick={handleShare}
              >
                Share
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default ShareModal;
