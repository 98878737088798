import { Grid, TextField, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
const GridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}));

const RangeInput = (props: {
  onRangeAdded: any;
  initialStartVal: string | Number;
  InitialEndVal: string | Number;
}) => {
  const { onRangeAdded, initialStartVal = "", InitialEndVal = "" } = props;
  const [val1, setVal1] = useState<Number | string>(initialStartVal);
  const [val2, setVal2] = useState<Number | string>(InitialEndVal);

  useEffect(() => {
    if (val1 !== "" && val2 !== "") {
      onRangeAdded([val1, val2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val1, val2]);



  return (
    <GridItem
      sx={{
        mt: "1vh",
        gap: 2,
      }}
    >
      <TextField
        type="number"
        name="num1"
        size="small"
        value={val1}
        onChange={(e: any) => {
          setVal1(e.target.value);
        }}
      />
      <Typography variant="caption">to</Typography>
      <TextField
        type="number"
        value={val2}
        size="small"
        name="num2"
        onChange={(e: any) => {
          setVal2(e.target.value);
        }}
      />
    </GridItem>
  );
};

export default RangeInput;
