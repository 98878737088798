import { Box, Skeleton } from "@mui/material";

const TableLoading = () => (
  <Box
    sx={{
      height: "max-content",
    }}
  >
    {[...Array(10)].map((_, idx) => (
      <Skeleton
        variant="rectangular"
        key={`${idx}`}
        sx={{ my: 4, mx: 1 }}
        height={"44px"}
      />
    ))}
  </Box>
);

export default TableLoading;
