import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Select,
  MenuItem,
  FormGroup,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AiFillAudio } from "react-icons/ai";
import { IoStop } from "react-icons/io5";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useGetFilterDataQuery } from "../../../services/modules/queryBuilder";
import { API_BASE_URL } from "../../constants";


export default function AlertDialogNLP(props: any) {
  const [PreviousQueries, setPreviousQueries] = useState(
    JSON.parse(sessionStorage.getItem("PrevNlpQueries") as any) ?? []
  );
  const { data } = useGetFilterDataQuery();
  const [StaticData, setStaticData] = useState(data ?? {});
  const [open, setOpen] = useState(false);
  const [InputQuestion, setInputQuestion] = useState<any>("");
  const [gettingAnswer, setGettingAnswer] = useState(false);
  const [multiline, setMultiline] = React.useState(false);

  React.useEffect(() => {
      setMultiline(true);
  }, []);
 

  useEffect(() => {
    if (data) {
      setStaticData(data);
    }
  }, [data]);
  let s = StaticData;
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (listening) {
      setInputQuestion(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  useEffect(() => {
    if (!open) {
      SpeechRecognition.stopListening();
      resetTranscript();
      setInputQuestion("");
    }
  }, [open]);

  if (!browserSupportsSpeechRecognition) {
   
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
  };
  /* istanbul ignore next */
  function fetchObjectByTableAndColumn(
    dataSet: any,
    { ActualTable, ActualColumn }: any
  ) {
    for (let attribute in dataSet) {
      for (let subAttribute in dataSet[attribute]) {
        const { ActualTable: table, ActualColumn: column } =
          dataSet[attribute][subAttribute];
        if (table === ActualTable && column === ActualColumn) {
          return {
            found: true,
            object: dataSet[attribute][subAttribute],
            mainTable: attribute,
            subAttribute: subAttribute,
          };
        }
      }
    }
    return { found: false, object: null, mainTable: null, subAttribute: null };
  }
  /* istanbul ignore next */
  function modifyGeneratedData(staticData: any, generatedData: any) {
    const modifiedData = {} as any;
    for (let key in generatedData) {
      let found = false;
  
      for (let staticKey in staticData) {
        if (staticData[staticKey].hasOwnProperty(key.replaceAll("_", " "))) {
       
          const staticSubKey = staticData[staticKey][key.replaceAll("_", " ")];
       
          generatedData[key].ActualTable = staticSubKey.ActualTable;
          generatedData[key].ActualColumn = staticSubKey.ActualColumn;
          generatedData[key].type = staticSubKey.types;
          generatedData[key].Main = staticKey;
          // if (
          //   staticSubKey.types === "Date Range" &&
          //   typeof generatedData[key].val === "string"
          // ) {
          //   generatedData[key].val = dayjs(generatedData[key].val).format(
          //     "DD-MM-YYYY"
          //   );
          // }

          // if (
          //   staticSubKey.types === "Date Range" &&
          //   generatedData[key].condition === "Between"
          // ) {
          //   generatedData[key].val = [
          //     dayjs(generatedData[key].val[0]).format("DD-MM-YYYY"),
          //     dayjs(generatedData[key].val[1]).format("DD-MM-YYYY"),
          //   ];
          // }

          if (
            staticSubKey.types === "Multi Select" &&
            typeof generatedData[key].val === "string"
          ) {
            generatedData[key].val = [generatedData[key].val];
            generatedData[key].condition = "Includes";
          }
          modifiedData[key.replaceAll("_", " ")] = generatedData[key];
          found = true;
          break;
        } else if (
          fetchObjectByTableAndColumn(staticData, generatedData[key]).found ===
          true
        ) {
          let functionalData: any = fetchObjectByTableAndColumn(
            staticData,
            generatedData[key]
          );
          generatedData[key].ActualTable = functionalData.object.ActualTable;
          generatedData[key].ActualColumn = functionalData.object.ActualColumn;
          generatedData[key].type = functionalData.object.types;
          generatedData[key].Main = functionalData.mainTable;
          // if (
          //   functionalData.object.types === "Date Range" &&
          //   typeof generatedData[key].val === "string"
          // ) {
          //   generatedData[key].val = dayjs(generatedData[key].val).format(
          //     "DD-MM-YYYY"
          //   );
          // }

          // if (
          //   functionalData.object.types === "Date Range" &&
          //   generatedData[key].condition === "Between"
          // ) {
          //   generatedData[key].val = [
          //     dayjs(generatedData[key].val[0]).format("DD-MM-YYYY"),
          //     dayjs(generatedData[key].val[1]).format("DD-MM-YYYY"),
          //   ];
          // }

          if (
            functionalData.object.types === "Multi Select" &&
            typeof generatedData[key].val === "string"
          ) {
            generatedData[key].val = [generatedData[key].val];
            generatedData[key].condition = "Includes";
          }

          modifiedData[functionalData.subAttribute] = generatedData[key];
          found = true;
          break;
        }
      }
      if (!found) {
        delete generatedData[key];
      }
    }
    return modifiedData;
  }
  /* istanbul ignore next */
  const handleCloseApply = () => {
    setGettingAnswer(true);
    setPreviousQueries((prev: any) => {
      let arrPrev = [...prev];
      arrPrev.push(InputQuestion);
      sessionStorage.setItem("PrevNlpQueries", JSON.stringify(arrPrev));
      return arrPrev;
    });
    stopListening();
    const url = `${API_BASE_URL}/update_filter`;
    const formdata = new FormData();
    formdata.append("question", JSON.stringify(InputQuestion));
    formdata.append("filter", JSON.stringify(props?.filter));

    fetch(url, {
      method: "POST",
      body: formdata,
      headers:{
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        let fn = modifyGeneratedData(StaticData, data.filters);

        props.GetNLPFilters(fn);
        props.onClose();
        setGettingAnswer(false);

        setInputQuestion("");
        resetTranscript();
      })
      .catch((error) => {
        console.log(error);
        setGettingAnswer(false);
      });
  };

  const handleClose = () => {
    if (!gettingAnswer) {
      setOpen(false);

      props?.onClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            border: "1px solid #EAEBEA",
            margin: "1.5vw",
            //minWidth: "30vw",
            borderRadius: "6px",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>
            {" "}
            {/* <MdAutoAwesome style={{ marginRight: "1vw", fontSize: "2vw" }} /> */}
            <Typography variant="body1">
              Generate a <strong>Filter</strong>
            </Typography>
          </DialogTitle>

          <DialogContent
            sx={{
              // maxHeight: "40vh",
              //maxWidth: "50vw",
              // mx:"1.5vw",
              overflow: "hidden",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormLabel>Previous Queries</FormLabel>
              <Select
                disabled={gettingAnswer}
                placeholder="Previous Queries"
                sx={{
                  mt: "0.5vh",

                  // minWidth: "27vw",
                  // maxWidth: "auto",
                  // maxHeight: "30vh",
                }}
                onChange={(e) => {
                  setInputQuestion(e.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={() => "Select here"}
              >
                {PreviousQueries?.map((q: any) => {
                  return (
                    <MenuItem
                      sx={{
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                      }}
                      value={q}
                    >
                      {q}
                    </MenuItem>
                  );
                })}
              </Select>

             
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: "1vh",
                  }}
                >
                  <FormLabel>Type your query</FormLabel>
                  {listening ? (
                    <IconButton
                      sx={{ p: "0", color: "black" }}
                      onClick={stopListening}
                      data-testid="stop"
                    >
                      <IoStop fontSize={"medium"} />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{ p: "0", color: "black" }}
                      disabled={gettingAnswer}
                      onClick={startListening}
                      data-testid="start"
                    >
                      <AiFillAudio fontSize={"medium"} />
                    </IconButton>
                  )}
                </Stack>
                <textarea
              
                  disabled={gettingAnswer}
                 
                 
                  onChange={(e) => {
                    setInputQuestion(e.target.value);
                  }}
                  value={InputQuestion}
                  style={{ marginTop: "1vh",resize:"none",border:"1px solid #c4c4c4",borderRadius:"3px",padding:"1vh"}}
                  id="outlined-basic"
                  placeholder="Type query in natural language to generate filters"
                  
                  rows={5}
                  data-testid="query"
                 
                />
             
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              onClick={handleClose}
              disabled={gettingAnswer}
              sx={{ mb: "1vw" }}
              data-testid="cancel"
            >
              Cancel
            </Button>
            <Button
              size="small"
              disabled={gettingAnswer || InputQuestion.trim() === ""}
              variant="contained"
              onClick={handleCloseApply}
              sx={{ mr: "1vw", mb: "1vw" }}
              data-testid="generate"
            >
              {gettingAnswer ? "Generating..." : "Generate"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
