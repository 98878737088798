import { EndpointBuilder } from "@reduxjs/toolkit/query";
// {
//     "_id": "2024-05-07T12:09:50.740000",
//     "data": {
//       "Action_Type": "Create_group",
//       "Owner": "manchi.renuka@cah.com",
//       "Description": "Group Caeated by"
//     }
//   }
type Keys = {
  Action_Type: string;
  Owner: string;
  Description: string;
};
type OriginalResp = {
  _id: string;
  data: Keys;
};

export type IHistoryDetails = {
  id: string;
} & Keys;

const getHistory = (build: EndpointBuilder<any, any, any>) =>
  build.query<IHistoryDetails[], void>({
    query: () => ({
      url: "/get_history",
      method: "GET",
    }),
    transformResponse: (response: OriginalResp[]) => {
      if (response?.length > 0) {
        return response.map((item) => ({
          id: item._id,
          ...item.data,
        }));
      }
      return [];
    },
  });

export default getHistory;
