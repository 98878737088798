import React from "react";

import { useRoutes } from "react-router-dom";
import { NotFoundPage } from "../components/NotFound";
import UserRoutes from "./UserRoutes";
import AdminRoutes from "./AdminRoutes";
import AuthRoutes from "./AuthRoutes";

const NotFountRoute = {
  path: "*",
  element: <NotFoundPage />,
};

const Routes = () => {
  return useRoutes([UserRoutes, AdminRoutes, AuthRoutes, NotFountRoute]);
};

export default Routes;
