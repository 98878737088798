import { Box, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "./Accordion";

import { FaCheck } from "react-icons/fa6";
export default function CustomizedAccordionsVisual(props) {

  const [expanded, setExpanded] = React.useState();
  const [FilterValue, setFilterValue] = React.useState("");
  const { disable } = props;
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <Box mt={2}>
      {Object.keys(props.staticData)?.map((fl, index) => {
        return (
          <>
            <Accordion
              expanded={expanded === index || props?.searchT}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "1px solid #C3C3C3" }}
              >
                <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                  {fl}
                </Typography>
              </AccordionSummary>
              {Object.keys(props.staticData[fl])
                .sort((a, b) => a.localeCompare(b))
                ?.map((fval, itemIndex) => {
                  return (
                    <>
                      <AccordionDetails sx={{ py: "1vh" }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Tooltip
                            title={props.staticData[fl][fval]["description"]}
                            disableInteractive
                          >
                            <Typography
                              onClick={() => {
                                if (
                                  disable ||
                                  (props?.InitVal?.length > 0 &&
                                    fval === props?.InitVal[0]["value"]) ||
                                  (props?.InitVal.length > 1 &&
                                    fval === props?.InitVal[1]["value"])
                                ) {
                                  return null;
                                } else {
                                  setFilterValue(fval);
                                  props.getVal({
                                    group: fl,
                                    value: fval,
                                    itemIndex,
                                  });
                                }
                              }}
                              sx={{
                                cursor: "pointer",
                                ml: "1vw",
                                fontWeight:
                                  (props?.InitVal?.length > 0 &&
                                    fval === props?.InitVal[0]["value"]) ||
                                  (props?.InitVal.length > 1 &&
                                    fval === props?.InitVal[1]["value"])
                                    ? "bold"
                                    : null,

                                py: "1vh",
                              }}
                              variant="h4"
                            >
                              {fval}
                            </Typography>
                          </Tooltip>
                          {((props?.InitVal?.length > 0 &&
                            fval === props?.InitVal[0]["value"]) ||
                            (props?.InitVal.length > 1 &&
                              fval === props?.InitVal[1]["value"])) && (
                            <FaCheck
                              color="primary"
                              style={{ fontSize: "1vw" }}
                            />
                          )}
                        </Stack>
                      </AccordionDetails>
                    </>
                  );
                })}
            </Accordion>
          </>
        );
      })}
    </Box>
  );
}
