import { EndpointBuilder } from "@reduxjs/toolkit/query";

const authenticateUser = (build: EndpointBuilder<any, any, any>) =>
  build.query<
    any,
    {
      userId: string;
    }
  >({
    query: ({ userId }) => {
      return {
        url: `/apa-sp/proxy/authenticateUser`,
        method: "GET",
        params: { userId: userId },
      };
    },
  });

export default authenticateUser;
