import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import * as yup from "yup";
import {
  useGetPracticesQuery,
  useGetRolesQuery,
} from "../../../../services/modules/admin";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/configureStore";
import { GroupFormData } from "../../../../services/modules/admin/modifyGroup";
import { IGroupDetails } from "../../../../services/modules/admin/getGroups";

interface CreateGroupFormProps {
  onClose: () => void;
  onGroupCreate: (g: GroupFormData) => void;
  editData: IGroupDetails | null;
}

// group_name: "",
// Description: "",
// Admin: [],
// PracticeTeam: "",
// Role: "",
// email: [],
// isnew: true,

const initialValues: {
  group_name: string;
  Description: string;
  Admin: IGroupDetails["Admin"];
  PracticeTeam: string;
  Role: string;
} = {
  group_name: "",
  Description: "",
  Admin: [
    {
      first_name: "",
      last_name: "",
      email: "",
    },
  ],
  PracticeTeam: "",
  Role: "",
};

const validationSchema = yup.object().shape({
  group_name: yup.string().required("First Name is required"),
  Description: yup.string().required("First Name is required"),
  Admin: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required("First Name is required"),
      last_name: yup.string().required("Last Name is required"),
      email: yup
        .string()
        .email("Provide a correct email")
        .required("Email is required"),
    })
  ),
  PracticeTeam: yup.string().required("Practice is required"),
  Role: yup.string().required("Role is required"),
});

const CreateGroup: FC<CreateGroupFormProps> = ({
  onClose = () => {},
  onGroupCreate = () => {},
  editData = null,
}) => {
  const { data: roles = [] } = useGetRolesQuery();
  const { data: practices = [] } = useGetPracticesQuery();

  const { userName: currentUser } = useSelector(
    (state: RootState) => state.LoginReducer
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (e) => {
      onGroupCreate({
        ...e,
        Admin: e.Admin,
        isnew: editData ? "False" : "True",
        email: currentUser,
      });
      // console.log("these are submitted values", e);
    },
    validationSchema,
  });

  useEffect(() => {
    if (editData) {
      formik.setValues({
        group_name: editData.id,
        Description: editData.Description,
        Admin: editData.Admin,
        PracticeTeam: editData.PracticeTeam,
        Role: editData.Role,
      });
    }
  }, [editData]);

  return (
    <form>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography>
          Creating
          <b> New Group</b>
        </Typography>
        <Box gap={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="outlined" onClick={formik.submitForm}>
            Save
          </Button>
        </Box>
      </Stack>
      <Stack spacing={2} mt={3}>
        <FormGroup>
          <FormLabel>Group Name</FormLabel>
          <TextField
            fullWidth
            value={formik.values.group_name}
            error={!!formik.errors.group_name}
            placeholder="Group Name"
            name="group_name"
            helperText={formik.touched.group_name && formik.errors.group_name}
            onChange={formik.handleChange}
            disabled={!!editData}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Description</FormLabel>
          <TextField
            fullWidth
            value={formik.values.Description}
            error={!!formik.errors.Description}
            placeholder="Description"
            name="Description"
            helperText={formik.touched.Description && formik.errors.Description}
            onChange={formik.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Add Role</FormLabel>
          <TextField
            fullWidth
            value={formik.values.Role}
            error={!!formik.errors.Role}
            placeholder="Role"
            name="Role"
            select
            helperText={formik.touched.Role && formik.errors.Role}
            onChange={formik.handleChange}
          >
            {roles.map((role) => (
              <MenuItem value={role}>{role}</MenuItem>
            ))}
          </TextField>
        </FormGroup>
        {formik.values.Admin.map((item, _idx) => (
          <Grid container gap={2}>
            <FormGroup>
              <FormLabel>First Name</FormLabel>
              <TextField
                fullWidth
                value={formik.values.Admin[_idx].first_name}
                placeholder="First Name"
                name={`Admin.${_idx}.first_name`}
                helperText={
                  formik.touched &&
                  formik.touched.Admin &&
                  formik.touched.Admin.length > 0 &&
                  formik.touched.Admin[_idx] &&
                  formik.touched.Admin[_idx]["first_name"] &&
                  formik.errors &&
                  formik.errors.Admin &&
                  formik.errors.Admin.length > 0 &&
                  formik.errors.Admin[_idx] &&
                  //@ts-expect-error
                  formik.errors.Admin[_idx]["first_name"]
                }
                error={
                  !!formik.errors.Admin &&
                  formik.errors.Admin.length > 0 &&
                  formik.errors.Admin[_idx] &&
                  //@ts-expect-error
                  formik.errors.Admin[_idx]["first_name"]
                }
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Last Name</FormLabel>
              <TextField
                fullWidth
                value={formik.values.Admin[_idx].last_name}
                placeholder="Last Name"
                name={`Admin.${_idx}.last_name`}
                helperText={
                  formik.touched &&
                  formik.touched.Admin &&
                  formik.touched.Admin.length > 0 &&
                  formik.touched.Admin[_idx] &&
                  formik.touched.Admin[_idx]["last_name"] &&
                  formik.errors &&
                  formik.errors.Admin &&
                  formik.errors.Admin.length > 0 &&
                  formik.errors.Admin[_idx] &&
                  //@ts-expect-error
                  formik.errors.Admin[_idx]["last_name"]
                }
                error={
                  !!formik.errors &&
                  formik.errors.Admin &&
                  formik.errors.Admin.length > 0 &&
                  formik.errors.Admin[_idx] &&
                  //@ts-expect-error
                  formik.errors.Admin[_idx]["last_name"]
                }
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <TextField
                fullWidth
                value={formik.values.Admin[_idx].email}
                placeholder="Email"
                name={`Admin.${_idx}.email`}
                helperText={
                  formik.touched &&
                  formik.touched.Admin &&
                  formik.touched.Admin.length > 0 &&
                  formik.touched.Admin[_idx] &&
                  formik.touched.Admin[_idx]["email"] &&
                  formik.errors &&
                  formik.errors.Admin &&
                  formik.errors.Admin.length > 0 &&
                  formik.errors.Admin[_idx] &&
                  //@ts-expect-error
                  formik.errors.Admin[_idx]["email"]
                }
                error={
                  !!formik.errors &&
                  formik.errors.Admin &&
                  formik.errors.Admin.length > 0 &&
                  formik.errors.Admin[_idx] &&
                  //@ts-expect-error
                  formik.errors.Admin[_idx]["email"]
                }
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Grid>
        ))}

        <FormGroup>
          <FormLabel>Assign Practice</FormLabel>
          <TextField
            fullWidth
            value={formik.values.PracticeTeam}
            placeholder="PracticeTeam"
            name="PracticeTeam"
            select
            error={!!formik.errors.PracticeTeam}
            helperText={
              formik.touched.PracticeTeam && formik.errors.PracticeTeam
            }
            onChange={formik.handleChange}
          >
            {practices.map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </TextField>
        </FormGroup>
      </Stack>
    </form>
  );
};

export default CreateGroup;
