import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

export default function ExpandedLooks(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props?.onClose();
  };

  React.useEffect(() => {
    setOpen(props?.open);
  }, [props]);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions>
          <IconButton
          data-testId="close-zoom"
          aria-label="close-button"
            size="small"
            style={{ marginLeft: "1vw" }}
            onClick={() => {
              setOpen(false);
              props?.onClose();
            }}
          >
            <Clear />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ minHeight: "80vh", maxHeight: "80vh" }}>
          {props?.url && (
            <Box sx={{ width: "98%", height: "72vh" }}>
              <iframe
                title="Map 1"
                src={props?.url}
                height="100%"
                width="100%"
                scrolling="yes"
                frameBorder={0}
              ></iframe>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
