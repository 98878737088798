import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  GridSize,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useGetFilterDataQuery,
  useLazyGetPatientDetailsQuery,
  useLazyPatientSummaryQuery,
} from "../../../../services/modules/queryBuilder";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { enqueueSnackbar } from "notistack";
import PatientDetails from "./PatientDetails";
import AboutPatient, {
  CardTitle,
  DetailItem,
} from "./AboutPatient";
import { IPatientDetails } from "../../../../services/modules/queryBuilder/patientDetails";
import SummaryTabs from "./SummaryTabs";
import PersonIcon from "@mui/icons-material/Person";

const PATIENT_SUMMARY = "Patient Summary";
const PATIENT_TABLE = "patient";

type PatientViewProps = {
  patientId: string;
  onClose: () => void;
};

const RenderGridData = ({
  items,
  title,
  gridSize,
  sx,
}: {
  items: Record<string, string | number | undefined>;
  title: string;
  gridSize: GridSize;
  sx?: SxProps;
}) => (
  <Grid xs={4}>
    <CardTitle title={title} />
    <Grid container rowGap={1} gap={1}>
      {Object.keys(items).map((item) => (
        <DetailItem
          value={items[item]}
          label={item}
          gridSize={gridSize ?? "auto"}
        />
      ))}
    </Grid>
  </Grid>
);

const RenderTabData = ({
  data,
  tabName,
  summary,
  dataLoading,
  summaryLoading,
}: {
  data: any;
  summary: IPatientDetails | undefined;
  tabName: string;
  dataLoading: boolean;
  summaryLoading: boolean;
}) => {
  if (tabName === PATIENT_SUMMARY) {
    if (summaryLoading) {
      return (
        <Grid container mt={3} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress />
        </Grid>
      );
    }
    return (
      <Box py={2}>
        <Typography mb={3}> {summary?.summary}</Typography>
        <Grid container rowGap={3}>
          <RenderGridData
            title="Disease Summary"
            items={{
              "Recent Diagnosis Date": summary?.data?.Recent_Diagnosis_Date,
              "Recent Clinical Disease Status":
                summary?.data?.Recent_Clinical_Disease_Status,
              "Tumor Site": summary?.data?.Tumor_Site,
              "Metastatis Reached": summary?.data?.Metastatis_Reached,
              Comorbidities: summary?.data?.Comorbidities,
              "Charlson Comorbidity Index":
                summary?.data?.Charlson_Comorbidity_Index,
            }}
            gridSize={3.5}
          />
          <RenderGridData
            title="Treatment Details"
            items={{
              "Diagnosis Date": summary?.data?.Diagnosis_Date,
              "Line of Therapy": summary?.data?.Line_of_Therapy,
              "Current Regimen": summary?.data?.Current_Regimen,
              "Therapy Type": summary?.data?.Therapy_Type,
            }}
            gridSize={3.5}
          />
          <RenderGridData
            title="Plan Details"
            items={{
              "MEMBER ID": summary?.data?.Member_Id,
              "PLAN NAME": summary?.data?.Plan_Name,
              "PLAN STATUS": summary?.data?.Plan_Status,
              "PLAN TYPE": summary?.data?.Plan_Type,
              "EFFECTIVE FROM": summary?.data?.Effective_From,
              "EFFECTIVE TO": summary?.data?.Effective_To,
            }}
            gridSize={3.5}
          />

          <RenderGridData
            title="Visit Details"
            items={{
              "Last Visit Date": data?.data?.Visit_Date,
              "Last Visit Type": data?.data?.Visit_Type,
              "Type of Follow up needed":
                data?.data?.Most_Recent_Follow_up_Type,
              "Treatment History": data?.data?.Treatment_History,
            }}
            gridSize={3.5}
          />
          <RenderGridData
            title="Patient Provider"
            items={{
              "PROVIDER ID": summary?.data?.Provider_ID,
              "PROVIDER NAME": summary?.data?.Provider_Name,
              "PRACTICE NAME": summary?.data?.Provider_Name,
              "PRACTICE CITY": summary?.data?.Practice_City,
            }}
            gridSize={3.5}
          />

          <Grid xs={6}>
            {/* @ts-ignore */}
            <SummaryTabs data={summary?.data} />
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (dataLoading) {
    return (
      <Grid container mt={3} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      {Object.keys(data[tabName] ?? {}).length > 1 ? (
        <>
          <PatientDetails item={data[tabName]} value={tabName} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
const PatientView = ({ patientId, onClose }: PatientViewProps) => {
  const { data = {} } = useGetFilterDataQuery();
  const [patientData, setPatientData] = useState<any>({});

  const [value, setValue] = React.useState(PATIENT_SUMMARY);
  const [getTableData, { isLoading, isFetching }] =
    useLazyPatientSummaryQuery();
  const [
    getDetails,
    {
      isLoading: detailsLoading,
      isFetching: detailsFetching,
      data: detailsData,
    },
  ] = useLazyGetPatientDetailsQuery();

  const fetchTableData = async (table: string, value: string) => {
    const result = await getTableData({
      table_name: table,
      patient_id: patientId,
    });
    if ("error" in result) {
      enqueueSnackbar(`Fetching ${table} data failed`, {
        variant: "error",
      });
    } else {
      const { data } = result;
      const pat = patientData;
      //@ts-ignore
      pat[value] = { ...pat[value], ...data };
      setPatientData(pat);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (Object.keys(patientData[newValue]).length === 1) {
      //fetch data
      if (newValue !== PATIENT_SUMMARY) {
        fetchTableData(patientData[newValue].actual_table, newValue);
      }
    }
    setValue(newValue);
  };

  const fetchPatientData = async () => {
    const result = await getDetails({
      patient_id: patientId,
    });
    if ("error" in result) {
      enqueueSnackbar(`Fetching data failed`, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  useEffect(() => {
    if (data) {
      const d = { [PATIENT_SUMMARY]: {} };
      Object.keys(data).forEach((key: string) => {
        if (data[key]) {
         
          //@ts-ignore
          d[key] = {
            //@ts-ignore
            actual_table: data[key][Object.keys(data[key])[0]].ActualTable,
          };
        }
      });
   
      setPatientData(d);
    }
  }, [data]);

  return (
    <>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Avatar
            sx={{
              height: "60px",
              width: "60px",
            }}
          >
            <PersonIcon />
          </Avatar>
          <Typography
            variant="h1"
            noWrap
            sx={{ fontWeight: "bold", maxWidth: "30%", fontSize: "24px" }}
          >
            {/* {detailsData?.data?.Patient_Name} */}
            Patient Info
          </Typography>
          <Button variant="contained" disabled>
            Appointment Scheduling
          </Button>
          <Button variant="outlined" disabled>
            Send Care Assessment
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton aria-label="close-button" onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {detailsData?.data && <AboutPatient item={detailsData?.data} />}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          //@ts-ignore
          textColor="black"
          scrollButtons="auto"
          sx={{
            fontWeight: "bold",
            mt: 1,
            "& .MuiTab-root.Mui-selected": {
              fontWeight: "bold",
            },
          }}
        >
          {Object.keys(patientData).map((key) => (
            <Tab
              key={key}
              value={key}
              label={key}
              sx={{
                textTransform: "capitalize",
                color: "black",
              }}
            />
          ))}
        </Tabs>
        <Divider />
        <RenderTabData
          data={patientData}
          dataLoading={isLoading || isFetching}
          summary={detailsData}
          summaryLoading={detailsLoading || detailsFetching}
          tabName={value}
        />
      </DialogContent>
    </>
  );
};

export default PatientView;
