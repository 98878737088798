import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { getFormData } from "../../serviceUtils";

interface GroupDeleteFormData {
  group_name: string;
  email: string;
}

const deleteGroupData = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, GroupDeleteFormData>({
    query: (payload) => {
      const body = getFormData(payload);
      return {
        url: "/delete_group",
        method: "POST",
        body,
        formData: true,
      };
    },
  });

export default deleteGroupData;
