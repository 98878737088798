import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import Users from "./users/Users";

interface MemberDetailsProps {}

const MemberDetails: FC<MemberDetailsProps> = () => {
  return (
    <Box mt={4}>
      <Typography variant="h2">Member Details</Typography>
      <Users isDisplay />
    </Box>
  );
};

export default MemberDetails;
