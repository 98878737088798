// You do not have access to this report.
// To request access please contact our Specialty Support Customer Success team at 877.453.3972
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export const NoAccessPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "20vh",
        textAlign: "center",
      }}
    >
      <Stack>
        <Typography variant="h1">
          You do not have access to this report.
        </Typography>
        <Typography variant="h4r">
          To request access please contact our Specialty Support Customer
          Success team at 877.453.3972
        </Typography>
      </Stack>
    </Box>
  );
};
