import {
  Box,
  Components,
  MenuItem,
  Theme,
  autocompleteClasses,
} from "@mui/material";
import React from "react";
const overRides = (theme: Theme): Components => {
  return {
    MuiTooltip:{
      defaultProps: {
        sx: {
          "& .MuiTooltip-popper":{backgroundColor: "#BBDDE6",
          color:"black"}
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "0.833vw",
         // padding: "12px 22px",
          height: "auto",
          fontWeight: "bold",
          ":disabled": {
            backgroundColor: theme.palette.common.disabled,
            color: "white",
          },
        },

        outlined: {
          "&:hover": {
            borderColor: theme.palette.common.hover,
          },
          ":disabled": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.disabled,
            borderColor: theme.palette.common.disabled,
          },
        },
        contained: {
          "&:hover": {
            backgroundColor: theme.palette.common.hover,
            color: "white",
          },
        },
        sizeSmall: {
          height: "auto",
          fontSize: "0.729vw",
      //    padding: "8px 16px",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&:last-child td, &:last-child th": { border: 0 },
        },
        icon: {
          // borderLeft: "1px solid #888888",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#C3C3C3",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          "&:focus": {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
      },
      defaultProps: {
        size: "small",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "11.38px",
          fontWeight: "bold",
          color: theme.palette.text.primary,
          marginBottom: "0.5vh",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        //divider: true,
      },
      styleOverrides: {
        root: {
          fontSize: "0.729vw",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold,
          },
          marginLeft: "16px",
          marginRight: "16px",
          borderRadius: "6px",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h3r: "h3",
          h4r: "h4",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.common.divider2,
         
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        renderOption: (props, option, state, ownerState) => (
          <MenuItem {...props}>{ownerState.getOptionLabel(option)}</MenuItem>
        ),
      },
    },
  };
};

export default overRides;
