import * as React from "react";
import Box from "@mui/material/Box";
import {
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import startCase from "lodash.startcase";
import {
  Button,
  Dialog,
  Grid,
  Menu,
  MenuItem,
  styled,
  useTheme,
} from "@mui/material";
import TreeSelect from "./TreeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import { generateFilename } from "./patientListutils";
import { enqueueSnackbar } from "notistack";
import { RootState, useSelector } from "../../../../store/configureStore";
import { useDownloadPatientListMutation } from "../../../../services/modules/queryBuilder";
import TableLoading from "../../../components/Common/TableLoading";
import StyledDataGrid from "../../../components/Datagrid/StyledDatagrid";
import PatientView from "../PatientDetailView/PatientView";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "150px",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No Records Found</Box>
    </StyledGridOverlay>
  );
}

export default function PatientTable({
  staticData,
  onPatientListDismiss,
  applyColumnFilters,
  data,
  isLoading = false,
  onRetry,
  onPageUpdated,
  totalRowCount = 0,
  filters,
  appliedColumns,
}: {
  staticData: any;
  onPatientListDismiss: () => {};
  applyColumnFilters: any;
  data: any;
  isLoading: boolean;
  isError: boolean;
  onRetry: () => void;
  onPageUpdated: (item: GridPaginationModel) => void;
  totalRowCount: number;
  filters: any;
  appliedColumns: any;
}) {
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const open2 = Boolean(anchorEl2);
  const [csvData, setCSVData] = React.useState([]);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
   
    setAnchorEl2(event.currentTarget);
  };
  const {
    cohortData: { CohortName },cohortType,ComparableFilters,
    combineLogic
  } = useSelector((state) => state.CohortReducer);
  const [downloadData] =
    useDownloadPatientListMutation();
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const theme = useTheme();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const rowCountRef = React.useRef(totalRowCount || 0);
  const csvPrintRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  // FOr managing the patient view
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [isPatientViewOpen, setPatientViewOpen] = React.useState<{
    show: boolean;
    data: GridRowParams["row"] | null;
  }>({
    show: false,
    data: null,
  });

  const rowCount = React.useMemo(() => {
    if (totalRowCount !== undefined) {
      rowCountRef.current = totalRowCount;
    }
    return rowCountRef.current;
  }, [totalRowCount]);

  React.useEffect(() => {
    onPageUpdated(paginationModel);
  }, [paginationModel.page, paginationModel.pageSize]);

  /**
   * Generate the
   */
  const columns = React.useMemo<GridColDef<any>[]>(() => {
    if (!(data?.length > 0)) {
      return [];
    }
    let col: any = [];
    Object.keys(data[0]).forEach((columnId) => {
      if (columnId === "_id") {
        return;
      }
      col.push({
        field: columnId,
        headerName: startCase(columnId),
        id: columnId,
        minWidth: 200,
      });
    });
    return col;
  }, [data]);

  const getCSVData = () => {
    setAnchorEl2(null);
    enqueueSnackbar("Your Download has Started", { variant: "info" });
    downloadData(cohortType==="combine"?[{"query":ComparableFilters[0]["Filters"],"columns":appliedColumns,"limit":50000,"pages":0,"operation":combineLogic.toLowerCase()},{"query":ComparableFilters[1]["Filters"],"columns":appliedColumns,"limit":50000,"pages":0,"operation":combineLogic.toLowerCase()}]:[{"query":JSON.stringify(filters),"columns":appliedColumns,"limit":50000,"pages":0,"operation":null}])
      .unwrap()
      .then((result) => {
        setCSVData(result.data);
        setTimeout(() => {
      
          csvPrintRef?.current?.link.click();
        }, 3000);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const onRowClick = (a: GridRowParams) => {
    setRowSelectionModel([a.id]);
    setPatientViewOpen({
      show: true,
      data: a.row,
    });
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "6px",
          boxShadow: "0px 3px 10px #0000001A",
          position: "relative",
          minHeight: "70vh",
          marginBottom: "2vh",
        }}
        mt={2}
       
        id={"patient-detail-table"}
      >
        <Grid container alignItems={"center"} mb={3}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TreeSelect
                staticData={staticData}
                applyColumnFilters={applyColumnFilters}
                appliedColumns={appliedColumns}
              />
              {/* <Box sx={{ flexGrow: 1 }} /> */}

              {(cohortType==="" || cohortType==="combine") && <Button
                sx={{ position: "absolute", right: 0, mr: 3 }}
                startIcon={<FileDownloadIcon />}
                variant="outlined"
                onClick={handleClick2}
                disabled={rowCount === 0}
              >
                Download
              </Button>}
            </Box>
          <Menu
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "export-option-button",
            }}
          >
            <MenuItem onClick={getCSVData}>Export to CSV</MenuItem>
          </Menu>
        </Grid>
        {isLoading ? (
          <TableLoading />
        ) : (
          <div style={{ height: "60vh" }}>
            <StyledDataGrid
              rows={data}
              getRowId={(row) => {
                return row._id;
              }}
              rowCount={rowCount ?? 0}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: true,
              }}
              paginationMode="server"
              paginationModel={paginationModel}
              pageSizeOptions={[rowCount ? 100 : 0]}
              onPaginationModelChange={setPaginationModel}
              onRowClick={onRowClick}
              rowSelectionModel={rowSelectionModel}
              columns={columns}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </div>
        )}
        <CSVLink
          ref={csvPrintRef}
          style={{
            height: "0.3vh",
            textDecoration: "none",
            color: theme.palette.text.primary,
          }}
          filename={generateFilename("PatientList_" + CohortName)}
          asyncOnClick={true}
          data={csvData.map((item: any) => {
            const { _id, ...rest } = item;
            return rest;
          })}
        ></CSVLink>
        {isPatientViewOpen?.data && (
          <Dialog
            open={isPatientViewOpen.show}
            scroll="paper"
            sx={{
              minHeight: "90vh",
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "80vw",
                minHeight: "90vh",
              },
            }}
          >
            <PatientView
              patientId={
                isPatientViewOpen?.data?.Patient_Id ??
                isPatientViewOpen?.data?.patient_id
              }
              onClose={() => {
                setPatientViewOpen({
                  show: false,
                  data: null,
                });
                setRowSelectionModel([]);
              }}
            />
          </Dialog>
        )}
      </Box>
    </>
  );
}
