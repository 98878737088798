export type MenuItem = {
  subModuleId: number;
  moduleId: number;
  subModuleName: string;
  filterTableName: string;
  pageURL: string;
  topMenuGroupId: number;
  topMenuGroupName: string;
  topMenuGroupSortOrder: number;
  moduleName: string;
  demoUrl: null | string;
  isExternal: boolean;
  hasAccess: boolean;
  isDisabled: boolean;
  isQlikView: boolean;
  sortOrder: number;
  isSubModuleDisabled: null | boolean;
};

interface ProcessedMenuSubItem {
  id: number;
  name: string;
  url: string;
  isDisabled: boolean;
  isQlikView: boolean;
  moduleName: string;
  hasAccess: boolean;
  isExternal: boolean;
  isSubModuleDisabled: boolean | null;
}

export interface ProcessedMenuItem {
  id: number;
  name: string;
  sortOrder: number;
  subItems: ProcessedMenuSubItem[];
}

const groupedItems = (items: MenuItem[]): { [key: string]: MenuItem[] } => {
  return items.reduce(
    (acc: { [key: string]: MenuItem[] }, item) => {
      const groupId = item.topMenuGroupId;
      if (groupId != null) {
        // Add null/undefined check
        if (!acc[groupId]) {
          acc[groupId] = [];
        }
        acc[groupId].push(item);
      }
      return acc;
    },
    {} as { [key: string]: MenuItem[] }
  );
};

export const processMenuItems = (
  menuItems: MenuItem[]
): ProcessedMenuItem[] => {
  const grouped = groupedItems(menuItems);

  return Object.entries(grouped)
    .map(([groupId, items]) => {
      const groupItem = items[0];

      //sort and Filter subs
      const subItems = items
        .sort(
          (a: { sortOrder: number }, b: { sortOrder: number }) =>
            a.sortOrder - b.sortOrder
        )
        .map(
          (item: {
            subModuleId: number;
            subModuleName: string;
            pageURL: string;
            isDisabled: boolean;
            isQlikView: boolean;
            moduleName: string;
            hasAccess: boolean;
            isExternal: boolean;
            isSubModuleDisabled: boolean | null;
          }) => ({
            id: item.subModuleId,
            name: item.subModuleName,
            url: item.hasAccess ? item.pageURL : "/no-access",
            isDisabled: item.isDisabled,
            isQlikView: item.isQlikView,
            moduleName: item.moduleName,
            hasAccess: item.hasAccess,
            isExternal: item.isExternal,
            isSubModuleDisabled: item.isSubModuleDisabled,
          })
        );

      return {
        id: parseInt(groupId),
        name: groupItem.topMenuGroupName,
        sortOrder: groupItem.topMenuGroupSortOrder,
        subItems,
      };
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);
};
export const isValidHttp = (url: string) => {
  try {
    const u = new URL(url);
    return u.protocol === "http:" || u.protocol === "https:";
  } catch (error) {
    return false;
  }
};