import { nanoid } from "@reduxjs/toolkit";
import { EndpointBuilder } from "@reduxjs/toolkit/query";

const TypeChecker = (pl: any) => {
  let flag;
  if (pl instanceof FormData) {
    
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};

const getPatientList = (build: EndpointBuilder<any, any, any>) =>
  build.query<any, any>({
    query: (payload) => ({
      url: TypeChecker(payload)
        ? `/get_combine_cohort_patient_list`
        : `/get_patient_list?limit=${payload.limit}&pages=${payload.pages}`,
      method: "POST",
      body: payload,
    }),
    transformResponse: (response) => {
      return {
        ...response,
        data: response?.data?.map((data: any, index: number) => {
          return {
            _id: nanoid(),
            ...data,
          };
        }),
      };
    },
  });

export default getPatientList;
