import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { Box, FormGroup, FormLabel, Typography } from "@mui/material";
import styled from "@emotion/styled";
import dayjs from "dayjs";

const DateRangePickerStyled = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

interface DateRangePickerProps
  extends Omit<DatePickerProps<any, any>, "value"> {
  startDate: any;
  setStartDate: any;
  setEndDate: any;
  endDate: any;
  setError1: any;
  setError2: any;
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const {
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    setError1,
    setError2,
    ...rest
  } = props;
  return (
    <DateRangePickerStyled>
      <FormGroup>
        <FormLabel>From</FormLabel>
        <DatePicker
          value={startDate}
          onError={(error) => {
            setError1(error === null ? false : true);
          }}
          onChange={(start) => {
            setStartDate(start);
          }}
          format="MM-DD-YYYY"
          {...rest}
        />
      </FormGroup>
      <Box sx={{ mx: 1 }}> </Box>
      <FormGroup>
        <FormLabel>To</FormLabel>
        <DatePicker
          value={endDate}
          onError={(error) => setError2(error === null ? false : true)}
          onChange={(start) => {
            setEndDate(start);
          }}
          minDate={startDate}
          format="MM-DD-YYYY"
          {...rest}
        />
      </FormGroup>
    </DateRangePickerStyled>
  );
};

export default function MaterialUIPickers({
  setDateRange,
  initialStartDate,
  initialEndDate,
  setError,
}: {
  setDateRange: any;
  initialStartDate: any;
  initialEndDate: any;
  setError: any;
}) {
  const [start, setStartDate] = React.useState(initialStartDate ?? null);
  const [end, setEndDate] = React.useState(initialEndDate ?? null);
  const [isError1, setError1] = React.useState(false);
  const [isError2, setError2] = React.useState(false);
  const [view,setView]=React.useState<'year' | 'month' | 'day'>("day")

  React.useEffect(() => {
    if (start !== null && end !== null) {
     

      setDateRange([
        dayjs(start).format("DD-MM-YYYY"),
        dayjs(end).format("DD-MM-YYYY"),
      ]);
    }
  }, [start, end]);
  //--
  React.useEffect(() => {
    setError(isError1 || isError2);
  }, [isError1, isError2]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        startDate={start}
        endDate={end}
      
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setError1={setError1}
        setError2={setError2}
        views={['year', 'month', 'day']}  
        openTo={view}  
        onViewChange={(newView:any) => setView(newView)}
        // @ts-ignore
        renderInput={(params: TextFieldProps) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
