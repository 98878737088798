import { EndpointBuilder } from "@reduxjs/toolkit/query";

export interface Filter {}

const getFilterData = (build: EndpointBuilder<any, any, any>) =>
  build.query<any, void>({
    query: () => ({
      url: "/get_filter",
      method: "GET",
    }),
  });

export default getFilterData;
