import { Tooltip, Typography, TypographyProps } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";

export interface OverflowTypographyProps extends TypographyProps {
  children: React.ReactNode;
}

export const OverflowTypography: FC<OverflowTypographyProps> = ({
  children,
  ...props
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    const compareSize = () => {
      if (ref.current) {
        const compare = ref.current.scrollWidth > ref.current.clientWidth;

        setTooltipEnabled(compare);
      }
    };
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => window.removeEventListener("resize", compareSize);
  }, []);

  return (
    <Tooltip title={children} disableHoverListener={!tooltipEnabled}>
      <Typography
        ref={ref}
        noWrap
        overflow="hidden"
        textOverflow="ellipsis"
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
