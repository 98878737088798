import { Box, Chip, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import PatientCount from "./PatientCount";
import SaveMDNew from "./SaveModalNew";
import { CiEdit } from "react-icons/ci";

const StyledChip = (props) => {
  return (
    <Chip
      sx={{
        minHeight: "2vh",
        maxHeight: "4vh",
        padding: "0",
        ml: "1vh",
        mb: "0.5vh",
        borderRadius: "0.6vh",
      }}
      label={
        <Typography
          variant="body2"
          sx={{
            heigh: "100%",
            mr: "0.8vw",
          }}
        >
          {props?.text}
        </Typography>
      }
      variant="outlined"
      onDelete={(e) => {
        props?.modifyState(e);
      }}
      size="small"
      deleteIcon={props?.isGlobal ? <deleteIcon disabled/> : <ClearIcon data-testid='chip-del-btn' color="primary" className="no-print" />}
    />
  );
};


const DateTags = {
  "Greater Than": "After",
  "Less Than": "Before",
  "Equal To": "Is On",
  Between: "Is Between",
  "Not Between": "Is not Between",
 "Not Like":"Not On",
 "Not Equal To": "Not On",
};

const DataTagMod = (v, t) => {
  if (t === "Date Range") {
    return DateTags[v];
  }
  return v;
};
const SingleCohortFilters = (props) => {
  const [OpenSaveModal, setOpenSaveModal] = useState(false);
  const DateFormatter = (d) => {
    let a = d.split("-");
    return `${a[1]}-${a[0]}-${a[2]}`;
  };
  return (
    <Stack
      sx={{
        overflow: "visible",
        maxHeight: "auto",
        minHeight: "auto",
        overflowX: "hidden",
        overflowY: "auto",
        pb: "1vh",
        backgroundColor: "white",
      }}
    >
      {Object.keys(props?.selectedFilters)?.length > 0 && !props?.isGlobal && (
        <>
          {" "}
          <Typography
            sx={{
              color: "#333333",
              fontSize: "1.3vw",
              fontWeight: "bolder",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {props?.corhotName ? props?.corhotName : "Untitled Cohort"}

            <IconButton
             data-testId="Save-Ico"
                    
                    size="small"
                    sx={{
                      cursor: "pointer",
                      marginLeft: "0.5vw",
                      marginRight: "0.5vw",
                    }}
                    onClick={() => {
                      setOpenSaveModal(true);
                    }}
                    className="no-print"
                  >
            <CiEdit/>
            </IconButton>
            {props?.filterSaved ? null : (
              <Typography
                sx={{
                  color: "red",
                  ml: "0.2vw",
                }}
                variant="h2"
              >
                *
              </Typography>
            )}
          </Typography>
          <PatientCount
            viewPatientList={props.viewPatientList}
            selectedFilters={props.selectedFilters}
            isLoading={props.isLoading}
          />
          <Divider sx={{ my: 2 }} />
          <Typography
            sx={{ display: "block", width: "100%", fontSize: "0.833vw" }}
            variant="h4"
          >
            Select Patients
          </Typography>
        </>
      )}
      <Stack
        direction="column"
        spacing={1}
        sx={{
          overflow: "visible",
          overflowY: "auto",
          maxHeight: "auto",
          minHeight: "auto",
          overflowX: "hidden",
          display: "flex",
          pb: "1vw",
        }}
      >
        {Object.keys(props?.selectedFilters)?.length > 0 &&
          Object.keys(props?.selectedFilters)?.map((f, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {index === 0 ? (
                  <Typography sx={{ mt: "1vh", fontSize: "0.833vw" }}>
                    {!props?.isGlobal ? "With" : ""}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      mt: "1vh",
                      p: "0.5vw",
                      fontWeight: "bold",
                      backgroundColor: "#3F7D98",
                      borderRadius: "3px",
                      color: "white",
                      maxWidth: "fit-Content",
                      //    alignSelf:"center"
                    }}
                    variant="h4"
                    onClick={() => {
                      props?.modifyFilters(
                        f,
                        props?.selectedFilters[f]["Main"]
                      );
                    }}
                  >
                    {props?.selectedFilters[f]["JoinBy"]}
                  </Typography>
                )}
                <Stack
                  sx={{
                    ml: "0.5vw",
                    mt: "1.2vh",
                    //    alignItems:"center"
                  }}
                  direction={"row"}
                >
                  <Typography
                    sx={{
                      fontSize: "0.833vw",
                      color: "primary.main",
                      cursor: "pointer",
                      "&:hover": {
                        color: "common.hover",
                      },
                    }}
                    variant="body2"
                    onClick={() => {
                      props?.modifyFilters(
                        f,
                        props?.selectedFilters[f]["Main"]
                      );
                    }}
                  >
                    {f}
                  </Typography>{" "}
                  <Typography
                    variant="body1"
                    sx={{ ml: "0.25vw", fontSize: "0.833vw" }}
                  >{`${
                    props?.selectedFilters[f]["condition"] !== "Is Blank"
                      ? DataTagMod(
                          props?.selectedFilters[f]["condition"],
                          props?.selectedFilters[f]["type"]
                        )
                      : ""
                  }`}</Typography>
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    mt: "1vh",
                    flexWrap: "wrap",
                    alignContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {props?.selectedFilters[f]["type"] === "Multi Select" &&
                    props?.selectedFilters[f]["condition"] === "Is Blank" && (
                      <StyledChip
                        text="Is Blank"
                        modifyState={(e) => {
                          props?.modifyStateVar(e, f);
                        }}
                        isGlobal={props?.isGlobal}
                      />
                    )}

                  {props?.selectedFilters[f]["type"] === "Multi Select" &&
                    props?.selectedFilters[f]["condition"] !== "Is Blank" &&
                    props?.selectedFilters[f]["val"]?.map((v) => {
                      return (
                        <StyledChip
                          text={v}
                          modifyState={(e) => {
                            props?.modifyStateVar(e, f, v);
                          }}
                          isGlobal={props?.isGlobal}
                        />
                      );
                    })}

                  {props?.selectedFilters[f]["type"] !== "Multi Select" &&
                    props?.selectedFilters[f]["condition"] !== "Between" &&  props?.selectedFilters[f]["condition"] !== "Not Between" && (
                      <StyledChip
                        text={
                          props?.selectedFilters[f]["type"] === "Date Range"
                            ? DateFormatter(props?.selectedFilters[f]["val"])
                            : props?.selectedFilters[f]["val"]
                        }
                        modifyState={(e) => {
                          props?.modifyStateVariableSingleValue(e, f);
                        }}
                        isGlobal={props?.isGlobal}
                      />
                    )}

                  {(props?.selectedFilters[f]["type"] === "Number Range" ||
                    props?.selectedFilters[f]["type"] === "Date Range") &&
                    (props?.selectedFilters[f]["condition"] === "Between" || props?.selectedFilters[f]["condition"] === "Not Between") && (
                      <StyledChip
                        text={
                          props?.selectedFilters[f]["type"] === "Date Range"
                            ? `${DateFormatter(
                                props?.selectedFilters[f]["val"][0]
                              )} And
                        ${DateFormatter(props?.selectedFilters[f]["val"][1])}`
                            : `${props?.selectedFilters[f]["val"][0]} And
                   ${props?.selectedFilters[f]["val"][1]}`
                        }
                        modifyState={(e) => {
                          props?.modifyStateVariableSingleValue(e, f);
                        }}
                        isGlobal={props?.isGlobal}
                      />
                    )}
                </Box>
              </Box>
            );
          })}
      </Stack>
      {OpenSaveModal && (
        <SaveMDNew
          open={OpenSaveModal}
          name={props?.corhotName}
          filters={props?.selectedFilters}
          description={props?.description}
          onClose={() => {
            setOpenSaveModal(false);
          }}
          onOk={(nm, des) => {
            props?.setCorhotName(nm);
            props?.setDescription(des);
            if (nm !== props?.corhotName) {
              props?.setNameChanged(true);
            }
          }}
        />
      )}
    </Stack>
  );
};

export default SingleCohortFilters;
