import {
  Stack,
  TextField,
  Box,
  Divider,
  FormLabel,
  DialogActions,
  Grid,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import {
  useGetCohortDescriptionMutation,
  useSaveCohortDataMutation,
} from "../../../services/modules/queryBuilder";
import { useSelector } from "../../../store/configureStore";
import { setCohortData } from "../../../store/reducers/CohortReducer";
import { useDispatch } from "react-redux";
import {
  useLazyGetAllCohortsQuery,
  useRenameCohortMutation,
} from "../../../services/modules/viewAllCohorts";
import { UseStaticDash } from "../../Context/StaticDashboardsContext";

const SaveMDNew = (props) => {
  const [open, setOpen] = React.useState(props?.open);
  const [name, setName] = useState(props?.name.trim());
  const [description, setDescription] = useState(props?.description ?? "");
  const [getAllCohorts, { data: allCohorts, error }] =
    useLazyGetAllCohortsQuery();
  const [renameCohort] = useRenameCohortMutation();
  const [saveCohort, response] = useSaveCohortDataMutation();
  const [errorMsg, setErrorMsg] = useState("");
  const { userName } = useSelector((state) => state.LoginReducer);
  const [getDescription, { isLoading }] = useGetCohortDescriptionMutation();

  const dispatch = useDispatch();
  const { setListChange } = UseStaticDash() ?? { setListChange: null };

  React.useEffect(() => {
    setOpen(props.open);
    setName(props?.name);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    setErrorMsg("");
    props?.onClose();
  };

  const handleCloseApply = () => {
    const formdata = new FormData();
    formdata.append("user_id", userName);
    formdata.append("cohort_name", name);
    formdata.append("filter_value", props?.filters);
    formdata.append("dashboard_id", props?.dashboards);
    formdata.append("description", description ?? "");
    formdata.append("columns", props?.columns);
    formdata.append("isnew", props?.isNew ? "True" : "False");
    saveCohort(formdata)
      .then((result) => {
        if (result === "Cohort name already exists") {
          setErrorMsg(result);
        } else {
          setErrorMsg("");
          setOpen(false);
          const cohortData = {
            Filters: JSON.stringify(props?.filters),
            CohortName: name,
            Dashboards: JSON.stringify(props?.dashboards),
            Columns: JSON.stringify(props?.columns)
          };
          if (!props?.isCopy) {
            dispatch(setCohortData(cohortData));
          }
          props?.onOk();
          setListChange((prev) => {
            let a = prev;
            a = prev + 1;
            return a;
          });
          props?.onClose();
        }
      })
      .catch((error) => {
        setErrorMsg(error.status);
        console.log(JSON.stringify(error));
      });
  };

  const onGetDescription = () => {
    const formdata = new FormData();
    formdata.append("filter", JSON.stringify(props?.filters));
    getDescription(formdata)
      .unwrap()
      .then((result) => {
        setDescription(result);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            border: "1px solid #EAEBEA",
            margin: "1.5vw",

            borderRadius: "6px",
          }}
        >
          {props?.isCopy && (
            <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h4">Copy this Cohort</Typography>
            </DialogTitle>
          )}
          {/* <Divider sx={{ mx: "1vw" }} /> */}
          <DialogContent
            sx={{
              minWidth: "20vw",

              overflowX: "hidden",
              //pb: 5,
            }}
          >
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
            >
              <Grid container spacing={4}>
                <Grid xs={12} item>
                  <FormLabel>Name</FormLabel>
                  <TextField
                    id="cohort-name"
                    fullWidth
                    inputProps={{ "data-testid": "cohort-name" }}
                    variant="outlined"
                    sx={{ width: "100%", fontSize: "1vw" }}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    error={errorMsg}
                    helperText={errorMsg}
                    // disabled={!props.isNew}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    //gap={1}
                    mb={1}
                  >
                    <FormLabel>Description</FormLabel>
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={() => {
                        onGetDescription();
                      }}
                      sx={{ marginLeft: "auto" }}
                    >
                      {isLoading ? (
                        <>
                          Generating AI Description ...
                          <CircularProgress size={20} sx={{ ml: 2 }} />
                        </>
                      ) : (
                        "Generate AI Description"
                      )}
                    </Button>
                  </Stack>
                  <textarea
                    id="cohort-description"
                    data-testid="cohort-description"
                    style={{
                      width: "100%",
                      fontSize: "1vw",
                      padding: "1vh",
                      borderRadius: "3px",
                      border: "1px solid #c4c4c4",
                      resize: "none",
                    }}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </form>

            {/* ........................................................... */}
          </DialogContent>
          <DialogActions>
            <Stack
              direction={"row"}
              sx={{
                pb: "1vw",
                px: "1vw",

                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
              spacing={2}
            >
              <Button onClick={handleClose} variant="outlined" size="small">
                Cancel
              </Button>

              <Button
                data-testId="Done-Btn-Save"
                variant="contained"
                sx={{ ml: "auto" }}
                disabled={name.trim() === ""}
                onClick={(e) => {
                  if (props?.isCopy) {
                    handleCloseApply();
                  } else {
                    props?.onOk(name, description);
                    props?.onClose();
                  }
                }}
                size="small"
              >
                {props?.isCopy ? "Copy" : "Done"}
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default SaveMDNew;
