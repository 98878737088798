import React from 'react';
import { MeasuringStrategy } from "@dnd-kit/core";
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
//@ts-ignore
import DOMPurify from 'dompurify';

import { Sortable, Props as SortableProps } from "./Sortable";
import { GridContainer } from "./Components/GridContainer";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { Handle } from "./Item";
import { DragItemProps } from "./Item/Item";
import {
  Clear,
  Edit,
  OpenInFull,
} from "@mui/icons-material";


function immutableMove(arr: any[], from: number, to: number) {
  return arr.reduce(
    (prev: any[], current: any, idx: any, self: { [x: string]: any }) => {
      if (from === to) {
        prev.push(current);
      }
      if (idx === from) {
        return prev;
      }
      if (from < to) {
        prev.push(current);
      }
      if (idx === to) {
        prev.push(self[from]);
      }
      if (from > to) {
        prev.push(current);
      }
      return prev;
    },
    []
  );
}

const props: Partial<SortableProps> = {
  adjustScale: true,
  Container: (props: any) => <GridContainer {...props} columns={2} />,
  strategy: rectSortingStrategy,
  wrapperStyle: () => ({
    width: "100%",
    height: "100%",
  }),
};

const IFrame = React.memo(
  ({ url, key }: { url: string; key: string }) => {
    return (
      <iframe
        style={{
          minHeight: "40vh",
          maxHeight: "40vh",
        }}
        src={DOMPurify.sanitize(url)}
        sandbox="allow-scripts allow-same-origin"
        key={key}
        title={"Visuals"}
        width="100%"
        scrolling="yes"
        frameBorder={0}
      ></iframe>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.url === nextProps.url;
  }
);

const VisualCard = React.memo(
  ({
    value,
    index,
    handle,
    handleProps,
    listeners,
    title,
    onExpand,
    onEdit,
    onItemRemove,
  }: DragItemProps & {
    title?: string;
    onExpand: (i: number) => void;
    onEdit: (i: number) => void;
    onItemRemove: (i: number) => void;
  }) => {
    return (
      <Box
        data-cypress="draggable-item"
        aria-label="draggable-item"
        sx={{
          boxShadow: "0px 3px 6px #00000014",
          border: "1px solid #EAEBEA",
          borderRadius: "0.5vh",
          padding: "1vw",
          mb: "1vh",
          minHeight: "50vh",
          maxHeight: "50vh",
          width: "100%",
          backgroundColor: "white",
        }}
        key={value as string}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip placement="top" title={title}>
            <Typography
              variant="h3"
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {title}
            </Typography>
          </Tooltip>
          <Tooltip placement="top" title={"Edit Visuals"}>
            <IconButton
            data-testID="edit-vis-ico"
              size="small"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                onEdit && onEdit(index ?? 0);
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title={"Zoom Visual"}>
            <IconButton
            data-testID="zoom-vis-ico"
              size="small"
              onClick={() => {
                onExpand && onExpand(index ?? 0);
              }}
            >
              <OpenInFull fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title={"Drag & Move"}>
            {handle ? <Handle {...handleProps} {...listeners} /> : <></>}
          </Tooltip>
          <Tooltip placement="top" title={"Clear Visual"}>
            <IconButton
            data-testID="remove-vis-ico"
              size="small"
              onClick={() => {
                onItemRemove && onItemRemove(index ?? 0);
              }}
            >
              <Clear fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        <IFrame url={value as string} key={value as string} />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value && prevProps.title === nextProps.title
    );
  }
);

const VisualSort = ({
  embedUrl,
  visualsArr,
  setVisualsArr,
  setEmbedUrl,
  onExpand,
  onEdit,
  onRemove,
  isColumn,
}: {
  embedUrl: string[];
  visualsArr: any[];
  setVisualsArr: any;
  setEmbedUrl: any;
  onExpand: (i: number) => void;
  onEdit: (i: number) => void;
  onRemove: (i: number) => void;
  isColumn: boolean;
}) => {
  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  const reOrderItems = (items: any[], from: number, to: number) => {
    let p = [...items];
    let s = immutableMove(p, from, to);
    // Update visual arrays and embed arrays
    setVisualsArr(immutableMove(visualsArr, from, to));
    setEmbedUrl(s);
    return s;
  };

  const parseTitle = (
    arr: { value: string }[][] | undefined,
    index: number
  ) => {
    if (arr && arr?.length > 0) {
      if (arr[index] && arr[index]?.length > 1) {
        return `${arr[index][0]["value"]} X ${arr[index][1]["value"]}`;
      } else if (arr[index]) {
        return arr[index][0]["value"];
      }
    }
    return "";
  };

  return (
    <Sortable
      {...props}
      animateLayoutChanges={animateLayoutChanges}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      removable
      items={embedUrl}
      renderItem={(props: DragItemProps) => (
        <VisualCard
          {...props}
          title={parseTitle(visualsArr, props.index ?? 0)}
          key={props.value as string}
          onExpand={onExpand}
          onEdit={onEdit}
          onItemRemove={onRemove}
        />
      )}
      handle
      reorderItems={reOrderItems}
      isColumn={isColumn}
    />
  );
};

export default VisualSort;
