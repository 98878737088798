import { EndpointBuilder } from "@reduxjs/toolkit/query";

const getCombinedCohortCount = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, any>({
    query: (payload) => ({
      url: "/get_combine_cohort_count",
      method: "POST",
      body: payload,
    }),
  });

export default getCombinedCohortCount;
