import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { getFormData } from "../../serviceUtils";
import { IUserBase } from "./getGroups";

export interface GroupFormData {
  group_name: string;
  Description: string;
  Admin: IUserBase[];
  PracticeTeam: string;
  Role: string;
  email: string;
  isnew: "True" | "False";
}

const saveGroupData = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, GroupFormData>({
    query: (payload) => {
      const body = getFormData(payload);
      return {
        url: "/add_update_group",
        method: "POST",
        body,
        formData: true,
      };
    },
  });

export default saveGroupData;
