import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import PageContainer from "../../components/PageContainer/PageContainer";
import { DEFAULT_SELECTED_COLUMNS } from "../../constants";
import { MdAutoAwesome, MdOutlineDeleteSweep } from "react-icons/md";
import html2canvas from "html2canvas";
import AppDrawer from "../../components/SideDrawer";
import React, { useEffect, useRef, useState } from "react";
import BuilderSideBar from "./BuilderSideBar";
import { jsPDF } from "jspdf";
import BuilderVisualSideBar from "./BuilderVisualSideBar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdAddchart } from "react-icons/md";
import { useSelector } from "../../../store/configureStore";
import { useDispatch } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import {
  resetCohortData,
  setCohortData,
  setExportable,
  setFilterSaved,
  setPatientCount,
} from "../../../store/reducers/CohortReducer";

import {
  useGetFilterDataQuery,
  useGetPatientCountMutation,
  useSaveCohortDataMutation,
} from "../../../services/modules/queryBuilder";
import { IoMdUndo } from "react-icons/io";
import SingleCohortFilters from "./SingleCohortFilters";
import CohortCompare from "./CohortCompare";
import PatientList from "./PatientList/PatientList";
import PatientCount from "./PatientCount";
import DisplayButtons from "./DisplayButtons";
import HTMLToCanvasIgnore from "../../components/Common/HtmlToCanvasIgnore";
import AlertDialogNLP from "./NaturalLanguageModal";
import { enqueueSnackbar } from "notistack";
import ExportModal from "../All Corhots/ExportModal";
import AlertDialog from "../../components/Dialogs/AlertDialog";
import ExpandedLooks from "./ExpandedLooks";
import SaveMDNew from "./SaveModalNew";
import { CiEdit } from "react-icons/ci";
import VisualSort from "./DragContainer";
import IndvidualVisGetter from "./IndvidualVisualGetter";

function Home() {
  const [NameChanged, setNameChanged] = useState(false);
  const { data } = useGetFilterDataQuery();
  const [visualModifyState, setVisualModifyState] = useState(false);
  const [VisualModifiableData, setVisualModifyableData] = useState(null);
  const [ModifiableVisualIndex, setModifiableVisualIndex] = useState(null);
  const [expandedLookModal, setExpandedLookModal] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [StaticData, setStaticData] = useState(data ?? {});
  const [ApplyingChanges, setApplyingChanges] = useState(false);
  const [VisualsArr, setVisualsArr] = useState([]);
  const [embedUrl, setEmbedUrl] = useState([]);
  const [gettingVisuals, setGettingVisuals] = useState(false);
  const [orientation, setOrientation] = useState(0);
  const [corhotName, setCorhotName] = useState("");
  const [description, setDescription] = useState("");
  const [open, toggleDrawer] = React.useState(false);
  const [openFilterModal, setOpenFitersModal] = React.useState(false);
  const [showUnsavedDialog, setShowUnsaved] = useState(false);
  const [openNLPModal, setOpenNLPModal] = React.useState(false);
  const [OpenSaveModal, setOpenSaveModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [ApplyState, setApplyState] = React.useState(false);
  const [openVisuals, toggleDrawerVisuals] = React.useState(false);
  const [ValToFilter, setValtoFilter] = useState("");
  const [ValToFilterMain, setValtoFilterMain] = useState("");
  const [undoableStates, setUndoableStates] = useState([]);
  const {
    cohortData,
    filterSaved,
    patientCount,
    totalCount,
    cohortType,
    ComparableFilters,
    combineLogic,
    isExportable,
    comparedCohortTitle,
  } = useSelector((state) => state.CohortReducer);
  const [viewPatientList, setViewPatientList] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    DEFAULT_SELECTED_COLUMNS
  );
  const handleExportClose = () => {
    const Coh = {
      Filters: JSON.stringify(cohortData.Filters),
      CohortName: cohortData.CohortName,
      Dashboards: JSON.stringify(cohortData.Dashboards),
    };
    dispatch(setCohortData(Coh));
    dispatch(setExportable(false));
    setShowExportModal(false);
  };
  useEffect(() => {
    if (data) {
      setStaticData(data);
    }
  }, [data]);

  useEffect(() => {
    const handleOnBeforeUnload = (e) => {
      if (!filterSaved && Object.keys(selectedFilters)?.length > 0) {
        e.preventDefault();
        return (e.returnValue = "Cohort Unsaved?Do you want to leave");
      }
      return;
    };
    window.addEventListener("beforeunload", handleOnBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload);
    };
  }, [filterSaved]);
  const [getCount, { isLoading }] = useGetPatientCountMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    });
  }, [viewPatientList]);

  useEffect(() => {
    if (cohortType === "compare" || cohortType === "combine") {
      setEmbedUrl([]);
      setVisualsArr([]);
      setViewPatientList(false);
    }
  }, [cohortType, ComparableFilters]);

  useEffect(() => {
    if (Object.keys(selectedFilters)?.length === 0) {
      setUndoableStates([]);
      dispatch(setFilterSaved(undefined));
    }
    if (
      (filterSaved === false || filterSaved === undefined) &&
      VisualsArr?.length > 0
    ) {
      setApplyState(true);
    } else {
      setApplyState(false);
    }

    if (cohortData.CohortName === "Untitled Cohort" || filterSaved === false) {
      getPatientCount(selectedFilters);
    }
  }, [selectedFilters]);

  const getPatientCount = (filters, asd) => {
    if (isLoading) {
      return;
    }

    getCount({ query: JSON.stringify(filters ?? selectedFilters) })
      .unwrap()
      .then((result) => {
        dispatch(
          setPatientCount({
            patientCount: result.filter_count,
            totalCount: result.total_count,
          })
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          typeof error === "string" ? error : "Something went wrong",
          { variant: "error" }
        );
        //Clear patient count
        dispatch(
          setPatientCount({
            patientCount: 0,
          })
        );
      });
  };

  const AppliedChanges = async () => {
    if (VisualsArr?.length > 0) {
      let Visuals = VisualsArr;

      for (let index = 0; index < Visuals?.length; index++) {
        let resp = await IndvidualVisGetter(
          Visuals[index],
          cohortType,
          selectedFilters,
          combineLogic,
          Visuals[index][0]["Chart"],
          Visuals[index][0]["KPI"],
          StaticData,
          Visuals[index]
        );
        if (resp && resp?.status) {
          setEmbedUrl((prev) => {
            let p = [...prev];
            p[index] = resp?.url;
            return p;
          });
          getPatientCount(selectedFilters);
          setApplyState(false);
          setApplyingChanges(false);
        } else {
          setApplyingChanges(false);
        }
      }
    }
  };

  const CloseModalState = () => {
    setValtoFilter("");
    setOpenFitersModal(false);
  };
  const getFilterVal = (a, b) => {
    setValtoFilter(a);

    setValtoFilterMain(b);
    toggleDrawer(true);
  };

  useEffect(() => {
    if (Object.keys(StaticData)?.length > 0) {
      viewData();
    }
  }, [cohortData.CohortName, StaticData]);

  useEffect(() => {
    //Clear selected filters and visuals after clicking discard when moving to new cohort
    if (filterSaved === undefined && Object.keys(selectedFilters)?.length > 0) {
      setSelectedFilters({});
      setVisualsArr([]);
      setEmbedUrl([]);
      setCorhotName("");
      setDescription("");
    }
  }, [filterSaved]);

  const viewData = async () => {
    if (cohortData.CohortName !== "Untitled Cohort" && cohortType === "") {
      dispatch(setFilterSaved(true));
      setSelectedFilters(JSON.parse(cohortData.Filters));
      setCorhotName(cohortData.CohortName);
      setDescription(cohortData.Description);
      setVisualsArr(JSON.parse(cohortData.Dashboards));
      if (patientCount === 0) {
        getPatientCount(JSON.parse(cohortData.Filters));
      }

      setSelectedColumns(JSON.parse(cohortData?.Columns || "{}"));
      let Visuals = JSON.parse(cohortData.Dashboards);
      let FLTRS = JSON.parse(cohortData.Filters);
      for (let index = 0; index < Visuals?.length; index++) {
        let resp = await IndvidualVisGetter(
          Visuals[index],
          cohortType,
          JSON.parse(cohortData.Filters),
          combineLogic,
          Visuals[index][0]["Chart"],
          Visuals[index][0]["KPI"],
          StaticData,
          Visuals[index]
        );
        if (resp && resp.status) {
          setEmbedUrl((prev) => {
            let p = [...prev];
            p[index] = resp.url;
            return p;
          });
          setGettingVisuals(false);
        } else {
          setGettingVisuals(false);
        }
      }
    } else {
      if (Object.keys(selectedFilters)?.length > 0) {
        setSelectedFilters({});
      }
      setVisualsArr([]);
      setEmbedUrl([]);
      setCorhotName("");
      setDescription("");
    }
  };

  useEffect(() => {
    if (!open) {
      setValtoFilter(undefined);
      setValtoFilterMain(undefined);
    }
  }, [open]);
  const ApplyFilter = (k, v) => {
    setValtoFilter(undefined);
    setValtoFilterMain(undefined);
    toggleDrawer(false);
    setUndoableStates((prev) => {
      let arra = [...prev];
      arra.push(selectedFilters);

      return arra;
    });
    setSelectedFilters((prev) => {
      let a = { ...prev };
      a[k] = v;
      return a;
    });
    dispatch(setFilterSaved(false));
  };

  const modifyStateVariable = (e, key, value) => {
    e.preventDefault();

    setSelectedFilters((prev) => {
      let a = { ...prev };
      let stringifiedA = JSON.stringify(prev);
      let varr = a[key]["val"];

      let farr = varr?.filter((v) => v !== value);

      if (farr?.length === 0 || a[key]["condition"] === "Is Blank") {
        delete a[key];
        if (Object.keys(a)?.length === 0) {
          dispatch(resetCohortData());
          dispatch(
            setPatientCount({
              patientCount: 0,
            })
          );
          setCorhotName("");
          setDescription("");
          setEmbedUrl([]);
          setVisualsArr([]);
          dispatch(setFilterSaved(undefined));
          return a;
        }
        dispatch(setFilterSaved(false));
        return a;
      } else {
        setUndoableStates((prev) => {
          let arra = [...prev];

          arra.push(JSON.parse(stringifiedA));
          return arra;
        });
        a[key]["val"] = farr;
        dispatch(setFilterSaved(false));
        return a;
      }
    });
  };

  const modifyStateVariableSingleValue = (e, key) => {
    e.preventDefault();

    setSelectedFilters((prev) => {
      let a = { ...prev };

      let b = JSON.stringify(a);

      setUndoableStates((prev) => {
        let arra = [...prev];
        arra.push(JSON.parse(b));
        return arra;
      });
      delete a[key];
      if (Object.keys(a)?.length === 0) {
        setCorhotName("");
        setDescription("");
        dispatch(resetCohortData());
        dispatch(
          setPatientCount({
            patientCount: 0,
          })
        );
        setEmbedUrl([]);
        setVisualsArr([]);
        dispatch(setFilterSaved(undefined));
        return a;
      }
      dispatch(setFilterSaved(false));
      return a;
    });
  };

  const queryScreenWidthHandler = () => {
    if (orientation === 0) {
      setOrientation(1);
    }
    if (orientation === 1) {
      setOrientation(0);
    }
    if (orientation === 2) {
      setOrientation(0);
    }
  };

  const VisualScreenWidthHandler = () => {
    if (orientation === 0) {
      setOrientation(2);
    }
    if (orientation === 1) {
      setOrientation(0);
    }
    if (orientation === 2) {
      setOrientation(0);
    }
  };

  const getVisualsVal = async (a, previewUrl) => {
    if (previewUrl) {
      setGettingVisuals(true);
      setVisualsArr((prev) => {
        let p = [...prev];
        if (visualModifyState) {
          p[ModifiableVisualIndex] = a;
          return p;
        }
        p.push(a);
        return p;
      });

      setEmbedUrl((prev) => {
        let p = [...prev];
        if (visualModifyState) {
          p[ModifiableVisualIndex] = previewUrl;
          return p;
        }
        p.push(previewUrl);
        return p;
      });
      if (cohortType === "") {
        dispatch(setFilterSaved(false));
      }
      if (visualModifyState) {
        setVisualModifyState(false);
        setModifiableVisualIndex(null);
        setVisualModifyableData(null);
      }
      setGettingVisuals(false);
      return;
    } else {
      setGettingVisuals(true);
      let resp = await IndvidualVisGetter(
        a,
        cohortType,
        cohortType === "" ? selectedFilters : ComparableFilters,
        combineLogic,
        a[0]["Chart"],
        a[0]["KPI"],
        StaticData,
        a
      );

      if (resp && resp.status) {
        setVisualsArr((prev) => {
          let p = [...prev];
          if (visualModifyState) {
            p[ModifiableVisualIndex] = a;
            return p;
          }
          p.push(a);
          return p;
        });

        setEmbedUrl((prev) => {
          let p = [...prev];
          if (visualModifyState) {
            p[ModifiableVisualIndex] = resp.url;
            return p;
          }
          p.push(resp.url);
          return p;
        });
        setGettingVisuals(false);
        if (visualModifyState) {
          setVisualModifyState(false);
          setModifiableVisualIndex(null);
          setVisualModifyableData(null);
        }
        if (cohortType === "") {
          dispatch(setFilterSaved(false));
        }
      } else {
        if (visualModifyState) {
          setVisualModifyState(false);
          setModifiableVisualIndex(null);
          setVisualModifyableData(null);
        }
      }
      setGettingVisuals(false);
    }
  };

  const viewPatientListItemSwitch = (v) => {
    setViewPatientList(v);
  };

  const GetNLPFilters = (f) => {
    let stringifiedA = JSON.stringify(selectedFilters);
    setUndoableStates((prev) => {
      let arra = [...prev];

      arra.push(JSON.parse(stringifiedA));
      return arra;
    });
    setSelectedFilters(f);
    dispatch(setFilterSaved(false));
  };
  const printRef = useRef(null);

  const IOSSwitch = styled((props) => (
    <Switch
      color="primary"
      checked={viewPatientList}
      value={viewPatientList}
      onChange={(e) => {
        viewPatientListItemSwitch(e.target.checked);
      }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(26px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#3F7D98",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const { userName } = useSelector((state) => state.LoginReducer);
  const [saveCohort, response] = useSaveCohortDataMutation() ?? [null, null];
  const handleCloseApplySaveold = () => {
    const formdata = new FormData();
    formdata.append("user_id", userName);
    formdata.append("cohort_name", corhotName.trim());
    formdata.append("filter_value", JSON.stringify(selectedFilters));
    formdata.append("dashboard_id", JSON.stringify(VisualsArr));
    formdata.append("columns", JSON.stringify(selectedColumns));
    formdata.append("description", description ?? "");
    formdata.append("isnew", "False");
    saveCohort(formdata)
      .then((result) => {
        if (result === "Cohort name already exists") {
          enqueueSnackbar("Cohort with this name already exist", {
            variant: "error",
          });
        } else {
          const cohortData = {
            Filters: JSON.stringify(selectedFilters),
            CohortName: corhotName,
            Dashboards: JSON.stringify(VisualsArr),
            Columns: JSON.stringify(selectedColumns),
          };

          dispatch(setCohortData(cohortData));

          if (ApplyState) {
            AppliedChanges();
          }
          setApplyState(false);
          setUndoableStates([]);
          dispatch(setFilterSaved(true));
          getPatientCount();
          enqueueSnackbar("Cohort saved Successfully", { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.status, { variant: "error" });
      });
  };

  const handleCloseApplySaveNew = () => {
    const formdata = new FormData();
    formdata.append("user_id", userName);
    formdata.append("cohort_name", corhotName.trim());
    formdata.append("filter_value", JSON.stringify(selectedFilters));
    formdata.append("dashboard_id", JSON.stringify(VisualsArr));
    formdata.append("columns", JSON.stringify(selectedColumns));
    formdata.append("description", description ?? "");
    formdata.append("isnew", "True");
    saveCohort(formdata)
      .then((result) => {
        if (result === "Cohort name already exists") {
          enqueueSnackbar("Cohort name already exists", { variant: "error" });
        } else {
          const cohortData = {
            Filters: JSON.stringify(selectedFilters),
            CohortName: corhotName,
            Dashboards: JSON.stringify(VisualsArr),
            Columns: JSON.stringify(selectedColumns),
          };

          dispatch(setCohortData(cohortData));
          if (ApplyState) {
            AppliedChanges();
          }
          setApplyState(false);
          setUndoableStates([]);
          dispatch(setFilterSaved(true));
          getPatientCount();
          setNameChanged(false);
          enqueueSnackbar("Cohort saved Successfully", { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.status, { variant: "error" });
      });
  };

  return (
    <div id="print" ref={printRef}>
      <PageContainer>
        {/* <FiltersModal
          cname={corhotName}
          open={openFilterModal}
          filterVal={ValToFilter}
          HeadFilterVal={ValToFilterMain}
          onClose={CloseModalState}
          ApplyFilter={ApplyFilter}
          initialVal={
            selectedFilters.hasOwnProperty(ValToFilter)
              ? selectedFilters[ValToFilter]
              : null
          }
          initialFilterCount={
            Object.keys(selectedFilters)?.length > 0 ? true : false
          }
          filterIndex={
            ValToFilter ? Object.keys(selectedFilters).indexOf(ValToFilter) : 0
          }
          StaticData={StaticData}
        /> */}

        <AlertDialogNLP
          className="no-print"
          open={openNLPModal}
          onClose={() => {
            setOpenNLPModal(false);
          }}
          filter={selectedFilters}
          GetNLPFilters={GetNLPFilters}
        />

        <AppDrawer
          className="no-print"
          open={open}
          toggleDrawer={toggleDrawer}
          clearModify={() => {
            if (visualModifyState) {
              setVisualModifyState(false);
              setModifiableVisualIndex(null);
              setVisualModifyableData(null);
            }
          }}
        >
          <BuilderSideBar
            className="no-print"
            open={open}
            toggleDrawer={toggleDrawer}
            getVal={getFilterVal}
            Filters={selectedFilters}
            onClose={CloseModalState}
            ApplyFilter={ApplyFilter}
            vald2={ValToFilter}
            vald1={ValToFilterMain}
          />
        </AppDrawer>
        <AppDrawer
          open={openVisuals}
          toggleDrawer={toggleDrawerVisuals}
          clearModify={() => {
            if (visualModifyState) {
              setVisualModifyState(false);
              setModifiableVisualIndex(null);
              setVisualModifyableData(null);
            }
          }}
        >
          <BuilderVisualSideBar
            className="no-print"
            open={openVisuals}
            toggleDrawer={toggleDrawerVisuals}
            getVal={getVisualsVal}
            cohortType={cohortType}
            selectedFilters={
              cohortType === "" ? selectedFilters : ComparableFilters
            }
            combineLogic={cohortType === "combine" ? combineLogic : null}
            Modify={visualModifyState}
            ModifyIndex={ModifiableVisualIndex}
            ModifyData={VisualModifiableData}
            clearModify={() => {
              if (visualModifyState) {
                setVisualModifyState(false);
                setModifiableVisualIndex(null);
                setVisualModifyableData(null);
              }
            }}
          />
        </AppDrawer>
        <Stack
          direction={"row"}
          sx={{ width: "100%", mt: "0vh", height: "auto" }}
          className="no-print"
        >
          <Stack
            sx={{
              width:
                orientation === 0 ? "49%" : orientation === 1 ? "69%" : "30%",
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "text.primary",
                display: "flex",
                minHeight: "auto",
                maxHeight: "auto",
                alignItems: "center",
              }}
            >
              Query Filters
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: "1vh",
                flexGrow: 1,
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0.12)",
              }}
            >
              <Card
                sx={{
                  p: 2,
                  width: "98%",
                  maxHeight: "auto",
                  minHeight: "60vh",
                  borderRight: "0",
                  borderBottomRightRadius: "0",
                  boxShadow: "0",
                  overflow: "auto",
                }}
              >
                {cohortType === "" && !isExportable && (
                  <Stack
                    sx={{ ml: "1vw", mb: "2vw" }}
                    direction={"row"}
                    gap="1vw"
                  >
                    <DisplayButtons
                      openOrientation={1}
                      position="start"
                      orientation={orientation}
                      onClickBtn={() => {
                        toggleDrawer(true);
                      }}
                      BtnTxt="Manual Filter"
                      disable={cohortType === "compare"}
                      toolTipText="Manual Filter"
                      logo={<IoIosAddCircle />}
                    />

                    <DisplayButtons
                      openOrientation={1}
                      // position="end"
                      orientation={orientation}
                      onClickBtn={() => {
                        setOpenNLPModal(true);
                      }}
                      BtnTxt="NLP AI Filter"
                      disable={cohortType === "compare"}
                      toolTipText="NLP AI Filter"
                      logo={<MdAutoAwesome />}
                    />

                    <DisplayButtons
                      openOrientation={1}
                      orientation={orientation}
                      onClickBtn={() => {
                        setSelectedFilters({});
                        setCorhotName("");
                        setDescription("");
                        setVisualsArr([]);
                        setEmbedUrl([]);
                        setUndoableStates([]);
                        dispatch(resetCohortData());
                        dispatch(
                          setPatientCount({
                            patientCount: 0,
                          })
                        );
                        dispatch(setFilterSaved(undefined));
                      }}
                      BtnTxt="Clear all filters"
                      disable={
                        Object.keys(selectedFilters)?.length === 0 ||
                        cohortType === "compare"
                      }
                      toolTipText="Clear all filters"
                      logo={<MdOutlineDeleteForever />}
                    />

                    <DisplayButtons
                      openOrientation={1}
                      orientation={orientation}
                      onClickBtn={() => {
                        AppliedChanges();
                      }}
                      BtnTxt={ApplyingChanges ? "Applying" : "Apply"}
                      disable={
                        Object.keys(selectedFilters)?.length === 0 ||
                        !ApplyState ||
                        cohortType === "compare" ||
                        ApplyingChanges
                      }
                      toolTipText={ApplyingChanges ? "Applying" : "Apply"}
                      logo={<IoCheckmarkDoneOutline />}
                    />

                    <DisplayButtons
                      openOrientation={1}
                      position="end"
                      orientation={orientation}
                      onClickBtn={() => {
                        setSelectedFilters(undoableStates?.slice(-1)[0]);
                        setUndoableStates((prevArray) =>
                          prevArray.slice(0, -1)
                        );
                      }}
                      BtnTxt="Undo"
                      disable={
                        Object.keys(selectedFilters)?.length === 0 ||
                        undoableStates?.length < 1
                      }
                      toolTipText="Undo"
                      logo={<IoMdUndo />}
                    />
                  </Stack>
                )}
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  {Object.keys(selectedFilters)?.length === 0 &&
                    ComparableFilters?.length === 0 && (
                      <Typography
                        variant="primary"
                        sx={{
                          fontSize: "1.2vw",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {corhotName ? corhotName : "Untitled Cohort"}
                          <CiEdit
                            onClick={() => {
                              setOpenSaveModal(true);
                            }}
                            style={{ cursor: "pointer", marginLeft: "0.5vw" }}
                          />
                        </Typography>
                        <PatientCount
                          viewPatientList={() => {
                            if (viewPatientList === false) {
                              viewPatientListItemSwitch(true);
                            }
                          }}
                          selectedFilters={selectedFilters}
                          isLoading={isLoading}
                        />
                        <Divider sx={{ my: 2 }} />
                      </Typography>
                    )}
                  {cohortType === ""
                    ? Object.keys(selectedFilters)?.length > 0 && (
                        <SingleCohortFilters
                          selectedFilters={selectedFilters}
                          corhotName={corhotName}
                          filterSaved={filterSaved}
                          patientCount={patientCount}
                          modifyStateVar={modifyStateVariable}
                          modifyStateVariableSingleValue={
                            modifyStateVariableSingleValue
                          }
                          viewPatientList={() => {
                            viewPatientListItemSwitch(true);
                          }}
                          isLoading={isLoading}
                          modifyFilters={getFilterVal}
                          description={description}
                          setCorhotName={setCorhotName}
                          setDescription={setDescription}
                          setNameChanged={setNameChanged}
                        />
                      )
                    : ComparableFilters?.length > 0 && (
                        <CohortCompare
                          ComparableFilters={ComparableFilters}
                          patientCount={patientCount}
                          viewPatientList={() => {
                            viewPatientListItemSwitch(true);
                          }}
                        />
                      )}
                </Box>
              </Card>
              {!isExportable && (
                <HTMLToCanvasIgnore>
                  <Box
                    sx={{
                      height: "100%",
                      width: "auto",
                      backgroundColor: "white",
                      flexGrow: 1,
                      borderLeft: "0",
                    }}
                  >
                    <Button
                      data-testid="query-resize"
                      onClick={() => {
                        queryScreenWidthHandler();
                      }}
                      variant="contained"
                      sx={{
                        maxHeight: "4vh",
                        minHeight: "4vh",
                        fontSize: "1vw",
                        minWidth: "auto",
                        py: "2vh",
                        px: "1vh",
                        borderRadius: "0",
                      }}
                    >
                      {orientation === 0 || orientation === 2 ? (
                        <IoIosArrowForward />
                      ) : (
                        <IoIosArrowBack />
                      )}
                    </Button>
                  </Box>
                </HTMLToCanvasIgnore>
              )}
            </Box>
          </Stack>

          <Stack
            sx={{
              width:
                orientation === 0 ? "50%" : orientation === 2 ? "69%" : "30%",
              ml: "auto",
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                ml: "0vw",
                display: "flex",
                alignItems: "center",

                maxHeight: "auto",
                minHeight: "auto",
              }}
            >
              Visuals
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: "1vh",
                flexGrow: 1,
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              {!isExportable && (
                <HTMLToCanvasIgnore>
                  <Box
                    sx={{
                      height: "100%",
                      flexGrow: 1,
                      width: "auto",
                      backgroundColor: "white",

                      borderRight: "0",
                    }}
                  >
                    <Button
                      data-testid="visual-resize"
                      onClick={() => {
                        VisualScreenWidthHandler();
                      }}
                      variant="contained"
                      sx={{
                        maxHeight: "4vh",
                        minHeight: "4vh",
                        fontSize: "1vw",
                        minWidth: "auto",
                        py: "2vh",
                        px: "1vh",
                        borderRadius: "0",
                      }}
                    >
                      {orientation === 0 || orientation === 1 ? (
                        <IoIosArrowBack />
                      ) : (
                        <IoIosArrowForward />
                      )}
                    </Button>
                  </Box>
                </HTMLToCanvasIgnore>
              )}
              <Card
                sx={{
                  width: "98%",
                  p: 2,
                  borderLeft: "0",
                  borderBottomLeftRadius: "0",
                  minHeight: "60vh",
                  maxHeight: "100%",
                  boxShadow: "none",
                }}
              >
                <HTMLToCanvasIgnore>
                  {!isExportable && (
                    <Stack
                      direction={"row"}
                      gap={"1vw"}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mb: 2,
                      }}
                    >
                      <DisplayButtons
                        variant="outlined"
                        openOrientation={2}
                        position="start"
                        orientation={orientation}
                        onClickBtn={() => {
                          toggleDrawerVisuals(true);
                        }}
                        BtnTxt="Add a Visual"
                        disable={
                          (Object.keys(selectedFilters)?.length === 0 &&
                            ComparableFilters?.length === 0) ||
                          embedUrl?.length > 3 ||
                          gettingVisuals
                        }
                        toolTipText="Add Visual"
                        logo={<MdAddchart />}
                      />

                      <DisplayButtons
                        openOrientation={2}
                        position="end"
                        orientation={orientation}
                        onClickBtn={() => {
                          setVisualsArr([]);
                          setEmbedUrl([]);
                          dispatch(setFilterSaved(false));
                          setApplyState(false);
                        }}
                        BtnTxt="Clear all visual"
                        disable={
                          Object.keys(selectedFilters)?.length === 0 ||
                          embedUrl?.length === 0 ||
                          gettingVisuals
                        }
                        toolTipText="Clear all visuals"
                        logo={<MdOutlineDeleteSweep />}
                      />
                    </Stack>
                  )}
                </HTMLToCanvasIgnore>
                {embedUrl?.length > 0 && (
                  <VisualSort
                    embedUrl={embedUrl}
                    visualsArr={VisualsArr}
                    setVisualsArr={(a) => {
                      setVisualsArr(a);
                    }}
                    isColumn={orientation === 1}
                    setEmbedUrl={(a) => setEmbedUrl(a)}
                    onExpand={(index) => {
                      setFullScreenUrl(embedUrl[index]);
                      setExpandedLookModal(true);
                    }}
                    onEdit={(index) => {
                      setVisualModifyState(true);
                      setModifiableVisualIndex(index);
                      setVisualModifyableData(VisualsArr[index]);
                      toggleDrawerVisuals(true);
                    }}
                    onRemove={(index) => {
                      dispatch(setFilterSaved(false));
                      setEmbedUrl((prev) => {
                        let p = [...prev];
                        let s = p.filter((a, ind) => ind !== index);
                        return s;
                      });

                      setVisualsArr((prev) => {
                        let p = [...prev];
                        let s = p.filter((a, ind) => ind !== index);
                        return s;
                      });
                    }}
                  />
                )}
              </Card>
            </Box>
          </Stack>
        </Stack>
        <HTMLToCanvasIgnore>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: "2vh",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              mb: "2vh",
            }}
            className="no-print"
          >
            {(cohortType === "" || cohortType === "combine") && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  mr: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.367vw",
                    fontWeight: "bold",
                    mr: "0.5vw",
                    mt: "0.5vh",
                  }}
                >
                  Patient List
                </Typography>
                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                />
              </Box>
            )}

            <Button
              onClick={(e) => {
                if (cohortType === "") {
                  const Coh = {
                    Filters: JSON.parse(cohortData.Filters),
                    CohortName: cohortData.CohortName,
                    Dashboards: JSON.parse(cohortData.Dashboards),
                    Columns: JSON.parse(cohortData?.Columns || "{}"),
                  };
                  dispatch(setCohortData(Coh));
                  dispatch(setExportable(true));
                  setShowExportModal(true);
                } else {
                  setShowExportModal(true);
                }
              }}
              disabled={
                (cohortData.CohortName === "Untitled Cohort" ||
                  !cohortData.CohortName ||
                  !filterSaved) &&
                cohortType === ""
              }
              variant="contained"
              sx={{
                mr: "1vw",
                fontSize: "0.833vw",
                borderRadius: "0",
                py: "0.5vh",
              }}
            >
              Download Cohort
            </Button>
            {cohortType !== "compare" && cohortType !== "combine" && (
              <Button
                disabled={
                  Object.keys(selectedFilters)?.length === 0 || gettingVisuals
                }
                onClick={(e) => {
                  if (
                    filterSaved ||
                    Object.keys(selectedFilters)?.length === 0
                  ) {
                    setSelectedFilters({});
                    setCorhotName("");
                    setDescription("");
                    setVisualsArr([]);
                    setEmbedUrl([]);
                    dispatch(resetCohortData());
                    dispatch(
                      setPatientCount({
                        patientCount: 0,
                      })
                    );
                    dispatch(setFilterSaved(undefined));
                    setUndoableStates([]);
                  } else {
                    setShowUnsaved(true);
                  }
                }}
                variant="contained"
                sx={{
                  mr: "1vw",
                  fontSize: "0.833vw",
                  borderRadius: "0",

                  py: "0.5vh",
                }}
              >
                New Cohort
              </Button>
            )}
            {cohortType !== "compare" && cohortType !== "combine" && (
              <Button
                onClick={(e) => {
                  if (Object.keys(selectedFilters)?.length === 0) {
                    enqueueSnackbar("No Filters are provided in cohort", {
                      variant: "error",
                    });
                    return;
                  } else if (!corhotName) {
                    enqueueSnackbar(
                      "Please provide cohort name by clicking of edit icon provided with title",
                      { variant: "warning" }
                    );
                    return;
                  } else {
                    if (NameChanged) {
                      handleCloseApplySaveNew();
                    } else {
                      handleCloseApplySaveold();
                    }
                  }
                }}
                variant="contained"
                sx={{
                  mr: "1vw",
                  fontSize: "0.833vw",
                  borderRadius: "0",
                  py: "0.5vh",
                }}
              >
                Save Cohort
              </Button>
            )}
          </Box>
        </HTMLToCanvasIgnore>

        {(isExportable || viewPatientList) && (
          <PatientList
            className="no-print"
            selectedFilters={selectedFilters}
            columns={selectedColumns}
            onPatientListDismiss={() => {
              setViewPatientList(false);
            }}
            onApplyColumns={(columns) => {
              setSelectedColumns(columns);
              //  if (Object.keys(selectedFilters)?.length > 0) {
              // dispatch(setFilterSaved(false));
              //  }
            }}
          />
        )}
        {expandedLookModal && (
          <ExpandedLooks
            className="no-print"
            open={expandedLookModal}
            url={fullScreenUrl}
            onClose={() => {
              setExpandedLookModal(false);
              setFullScreenUrl("");
            }}
          />
        )}
        {OpenSaveModal && (
          <SaveMDNew
            className="no-print"
            open={OpenSaveModal}
            name={corhotName}
            description={description}
            filters={selectedFilters}
            dashboards={VisualsArr}
            columns={selectedColumns}
            isNew={false}
            onClose={() => {
              setOpenSaveModal(false);
            }}
            onOk={(nm, des) => {
              setCorhotName(nm);
              setDescription(des);
              if (nm !== corhotName) {
                setNameChanged(true);
              }
            }}
          />
        )}
        {showExportModal && (
          <ExportModal
            onClose={handleExportClose}
            embedUrl={embedUrl}
            VisualsArr={VisualsArr}
          />
        )}
        {showUnsavedDialog && (
          <AlertDialog
            className="no-print"
            title={"Unsaved Changes"}
            buttonName={"Discard"}
            description={
              "You have unsaved changes. Changes will be discarded if you proceed. Do you want to Discard?"
            }
            onButtonClick={() => {
              setSelectedFilters({});
              setCorhotName("");
              setDescription("");
              setVisualsArr([]);
              setEmbedUrl([]);
              dispatch(resetCohortData());
              dispatch(
                setPatientCount({
                  patientCount: 0,
                })
              );
              dispatch(setFilterSaved(undefined));
              setShowUnsaved(false);
            }}
            onClose={() => {
              setShowUnsaved(false);
            }}
          />
        )}
      </PageContainer>
    </div>
  );
}

export default Home;
