import { EndpointBuilder } from "@reduxjs/toolkit/query";

const getGroupListNames = (build: EndpointBuilder<any, any, any>) =>
  build.query<string[], void>({
    query: () => ({
      url: "/get_groups_list",
      method: "GET",
    }),
  });

export default getGroupListNames;
