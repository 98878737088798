import {
  Box,
  Button,
  Chip,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  useGetCombinedCohortCountMutation,
  useGetPatientCountMutation,
} from "../../../services/modules/queryBuilder";
import { useSelector } from "../../../store/configureStore";
import HTMLToCanvasIgnore from "../../components/Common/HtmlToCanvasIgnore";
import CohortCompareDialog from "../../components/Dialogs/CohortCompareDialog";
import PatientCount from "./PatientCount";

const StyledChip = (props) => {
  return (
    <Chip
      size="small"
      sx={{
        minHeight: "2vh",
        maxHeight: "4vh",
        padding: "0",
        ml: "1vh",
        borderRadius: "0.6vh",
        mb: "0.5vh",
      }}
      label={
        <Typography variant="body2" sx={{ heigh: "100%", mr: "0.8vw" }}>
          {props?.text}
        </Typography>
      }
      variant="outlined"
    />
  );
};

const DateTags = {
  "Greater Than": "After",
  "Less Than": "Before",
  "Equal To": "Is On",
  Between: "Is Between",
  "Not Between": "Is not Between",
  "Not Like": "Not On",
  "Not Equal To": "Not On",
};

const DataTagMod = (v, t) => {
  if (t === "Date Range") {
    return DateTags[v];
  }
  return v;
};

const CohortCompare = (props) => {
  const DateFormatter = (d) => {
    let a = d.split("-");
    return `${a[1]}-${a[0]}-${a[2]}`;
  };
  const [getCount, { data, isLoading }] = useGetPatientCountMutation();
  const dispatch = useDispatch();
  const [showCompareDialogOpen, setShowCompareDialogOpen] = useState(false);
  const [cohort1Count, setcohortCount1] = useState(0);
  const [cohort2Count, setcohortCount2] = useState(0);
  const [combinedCount, setCombinedCount] = useState({});
  const [loadingCombinedCount, setLoadingCombinedCount] = useState(false);
  const { cohortType, combineLogic } = useSelector(
    (state) => state.CohortReducer
  );
  const [getCombinedCount] = useGetCombinedCohortCountMutation();

  const getPatientCount = (selectedFilters, cohn) => {
    getCount({ query: selectedFilters })
      .then((res) => {
        if (cohn === 0) {
          setcohortCount1(res?.data?.filter_count);
        } else {
          setcohortCount2(res?.data?.filter_count);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      });
  };
  useEffect(() => {
    getPatientCount(props?.ComparableFilters[1]["Filters"], 1);
    getPatientCount(props?.ComparableFilters[0]["Filters"], 0);
  }, [props.ComparableFilters]);

  useEffect(() => {
    if (cohortType === "combine") {
      setLoadingCombinedCount(true);
      const formDt = new FormData();
      formDt.append("filter1", props?.ComparableFilters[0]["Filters"]);
      formDt.append("filter2", props?.ComparableFilters[1]["Filters"]);
      formDt.append("operation", combineLogic);

      getCombinedCount(formDt).then((response) => {
          if (!response.data) {
           
            throw new Error("Error:");
          }
          return response;
        })
        .then((data) => {
         
          setCombinedCount(data.data);

          setLoadingCombinedCount(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.ComparableFilters, cohortType, combineLogic]);

  return (
    <Stack
      sx={{
        maxHeight: "auto",
        minHeight: "auto",
        overflowX: "hidden",
        overflowY: "auto",
      }}
      className="print"
    >
      {loadingCombinedCount && cohortType === "combine" && (
        <Skeleton height={"40px"} width={"10vw"} />
      )}
      {!loadingCombinedCount &&
        cohortType === "combine" &&
        Object.keys(combinedCount).length > 0 && (
          <Typography variant="h3">
            <b
              onClick={() => {
                props?.viewPatientList();
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#3F7D98",
              }}
            >
              {combinedCount.filter_count}
            </b>{" "}
            Patients of {combinedCount.total_count}
          </Typography>
        )}
      .
      {props?.ComparableFilters.map((singleCohort, index) => {
        const IndvidualFilter = JSON.parse(singleCohort.Filters);

        return (
          <Stack>
            {index === 1 && (
              <Divider sx={{ mb: "3vh", backgroundColor: "transparent" }}>
                <Box
                  sx={{
                    borderRadius: "0",
                    backgroundColor: "common.brandBackground",
                    color: "black",
                    py: 0.5,
                    px: 1,
                  }}
                >
                  <Typography variant="body2">
                    {cohortType === "compare" && combineLogic === ""
                      ? "Compare to"
                      : combineLogic}
                  </Typography>
                </Box>
              </Divider>
            )}
            {Object.keys(IndvidualFilter)?.length > 0 && (
              <>
                {" "}
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#333333",
                      fontSize: "1.3vw",
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {singleCohort["CohortName"]}
                  </Typography>
                  {index === 0 && (
                    <HTMLToCanvasIgnore>
                      <Button
                        className="no-print"
                        sx={{ mr: "0.5vw" }}
                        variant="outlined"
                        size="small"
                        onClick={() => setShowCompareDialogOpen(true)}
                      >
                        Select
                      </Button>
                    </HTMLToCanvasIgnore>
                  )}
                </Stack>
                <PatientCount
                  viewPatientList={{}}
                  selectedFilters={singleCohort.Filters}
                  isLoading={isLoading}
                  isCompare={true}
                  patientCountProps={index === 0 ? cohort1Count : cohort2Count}
                />
                <Divider sx={{ my: 2 }} />
                <Typography
                  sx={{ display: "block", width: "100%" }}
                  variant="h4"
                >
                  Select Patient
                </Typography>
              </>
            )}
            <Stack
              direction="column"
              spacing={1}
              sx={{
                maxHeight: "auto",
                minHeight: "auto",
                overflowX: "hidden",
                display: "flex",
                pb: "1vw",
              }}
            >
              {Object.keys(IndvidualFilter)?.length > 0 &&
                Object.keys(IndvidualFilter)?.map((f, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                    >
                      {index === 0 ? (
                        <Typography
                          sx={{ mt: "1vh", fontSize: "0.833vw" }}
                          variant="body1"
                        >
                          With
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            mt: "1vh",
                            p: "0.5vw",
                            backgroundColor: "#3F7D98",
                            borderRadius: "3px",
                            color: "white",
                            maxWidth: "fit-Content",
                          }}
                          variant="body2"
                        >
                          {IndvidualFilter[f]["JoinBy"]}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          ml: "0.5vw",
                          mt: "1vh",
                          fontSize: "0.833vw",
                        }}
                        variant="body1"
                      >
                        <strong>{f}</strong>{" "}
                        {`${DataTagMod(
                          IndvidualFilter[f]["condition"],
                          IndvidualFilter[f]["type"]
                        )}`}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          mt: "0.5vh",
                          flexWrap: "wrap",
                          alignContent: "space-between",
                        }}
                      >
                        {IndvidualFilter[f]["type"] === "Multi Select" &&
                          IndvidualFilter[f]["condition"] === "Is Blank" && (
                            <StyledChip text="Is Blank" />
                          )}

                        {IndvidualFilter[f]["type"] === "Multi Select" &&
                          IndvidualFilter[f]["condition"] !== "Is Blank" &&
                          IndvidualFilter[f]["val"]?.map((v) => {
                            return <StyledChip text={v} />;
                          })}

                        {IndvidualFilter[f]["type"] !== "Multi Select" &&
                          IndvidualFilter[f]["condition"] !== "Between" &&
                          IndvidualFilter[f]["condition"] !== "Not Between" && (
                            <StyledChip
                              text={
                                IndvidualFilter[f]["type"] === "Date Range"
                                  ? DateFormatter(IndvidualFilter[f]["val"])
                                  : IndvidualFilter[f]["val"]
                              }
                            />
                          )}

                        {(IndvidualFilter[f]["type"] === "Number Range" ||
                          IndvidualFilter[f]["type"] === "Date Range") &&
                          (IndvidualFilter[f]["condition"] === "Between" ||
                            IndvidualFilter[f]["condition"] ===
                              "Not Between") && (
                            <StyledChip
                              text={
                                IndvidualFilter[f]["type"] === "Date Range"
                                  ? `${DateFormatter(
                                      IndvidualFilter[f]["val"][0]
                                    )} And
                            ${DateFormatter(IndvidualFilter[f]["val"][1])}`
                                  : `${IndvidualFilter[f]["val"][0]} And
                       ${IndvidualFilter[f]["val"][1]}`
                              }
                            />
                          )}
                      </Box>
                    </Box>
                  );
                })}
            </Stack>
          </Stack>
        );
      })}
      {showCompareDialogOpen && (
        <CohortCompareDialog
          isCombine={cohortType === "combine" ? true : false}
          onClose={() => setShowCompareDialogOpen(false)}
          setTabVal={() => {}}
          Temp={1}
        />
      )}
    </Stack>
  );
};

export default CohortCompare;
