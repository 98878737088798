import { styled } from "@mui/material";
import "./LoadingIndicator.css";
import { pxToRem } from "../../utils/utilsFuncs";

const SIZE = 33;

const CustomLoadingIndicator = styled("div")<{
  size: number;
}>(({ size }) => ({
  position: "relative",
  width: 1.8 * size,
  height: pxToRem(size),
}));

const Circle1 = styled("div")<{
  size: number;
}>(({ theme, size }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: theme.palette.primary.main,
  width: pxToRem(size),
  height: pxToRem(size),
  borderRadius: "50%",
  border: `2px solid ${theme.palette.primary.main}`,
}));

const Circle2 = styled("div")<{
  size: number;
}>(({ theme, size }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  backgroundColor: theme.palette.primary.main,
  width: pxToRem(size),
  height: size,
  borderRadius: "50%",
  border: `2px solid ${theme.palette.primary.main}`,
}));

function LoadingIndicator({ size = SIZE }: { size?: number }) {
  return (
    <CustomLoadingIndicator className="loader" size={size}>
      <Circle1 color="primary" className="circle1" size={size} />
      <Circle2 color="primary" className="circle2" size={size} />
    </CustomLoadingIndicator>
  );
}

export default LoadingIndicator;
