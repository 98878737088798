const env = window && window.__env;

const CLIENT_ID = env?.REACT_APP_AUTH0_CLIENT_ID || "{clientId}";
const ISSUER =
  env?.REACT_APP_AUTH0_DOMAIN || "https://{yourOktaDomain}.com/oauth2/default";

// "Navista™ Practice Explorer": "/practice-explorer",
//     "Population Profiling": "/population-profiling",
//     "Diagnosis and Disease Staging": "/diagnosis-and-disease-staging",
//     "Therapy Pathways": "/therapy-pathways",
const processRedirectUri = () => {
  const pathName = window.location.pathname;
  if (
    !(
      pathName.includes("practice-explorer") ||
      pathName.includes("population-profiling") ||
      pathName.includes("diagnosis-and-disease-staging") ||
      pathName.includes("therapy-pathways")
    )
  ) {
    return `${window.location.origin}/practice-explorer`;
  }
  return `${window.location.origin}${pathName}`;
};

export const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: processRedirectUri(),
    pkce: true,
    disableHttpsCheck: true,
  },
  api: {
    apiBaseUrl: env?.REACT_APP_API_BASE_URL ?? "",
    lookerBaseUrl: env?.REACT_APP_API_BASE_URL ?? "",
  },
  apigee: {
    apiGeeEndPoint: env?.REACT_APIGEE_END_POINT ?? "",
    apiGeeUserName: env?.REACT_APP_APIGEE_USERNAME ?? "",
    apiGeePassword: env?.REACT_APP_APIGEE_PASSWORD ?? "",
    apiAuthUrl: env?.REACT_APP_APIGEE_AUTH_URL,
    apiAuthUsername: env?.REACT_APP_APIGEE_AUTH_USERNAME,
    apiAuthPassword: env?.REACT_APP_APIGEE_AUTH_PASSWORD,
  },
  test: {
    //--test credentials for running local unit test cases
    testUser: "test@example.com",
    testP: "test",
  },
};

export default config;
