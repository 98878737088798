import { TypeSpecimen } from "@mui/icons-material";

const buildExpression = (key, f,props) => {
    let i = `${key}=`;
    let temp = "";
    let type=JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f]["type"]
    let con= JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f][
      "condition"
    ]

    if (
      type ===
      "Multi Select"
    ) {
      if (
        con === "Excludes"
      ) {
        temp = `${JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])
          [f]["val"].map((str) => `-${str}`.replace(/ /g, "+"))
          .join(",")}&`;
        i = i + temp;
        return i;
      } else if (
        con === "Includes"
      ) {
        temp = `${JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])
          [f]["val"].map((str) => str.replace(/ /g, "+"))
          .join(",")}&`;
        i = i + temp;
        return i;
      }
    }
    if (
      type ===
      "Number Range"
    ) {
      if (
        con === "Greater Than"
      ) {
        temp = `>${
          JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f]["val"]
        }&`;
        i = i + temp;
        return i;
      } else if (
        con === "Less Than"
      ) {
        temp = `<${
          JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f]["val"]
        }&`;
        i = i + temp;
        return i;
      } else if (
        con === "Equal To"
      ) {
        temp = `${
          JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f]["val"]
        }&`;
        i = i + temp;
        return i;
      }
    }
    if (
      type ===
      "Text Box"
    ) {

      
      if (
        con === "Like" ||  con === "Not Like"
      ) {
        temp = `${ con === "Not Like"?"-":""}%${
          JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f]["val"]
        }%&`;
        i = i + temp;
        return i;
      } else if (
        con === "Equal To" ||  con === "Not Equal To"
      ) {
        temp = `${ con === "Not Equal To"?"-":""}${
          JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])[f]["val"]
        }&`;
        i = i + temp;
        return i;
      }
    



    }

    //.......................................................................................................//
    if (
     type ===
      "Date Range"
    ) {
      if (
        con === "Greater Than"
      ) {
        temp = `after+${JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])
          [f]["val"].split("-")
          .reverse()
          .join("%2F")}&`;
        i = i + temp;
        return i;
      } else if (
        con === "Less Than"
      ) {
        temp = `before+${JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])
          [f]["val"].split("-")
          .reverse()
          .join("%2F")}&`;
        i = i + temp;
        return i;
      } else if (
        con === "Equal To" ||  con === "Not Equal To"
      ) {
        temp = `is+${JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])
          [f]["val"].split("-")
          .reverse()
          .join("%2F")}&`;
        i = i + temp;
        return i;
      } else if (
        con === "Between" ||  con === "Not Between"
      ) {
        temp = `${JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])
          [f]["val"][0].split("-")
          .reverse()
          .join("%2F")}+to+${JSON.parse(
          props?.["cohortAll"][props?.cnm]["Filter"]
        )
          [f]["val"][1].split("-")
          .reverse()
          .join("%2F")}&`;
        i = i + temp;
        return i;
      }}
  };

  const ModifiedFilters = (props,a) => {
    let fp = a;

    Object.keys(JSON.parse(props?.["cohortAll"][props?.cnm]["Filter"])).forEach(
      (f) => {
        let key = f.replaceAll(" ","+");
        if (fp.includes(`${key}=&`)) {
          let ifp = fp.replace(`${key}=&`, buildExpression(key, f,props));
          fp = ifp;
        } else {
          let key = f.replace(" ","+");
          let ifp = `${fp}&${buildExpression(key, f,props)}`;
          fp = ifp;
        }
      }
    );

    return fp.replaceAll("&&","&").slice(0, -1).slice(1).replaceAll(" ","+").replaceAll(" ","+");
  };
  export default ModifiedFilters;