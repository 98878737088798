import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type IUserDetails = {
  sub: string;
  name: string;
  locale: string;
  email: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  zoneinfo: string;
  updated_at: number;
  email_verified: boolean;
  headers: {
    "cache-control": string;
    "content-type": string;
    date: string;
    expires: string;
    pragma: string;
    "x-okta-request-id": string;
    "x-rate-limit-limit": string;
    "x-rate-limit-remaining": string;
    "x-rate-limit-reset": string;
  };
};
// TODO: This code was to give username
const initialState: {
  userName: string;
  token: string;
  apToken: string;
  isAuthenticated: boolean;
  userInfo: IUserDetails | null;
} = {
  userName: "",
  token: "",
  isAuthenticated: true,
  userInfo: null,
  apToken: "",
};
export const loginDetailsSlice = createSlice({
  name: "loginDetails",
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<IUserDetails>) => {
      state.userName = action.payload.email;
      state.isAuthenticated = true;
      state.userInfo = action.payload;
    },
    setApToken: (state, action: PayloadAction<string>) => {
      state.apToken = action.payload;
    },

    setUserAppToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setUserName, setApToken,setUserAppToken} = loginDetailsSlice.actions;
export default loginDetailsSlice.reducer;
