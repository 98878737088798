import { LOOKER_BASE_URL } from "../../constants";




export const dateFormatter = (dt) => {
  let a = dt;
  let b = a.split("-");
  b = b.reverse();
  let res = `${b[0]},${b[1]},${b[2]}`;
  return res;
};

export const processStringDate = (str) => {
  var modifiedString = str;
  // Check if the string ends with "date" (case-insensitive)
  if (modifiedString.toLowerCase().endsWith("_date")) {
    // Find the last index of "date" in the string
    var lastIndex = modifiedString.toLowerCase().lastIndexOf("date");
    if (lastIndex !== -1) {
      // Replace the last occurrence of "date" with "date"
      modifiedString = modifiedString.slice(0, lastIndex) + "date";
    }
  } else {
    // Append "_date" at the end of the string
    modifiedString = modifiedString + "_date";
  }
  return modifiedString;
};

export const filterstosend = (args, StaticData, filterValueD2, GraphKPI) => {
  let a = args;
  let b = Object.keys(a);
  let result = "";
  b?.forEach((val, ind) => {
    let internalRes = "";
    if (args[val]["type"] === "Multi Select") {
      internalRes = `${
        ind > 0 ? (args[val]["JoinBy"] === "And With" ? "#AND#" : "#OR#") : ""
      } matches_filter($@{${args[val]["ActualTable"]}.${
        args[val]["ActualColumn"]
      }},'${
        args[val]["condition"] === "Is Blank"
          ? `EMPTY`
          : args[val]["val"].map((v) => {
              if (args[val]["condition"] === "Excludes") {
                return `-${v}`;
              } else {
                return `${v}`;
              }
            })
      }')`;
      let dynmFltr = `#AND#matches_filter($@{${args[val]["ActualTable"]}.${args[val]["ActualColumn"]}},'-NULL')`;
      result = result + internalRes + dynmFltr;
    } else if (args[val]["type"] === "Text Box") {
      internalRes = `${
        ind > 0 ? (args[val]["JoinBy"] === "And With" ? "#AND#" : "#OR#") : ""
      }matches_filter($@{${args[val]["ActualTable"]}.${
        args[val]["ActualColumn"]
      }},'${
        args[val]["condition"] === "Equal To"
          ? args[val]["val"]
          : args[val]["condition"] === "Not Equal To"
            ? `-${args[val]["val"]}`
            : args[val]["condition"] === "Like"
              ? `%${args[val]["val"]}%`
              : `-%${args[val]["val"]}%`
      }')`;
      let dynmFltr = `#AND#matches_filter($@{${args[val]["ActualTable"]}.${args[val]["ActualColumn"]}},'-NULL')`;
      result = result + internalRes + dynmFltr;
    } else if (args[val]["type"] === "Number Range") {
      if (typeof args[val]["val"] !== "object") {
        internalRes = `${
          ind > 0 ? (args[val]["JoinBy"] === "And With" ? "#AND#" : "#OR#") : ""
        }$@{${args[val]["ActualTable"]}.${args[val]["ActualColumn"]}} ${
          args[val]["condition"] === "Equal To"
            ? "="
            : args[val]["condition"] === "Less Than"
              ? "<"
              : ">"
        }${args[val]["val"]}`;
      } else {
        internalRes = `${
          ind > 0 ? (args[val]["JoinBy"] === "And With" ? "#AND#" : "#OR#") : ""
        }$@{${args[val]["ActualTable"]}.${args[val]["ActualColumn"]}} >=${
          args[val]["val"][0]
        }#AND#$@{${args[val]["ActualTable"]}.${
          args[val]["ActualColumn"]
        }} <=${args[val]["val"][1]}`;
      }
      internalRes = internalRes.replaceAll(" ", "");
      let dynmFltr = `#AND#matches_filter($@{${args[val]["ActualTable"]}.${args[val]["ActualColumn"]}},'NOT#NULL')`;
      result = result + internalRes.replaceAll(" ", "") + dynmFltr;
    } else if (args[val]["type"] === "Date Range") {
      if (typeof args[val]["val"] !== "object") {
        internalRes = `${
          ind > 0 ? (args[val]["JoinBy"] === "And With" ? "#AND#" : "#OR#") : ""
        }$@{${args[val]["ActualTable"]}.${processStringDate(
          args[val]["ActualColumn"]
        )}} ${
          args[val]["condition"] === "Equal To"
            ? "="
            : args[val]["condition"] === "Less Than"
              ? "<"
              : args[val]["condition"] === "Not Equal To"
                ? "!="
                : ">"
        }date(${dateFormatter(args[val]["val"])})`;
      } else {
        internalRes = `${
          ind > 0 ? (args[val]["JoinBy"] === "And With" ? "#AND#" : "#OR#") : ""
        }$@{${args[val]["ActualTable"]}.${processStringDate(
          args[val]["ActualColumn"]
        )}}${
          args[val]["condition"] === "Between" ? ">=" : "<="
        }date(${dateFormatter(args[val]["val"][0])})#AND#$@{${
          args[val]["ActualTable"]
        }.${processStringDate(args[val]["ActualColumn"])}}${
          args[val]["condition"] === "Between" ? "<=" : ">="
        }date(${dateFormatter(args[val]["val"][1])})`;
      }
      internalRes = internalRes.replaceAll(" ", "");
      let dynmFltr = `#AND#matches_filter($@{${
        args[val]["ActualTable"]
      }.${processStringDate(args[val]["ActualColumn"])}},'NOT#NULL')`;
      result = result + internalRes.replaceAll(" ", "") + dynmFltr;
    }
  });

  let dynamicAxisFilters = fieldstosend(filterValueD2, GraphKPI, StaticData)
    .replaceAll("[", "")
    .replaceAll("]", "")
    .replaceAll("'", "")
    .split(",");

  dynamicAxisFilters.shift();
  
  let axisres = "";
  let df = dynamicAxisFilters.map((Ael, index) => {
    let a = "";
    a = `#AND#matches_filter($@{${Ael}},'${
      StaticData[filterValueD2[index].group][filterValueD2[index].value][
        "types"
      ] === "Date Range" ||
      StaticData[filterValueD2[index].group][filterValueD2[index].value][
        "types"
      ] === "Number Range"
        ? "NOT#NULL"
        : "-NULL"
    }')${
      StaticData[filterValueD2[index].group][filterValueD2[index].value][
        "types"
      ] === "Number Range"
        ? `#AND#$@{${Ael}}>0`
        : ""
    }`;

    return axisres + a;
  });
  let res2 = result + df.toString().replaceAll(" ", "").replaceAll(",#", "#");
  return `"${res2
    .replaceAll("@", "")
    .replaceAll("#", ` `)
    .replaceAll("'", "`")}"`;
};

const DerivedTableConversion=(ColDisplayName,ColActualName,TableName,selectedFilters)=>{


  let DerTables={"Primary Tumor Site" : "top_25_primary_tumor_site",
    "Referring Provider Name" : "top_25_referring_provider_name",
    "Payer Name": "top_25_payer_name"}
  if(!DerTables.hasOwnProperty(ColDisplayName)){
    return TableName
  }
  else{
    if(!selectedFilters.hasOwnProperty(ColDisplayName)){
      return DerTables[ColDisplayName]
    }
    else if(selectedFilters.hasOwnProperty(ColDisplayName) && selectedFilters[ColDisplayName]["condition"]==="Includes" && selectedFilters[ColDisplayName]["val"].length>24 ){
      return DerTables[ColDisplayName]
    }

    else if(selectedFilters.hasOwnProperty(ColDisplayName) && selectedFilters[ColDisplayName]["condition"]==="Includes" && selectedFilters[ColDisplayName]["val"].length<=24 ){
      return TableName
    }

    else if(selectedFilters.hasOwnProperty(ColDisplayName) && selectedFilters[ColDisplayName]["condition"]==="Excludes" ){
      return DerTables[ColDisplayName]
    }
    else{
      return TableName
    }
  }
}

export const fieldstosend = (arg, GraphKPI, StaticData,selectedFilters) => {
  let result = "";
  if (arg.length === 1) {
    result = `['encounter.${
      GraphKPI !== "All Records" ? "patient_count" : "count"
    }','${!selectedFilters?StaticData[arg[0].group][arg[0].value]["ActualTable"]:DerivedTableConversion(arg[0].value,StaticData[arg[0].group][arg[0].value]["ActualColumn"],StaticData[arg[0].group][arg[0].value]["ActualTable"],selectedFilters)}.${
      StaticData[arg[0].group][arg[0].value]["types"] === "Date Range"
        ? processStringDate(
            StaticData[arg[0].group][arg[0].value]["ActualColumn"]
          )
        : StaticData[arg[0].group][arg[0].value]["ActualColumn"]
    }']`;
    return result;
  } else {
    result = `['encounter.${
      GraphKPI !== "All Records" ? "patient_count" : "count"
    }',  '${!selectedFilters?StaticData[arg[0].group][arg[0].value]["ActualTable"]:DerivedTableConversion(arg[0].value,StaticData[arg[0].group][arg[0].value]["ActualColumn"],StaticData[arg[0].group][arg[0].value]["ActualTable"],selectedFilters)}.${
      StaticData[arg[0].group][arg[0].value]["types"] === "Date Range"
        ? processStringDate(
            StaticData[arg[0].group][arg[0].value]["ActualColumn"]
          )
        : StaticData[arg[0].group][arg[0].value]["ActualColumn"]
    }',  '${!selectedFilters?StaticData[arg[1].group][arg[1].value]["ActualTable"]:DerivedTableConversion(arg[1].value,StaticData[arg[1].group][arg[1].value]["ActualColumn"],StaticData[arg[1].group][arg[1].value]["ActualTable"],selectedFilters)}.${
      StaticData[arg[1].group][arg[1].value]["types"] === "Date Range"
        ? processStringDate(
            StaticData[arg[1].group][arg[1].value]["ActualColumn"]
          )
        : StaticData[arg[1].group][arg[1].value]["ActualColumn"]
    }']`;
    return result.replaceAll(" ", "");
  }
};

export const fieldstosendComparable = (arg, GraphKPI, StaticData) => {
  let result = "";
  if (arg.length === 1) {
    result = `['${
      GraphKPI !== "All Records" ? "patient_count" : "count"
    }_cohort1','${
      GraphKPI !== "All Records" ? "patient_count" : "count"
    }_cohort2','${StaticData[arg[0].group][arg[0].value]["ActualTable"]}.${
      StaticData[arg[0].group][arg[0].value]["types"] === "Date Range"
        ? processStringDate(
            StaticData[arg[0].group][arg[0].value]["ActualColumn"]
          )
        : StaticData[arg[0].group][arg[0].value]["ActualColumn"]
    }']`;
    return result;
  } else {
    result = `['${
      GraphKPI !== "All Records" ? "patient_count" : "count"
    }_cohort1','${
      GraphKPI !== "All Records" ? "patient_count" : "count"
    }_cohort2',  '${StaticData[arg[0].group][arg[0].value]["ActualTable"]}.${
      StaticData[arg[0].group][arg[0].value]["types"] === "Date Range"
        ? processStringDate(
            StaticData[arg[0].group][arg[0].value]["ActualColumn"]
          )
        : StaticData[arg[0].group][arg[0].value]["ActualColumn"]
    }',  '${StaticData[arg[1].group][arg[1].value]["ActualTable"]}.${
      StaticData[arg[1].group][arg[1].value]["types"] === "Date Range"
        ? processStringDate(
            StaticData[arg[1].group][arg[1].value]["ActualColumn"]
          )
        : StaticData[arg[1].group][arg[1].value]["ActualColumn"]
    }']`;
    return result.replaceAll(" ", "");
  }
};

export const filtersToSendComparable = (
  selectedFilters,
  GraphKPI,
  StaticData,
  filterValueD2
) => {
  let res = [
    {
      category: "measure",
      expression: filterstosend(
        JSON.parse(selectedFilters[0]["Filters"]),
        StaticData,
        filterValueD2,
        GraphKPI
      ),
      label: selectedFilters[0]["CohortName"],
      based_on:
        GraphKPI !== "All Records" ? "encounter.patient_count" : "encounter.count",
      filter_expression: filterstosend(
        JSON.parse(selectedFilters[0]["Filters"]),
        StaticData,
        filterValueD2,
        GraphKPI
      ),
      _kind_hint: "measure",
      measure:
        GraphKPI !== "All Records" ? "patient_count_cohort1" : "count_cohort1",
      type: "count_distinct",
      _type_hint: "number",
      filters: {},
    },
    {
      category: "measure",
      expression: filterstosend(
        JSON.parse(selectedFilters[1]["Filters"]),
        StaticData,
        filterValueD2,
        GraphKPI
      ),
      label: selectedFilters[1]["CohortName"],
      based_on:
        GraphKPI !== "All Records" ? "encounter.patient_count" : "encounter.count",
      filter_expression: filterstosend(
        JSON.parse(selectedFilters[1]["Filters"]),
        StaticData,
        filterValueD2,
        GraphKPI
      ),
      _kind_hint: "measure",
      measure:
        GraphKPI !== "All Records" ? "patient_count_cohort2" : "count_cohort2",
      type: "count_distinct",
      _type_hint: "number",
      filters: {},
    },
  ];

  let result = `${JSON.stringify(res)}`;

  result = result.replaceAll(/\\"/g, '"');
  result = result.replaceAll('""', '"');
  result = result.replaceAll('"', '\\"');
  return `"${result}"`;
};

export const MakeCombineFilters = (
  combineType,
  selectedFilters,
  StaticData,
  filterValueD2,
  GraphKPI
) => {
  if (combineType === "Union") {
    let a = `(${filterstosend(
      JSON.parse(selectedFilters[0]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )}) OR (${filterstosend(JSON.parse(selectedFilters[1]["Filters"]), StaticData, filterValueD2, GraphKPI)})`;
    let B = a.replaceAll('"', "");
    let c = `"${B}"`;
    return c;
  }

  if (combineType === "Intersection") {
    let a = `(${filterstosend(
      JSON.parse(selectedFilters[0]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )}) AND (${filterstosend(JSON.parse(selectedFilters[1]["Filters"]), StaticData, filterValueD2, GraphKPI)})`;
    let B = a.replaceAll('"', "");
    let c = `"${B}"`;
    return c;
  }

  if (combineType === "Subtraction") {
    let a = `(${filterstosend(
      JSON.parse(selectedFilters[0]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )}) AND NOT (${filterstosend(
      JSON.parse(selectedFilters[1]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )})`;
    let B = a.replaceAll('"', "");
    let c = `"${B}"`;
    return c;
  }

  if (combineType === "Unique") {
    let a = `((${filterstosend(
      JSON.parse(selectedFilters[0]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )}) AND NOT (${filterstosend(
      JSON.parse(selectedFilters[1]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )})) OR ((${filterstosend(
      JSON.parse(selectedFilters[1]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )}) AND NOT (${filterstosend(
      JSON.parse(selectedFilters[0]["Filters"]),
      StaticData,
      filterValueD2,
      GraphKPI
    )}))`;
    let B = a.replaceAll('"', "");
    let c = `"${B}"`;
    return c;
  }
};

export const findPatientInfo = (arr) => {
  if (
    arr.includes("patient.Age_Group") &&
    arr.includes("observation.Cancer_Stage_General")
  ) {
    return arr.indexOf("patient.Age_Group") <
      arr.indexOf("observation.Cancer_Stage_General")
      ? '["patient.Age_Group asc"]'
      : '[""observation.Cancer_Stage_General asc"]';
  } else if (arr.includes("patient.Age_Group")) {
    return '["patient.Age_Group asc"]';
  } else if (arr.includes("observation.Cancer_Stage_General asc")) {
    return '["observation.Cancer_Stage_General"]';
  } else {
    return `["${arr[0]} desc"]`;
  }
};

const IndvidualVisGetter = async (
  a,
  cohortType,
  selectedFilters,
  combineLogic,
  alignment,
  GraphKPI,
  StaticData,
  filterValueD2
) => {

  try {
    // Get dynamic axis filters
    let dynamicAxisFilters = fieldstosend(a, GraphKPI, StaticData,selectedFilters)
      .replaceAll("[", "")
      .replaceAll("]", "")
      .replaceAll("'", "")
      .split(",");

    let sorts = findPatientInfo(dynamicAxisFilters);
    // Get fields to send based on cohort type
    let fieldsTosendPl =
      cohortType === "" || cohortType === "combine"
        ? fieldstosend(a, GraphKPI, StaticData,selectedFilters)
        : fieldstosendComparable(a, GraphKPI, StaticData);
    // Get payload filters based on cohort type
    let payloadFilters =
      cohortType === ""
        ? filterstosend(selectedFilters, StaticData, filterValueD2, GraphKPI)
        : cohortType === "compare"
          ? filtersToSendComparable(
              selectedFilters,
              GraphKPI,
              StaticData,
              filterValueD2
            )
          : MakeCombineFilters(
              combineLogic,
              selectedFilters,
              StaticData,
              filterValueD2,
              GraphKPI
            );
    // Create visconfig string
    let visconfig = `{'type':'${alignment}'${
      cohortType !== "" && a.length === 1
        ? `,'series_colors':{'${
            GraphKPI !== "All Records"
              ? "patient_count_cohort1"
              : "count_cohort1"
          }': '#12465b','${
            GraphKPI !== "All Records"
              ? "patient_count_cohort2"
              : "count_cohort2"
          }': '#F79C59'}`
        : ""
    }}`;
    // Create pivots array
    let pvt = `${
      a.length === 1
        ? "[]"
        : `['${DerivedTableConversion(a[1].value,StaticData[a[1].group][a[1].value]["ActualColumn"],StaticData[a[1].group][a[1].value]["ActualTable"],selectedFilters)}.${
            StaticData[a[1].group][a[1].value]["ActualColumn"]
          }']`
    }`;
    // Create FormData object
    const formdata = new FormData();
    formdata.append("sorts", sorts);
    formdata.append("field_name", fieldsTosendPl);
    formdata.append("pivots", pvt);
    if (cohortType === "" || cohortType === "combine") {
      formdata.append("filter_expression", payloadFilters);
    } else {
      formdata.append("dynamic_fields", payloadFilters);
    }
    formdata.append("vis_config", visconfig);

    // Set getting visuals flag
    // setGettingVisuals(true);

    // Define URL based on cohort type and combine logic
    let url = "";
    if (cohortType === "") {
      url = `${LOOKER_BASE_URL}/create_query_id`;
    } else if (cohortType === "compare") {
      url = `${LOOKER_BASE_URL}/create_query_id_compare_cohorts`;
    } else {
      if (combineLogic === "Union") {
        url = `${LOOKER_BASE_URL}/create_query_id_combine_cohort_union`;
      }
      if (combineLogic === "Subtraction") {
        url = `${LOOKER_BASE_URL}/create_query_id_combine_cohort_subtraction`;
      }
      if (combineLogic === "Intersection") {
        url = `${LOOKER_BASE_URL}/create_query_id_combine_cohort_intersection`;
      }
      if (combineLogic === "Unique") {
        url = `${LOOKER_BASE_URL}/create_query_id_combine_cohort_unique`;
      }
    }

    // Send POST request to get query ID
    const response = await fetch(url, {
      method: "POST",
      body: formdata,
      headers:{
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
      }
    });
    // Check for errors in the response
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse response as JSON
    const data = await response.json();

    // Define URL for creating look based on cohort type and combine logic
    let url2 = "";
    if (cohortType === "") {
      url2 = `${LOOKER_BASE_URL}/create_look?query=${Number(data)}`;
    } else if (cohortType === "compare") {
      url2 = `${LOOKER_BASE_URL}/create_look_compare_cohorts?query_compare_cohorts=${Number(
        data
      )}`;
    } else {
      if (combineLogic === "Union") {
        url2 = `${LOOKER_BASE_URL}/create_look_combine_cohort_union?query=${Number(
          data
        )}`;
      }
      if (combineLogic === "Subtraction") {
        url2 = `${LOOKER_BASE_URL}/create_look_combine_cohort_subtraction?query=${Number(
          data
        )}`;
      }
      if (combineLogic === "Intersection") {
        url2 = `${LOOKER_BASE_URL}/create_look_combine_cohort_intersection?query=${Number(
          data
        )}`;
      }
      if (combineLogic === "Unique") {
        url2 = `${LOOKER_BASE_URL}/create_look_combine_cohort_unique?query=${Number(
          data
        )}`;
      }
    }

    // Send POST request to create look
    const response2 = await fetch(url2, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
      },
    });

    // Check for errors in the response
    if (!response2.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse response as JSON
    const data2 = await response2.json();

    // Define URL for creating embed SSO URL
    let url3 = "";
    if (cohortType === "") {
      url3 = `${LOOKER_BASE_URL}/create_embed_sso_url?look_id=${Number(data2)}`;
    } else if (cohortType === "compare") {
      url3 = `${LOOKER_BASE_URL}/create_embed_sso_url_compare_cohorts?look_id=${Number(
        data2
      )}`;
    } else {
      if (combineLogic === "Union") {
        url3 = `${LOOKER_BASE_URL}/create_embed_sso_url_combine_cohort_union?look_id=${Number(
          data2
        )}`;
      }
      if (combineLogic === "Subtraction") {
        url3 = `${LOOKER_BASE_URL}/create_embed_sso_url_combine_cohort_subtraction?look_id=${Number(
          data2
        )}`;
      }
      if (combineLogic === "Intersection") {
        url3 = `${LOOKER_BASE_URL}/create_embed_sso_url_combine_cohort_intersection?look_id=${Number(
          data2
        )}`;
      }
      if (combineLogic === "Unique") {
        url3 = `${LOOKER_BASE_URL}/create_embed_sso_url_combine_cohort_unique?look_id=${Number(
          data2
        )}`;
      }
    }

    // Send POST request to create embed SSO URL
    const response3 = await fetch(url3, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
        
      },
    });

    // Check for errors in the response
    if (!response3.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse response as JSON
    const data3 = await response3.json();

    // Return the URL
    return { status: true, url: typeof data3 === "string" ? data3 : data3.url };
  } catch (error) {
    
    return { status: false, url: null };
  }
};

export default IndvidualVisGetter;
