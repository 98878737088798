import {
  Box,
  Button,
  Card,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/configureStore";
import { setFilterSaved } from "../../../store/reducers/CohortReducer";

import { useGetFilterDataQuery } from "../../../services/modules/queryBuilder";
import IndvidualVisGetter from "./IndvidualVisualGetter";

const DashboardVisuals = (props) => {
  const [VisualsArr, setVisualsArr] = useState([]);
  const [embedUrl, setEmbedUrl] = useState([]);
  const [gettingVisuals, setGettingVisuals] = useState(false);
  const { cohortData } = useSelector((state) => state.CohortReducer);
  const dispatch = useDispatch();
  const { data: filterData } = useGetFilterDataQuery();
  const [StaticData, setStaticData] = useState(filterData ?? {});

  useEffect(() => {
   if(props?.embedUrl === ""){
    setGraphVisuals();
   }else{
   setEmbedUrl(props?.embedUrl);
   setVisualsArr(props?.VisualsArr);
   }
  }, []);

  useEffect(() => {
    if (filterData) {
      setStaticData(filterData);
    }
  }, [filterData]);

  const setGraphVisuals = async () => {
    let Visuals = cohortData.Dashboards;
    let FLTRS = cohortData.Filters;
    setVisualsArr(cohortData.Dashboards);

    for (let index = 0; index < Visuals?.length; index++) {
      let resp = await IndvidualVisGetter(
        Visuals[index],
        "",
        FLTRS,
        null,
        Visuals[index][0]["Chart"],
        Visuals[index][0]["KPI"],
        StaticData,
        Visuals[index]
      );
      if (resp && resp.status) {
        setEmbedUrl((prev) => {
          let p = [...prev];
          p[index] = resp?.url;
          return p;
        });
        setGettingVisuals(false);
      } else {
        setGettingVisuals(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "block",
        position:"relative",
        maxWidth: "95%",
        m: "1vw"
      }}
    >  
            {embedUrl?.length > 0 &&
              embedUrl.map((url, index) => {
                return (
                  <Box
                    sx={{
                      height: props?.isPrintable ? "43vh": "auto",
                      boxShadow: "0px 3px 6px #00000014",
                      border: "1px solid #888888",
                      borderRadius: "1vh",
                      padding: "1vw",
                      display: "block",
                      position:"relative",
                      mt:  props?.isPrintable ? "8vh": "2vh",
                      justifyContent:"space-between",
                      alignItems:"center"
                    }}
                  >
                    <Box
                      sx={{
                        display: "block",
                        position:"relative",
                        //width: "95%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        verticalAlign:"center"
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {VisualsArr[index].length === 1
                          ? `${VisualsArr[index][0]["value"]}`
                          : `${VisualsArr[index][0]["value"]} X ${VisualsArr[index][1]["value"]}`}
                      </Typography>
                      
                    </Box>
                    <Divider />
                    <iframe
                      style={{
                        minHeight: "35vh",
                        marginTop: "1vh",
                        alignSelf:"center"
                       // maxHeight: "35vh",
                      }}
                      title="Map 1"
                      src={url}
                      width="90%"
                      height="auto"
                     // scrolling="yes"
                      frameBorder={0}
                    ></iframe>
                  </Box>
                );
              })}
            {gettingVisuals && embedUrl.length === 0 && (
              <Stack
                direction={"column"}
                sx={{
                  width: "100%",
                  display: "block",
                  flexDirection: "column",
                  ml: "auto",
                }}
              >
                <Skeleton
                  sx={{
                    width: "100%",
                    height: "60vh",
                    ml: "1vh",
                    mb: "0",
                  }}
                />
              </Stack>
            )}

            {gettingVisuals &&
              (embedUrl.length === 1 || embedUrl.length === 3) && (
                <Stack
                  direction={"column"}
                  sx={{
                    width: "48%",
                    display: "block",
                    flexDirection: "column",
                    ml: "auto",
                    mr: "1vw",
                  }}
                >
                  <Skeleton
                    sx={{
                      width: "100%",
                      height: "60vh",
                      ml: "1vh",
                      mb: "0",
                    }}
                  />
                </Stack>
              )}

            {gettingVisuals && embedUrl.length === 2 && (
              <Stack
                direction={"column"}
                sx={{
                  width: "48%",
                  display: "block",
                  flexDirection: "column",
                  ml: "0",
                }}
              >
                <Skeleton
                  sx={{
                    width: "100%",
                    height: "60vh",
                    ml: "1vh",
                    mb: "0",
                  }}
                />
              </Stack>
            )}
    </Box>
  );
};

export default DashboardVisuals;
