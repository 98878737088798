import { EndpointBuilder } from "@reduxjs/toolkit/query"

const deleteCohort = (build: EndpointBuilder<any, any, any>) => build.mutation<any, any>({

    query: (payload) => {
        return {
            url: "/delete_cohort",
            method: 'DELETE',
            body: payload
        };
    }
})

export default deleteCohort;