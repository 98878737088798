import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDownloadPatientListMutation } from "../../../services/modules/queryBuilder";
import { useSelector } from "../../../store/configureStore";
import DashboardVisuals from "../Builder/DashboardVisuals";
import { generateFilename } from "../Builder/PatientList/patientListutils";
import SingleCohortFilters from "../Builder/SingleCohortFilters";
import CohortCompare from "../Builder/CohortCompare";

const ExportModal = (props: any) => {
  const printRef = useRef(null);
  const { cohortData, cohortType, ComparableFilters } = useSelector((state) => state.CohortReducer);
  const [isFilterChecked, setFilterChecked] = useState(true);
  const [isVisualChecked, setVisualChecked] = useState(false);
  const [isPatientListChecked, setPatientListChecked] = useState(false);
  const csvPrintRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [downloadData, error] = useDownloadPatientListMutation();
  const [csvData, setCSVData] = React.useState([]);
  const [pages,setPages] = React.useState(['Filters']);
  const [isPrintable,setPrintable] = useState(false);
  const [isVisualTimerOn,setVisualTimerOn] = useState(false);


  const handleClose = () => {
    props?.onClose();
  };

  const handleDownload = () => {
   // enqueueSnackbar("Your Download has Started", { variant: "info" });
    if (isPatientListChecked) {
      getCSVData();
    }
    if(isFilterChecked || isVisualChecked){
    setPrintable(true);
    setTimeout(()=>{
      window.print();
    },1000);
    setTimeout(()=>{
      
      setPrintable(false);
      props?.onClose();
    },5000);
  }
  };

  const handleCheckedChange = (item: string) => {
    const temp = pages;


    if (!temp.includes(item)) {
      temp.push(item);
    } else {
      temp.splice(temp.indexOf(item), 1);
    }
    setPages(temp);
  };

  const getCSVData = () => {
    downloadData({
      query: JSON.stringify(cohortData.Filters),
      columns: {},
      count: 50000,
    })
      .unwrap()
      .then((result) => {
        setCSVData(result.data);
        setTimeout(() => {
   
          csvPrintRef?.current?.link.click();
        }, 3000);
      })
      .catch((error) => {
    
      });
  };

  return (
    <React.Fragment>
      <Dialog open={true} onClose={handleClose} fullWidth maxWidth={false as any} sx={{minHeight:"95%"}}>
        <Box
          // sx={{
          // //  border: "1px solid #EAEBEA",
          //   margin: "1.5vw",
          //   // minWidth: "30vw",
          //   borderRadius: "6px",
          // }}
          id="alert-dialog-title"
        >
         {!isPrintable && 
         <DialogTitle className="no-print">
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant="h4">
                Select details for downloading
              </Typography>
            </Stack>
          </DialogTitle>}
          <DialogContent
            sx={{
              //   minWidth: "70vw",
              // maxWidth: "40vw",
             
              overflowX: "hidden",
            }}
          >
              <Box>
              {!isPrintable && <Stack direction={"row"} gap={2} className="no-print">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isFilterChecked}
                      onChange={() => {
                        setFilterChecked(!isFilterChecked);
                        handleCheckedChange("Filters");
                      }}
                    />
                  }
                  label="Filters"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isVisualChecked}
                      onChange={() => {
                        setVisualChecked(!isVisualChecked);
                        handleCheckedChange("Visuals");
                        if(!isVisualChecked){
                          setVisualTimerOn(true);
                          setTimeout(()=>{
                            setVisualTimerOn(false);
                          },8000);
                        }
                      }}
                    />
                  }
                  label="Visuals"
                />
                {cohortType === "" && <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isPatientListChecked}
                      onChange={() => {
                        setPatientListChecked(!isPatientListChecked);
                      }}
                    />
                  }
                  label="Patient List"
                />}
              </Stack>}
              <Box >
              {!isPrintable && <Typography variant="body2" className="no-print">Preview</Typography>}
              
              <Box
                style={{ padding: "1vw",maxHeight:"90%", border: !isPrintable ? "1px solid #c3c3c3" : undefined, borderRadius: "4px",marginTop: "1vh",overflow:"auto"}}
              >
                {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}> */}
                  {/* <IconButton onClick={()=>setSelectedIndex(selectedIndex - 1)} disabled={selectedIndex === 0}>
                  <ArrowBackIos sx={{color : "#5F6368" }}  />
                  </IconButton> */}
                 
                 <Stack alignItems={"center"} justifyContent={"center"} sx={{display:"block",position:"relative"}}>
                 <div
                id="print"
                ref={printRef}
                className="print"
                style={{backgroundColor:'white',width:"100%",margin:"2px",display:"block",position:"relative"}}
              >
                      {isFilterChecked && (
                  
                      <Card sx={{ p: 2,minHeight:"40vh", width:"95%",m:"1vw",backgroundColor:'white', position:"relative" }}>
                        {cohortType === "" ? (<SingleCohortFilters
                          selectedFilters={cohortData.Filters}
                          corhotName={cohortData.CohortName}
                          filterSaved={true}
                          patientCount={0}
                        />) : (
                          <CohortCompare
                          ComparableFilters={ComparableFilters}
                          patientCount={0}
                        />
                        )}
                      </Card>
                    )}
                   
                    {isVisualChecked && (
                  <Box sx={{mt: isPrintable ? isFilterChecked ? "50vh ": "0vh" : "0vh"}}>
                    
                    {/* <Card sx={{ p: 2,minHeight:"40vh", maxHeight: "auto",   width:"95%",m:"1vw" }}>
                    <Typography variant="h4" mt={1}>Visuals</Typography> */}
                        <DashboardVisuals embedUrl={props?.embedUrl} VisualsArr={props?.VisualsArr} isPrintable={isPrintable}/>
                    {/* </Card> */}
                  </Box>
                )}
                </div>
                </Stack>
              </Box>
              </Box>
              <CSVLink
                  className="no-print"
              ref={csvPrintRef}
              style={{
                textDecoration: "none",
                color: "text.primary",
              }}
              filename={generateFilename("PatientList_" + cohortData.CohortName)}
              asyncOnClick={true}

              data={csvData.map((item: any) => {
                const { _id, ...rest } = item;
                return rest;
              })}
            //ref={csvLinkRef}
            ></CSVLink>
            </Box>
          </DialogContent>
          {!isPrintable && <Stack direction={"row"} gap={1.5} sx={{ display: "flex", justifyContent: "flex-end", m: 3 }}  className="no-print">
            <Button variant="outlined" size="small"
            disabled={(!isFilterChecked && !isVisualChecked && !isPatientListChecked)}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
            data-testId="Download-Modal-Action"
              disabled={
                (!isFilterChecked && !isVisualChecked && !isPatientListChecked) || isVisualTimerOn
              }
              sx={{ fontWeight: "bold" }}
              onClick={handleDownload}
              variant="contained"
              size="small"
              name=""
            >
              Download
            </Button>
          </Stack>}
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default ExportModal;
