import React, { forwardRef } from "react";

import { Action, ActionProps } from "../Action";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return (
      <Action
        ref={ref}
        cursor="grab"
        data-cypress="draggable-handle"
        data-testid="draggable-handle"
        {...props}
      >
        <DragIndicatorIcon fontSize="small" />
      </Action>
    );
  }
);
