import { EndpointBuilder } from "@reduxjs/toolkit/query";

export type IUserBase = {
  first_name: string;
  last_name: string;
  email: string;
};

type Keys = {
  Description: string;
  Admin: IUserBase[];
  PracticeTeam: string;
  Role: string;
  users: string[];
  Last_Modified: string;
  Created_On: string;
};
type OriginalResp = {
  _id: string;
  data: Keys;
};

export type IGroupDetails = {
  id: string;
} & Keys;

const getGroups = (build: EndpointBuilder<any, any, any>) =>
  build.query<IGroupDetails[], void>({
    query: () => ({
      url: "/get_groups",
      method: "GET",
    }),
    transformResponse: (response: OriginalResp[]) => {
      if (response?.length > 0) {
        return response.map((item) => ({
          id: item._id,
          ...item.data,
        }));
      }
      return [];
    },
  });

export default getGroups;
