import { api } from "../..";
import deleteCohort from "./deleteCohort";
import getAllCohortsData from "./getAllCohortsData";
import getCohortsData from "./getCohortData";
import renameCohort from "./renameCohort";

export const viewAllCohortsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCohorts: getAllCohortsData(build),
    getCohortsData: getCohortsData(build),
    deleteCohort: deleteCohort(build),
    renameCohort:renameCohort(build)
  }),
});

export const {
  useGetAllCohortsQuery,
  useLazyGetAllCohortsQuery,
  useGetCohortsDataQuery,
  useLazyGetCohortsDataQuery,
  useDeleteCohortMutation,
  useRenameCohortMutation
} = viewAllCohortsApi;
