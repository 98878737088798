import { EndpointBuilder } from "@reduxjs/toolkit/query"

const getCohortsData = (build: EndpointBuilder<any, any, any>) => build.query<any, { user_id: string, cohort_name: string }>({

    query: ({ user_id, cohort_name }) => {
        return {
            url: "/get_cohort",
            method: 'GET',
            params: { user_id: user_id.replace("@","#"), cohort_name: cohort_name }
        };
    }
})

export default getCohortsData;