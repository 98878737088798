import React from "react";

import { Grid } from "@mui/material";

export interface Props {
  children: React.ReactNode;
}

export function GridContainer({ children }: Props) {
  return (
    <Grid container spacing={1} gap={1} rowSpacing={2}>
      {children}
    </Grid>
  );
}
