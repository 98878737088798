// Multi - In , Ex , Is blank ( String , Multi )
// Text - Like ( Type  % Delhi %) , Is equal to
// Number - Great , Less , equal , in between ( Range)
// Date - Great , Less , equal , in between ( Range)

import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ListboxComponent from "../../components/AutoCompleteVirtualized/ListboxComponent";
import DateRangePicker from "./DateRangeInput";
import RangeInput from "./RangeInput";
import "./FilterSelect.css";
import { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

enum FilterTypes {
  MULTI_SELECT = "Multi Select",
  TEXT_BOX = "Text Box",
  NUMBER_RANGE = "Number Range",
  DATE_RANGE = "Date Range",
}

type FilterItemProps = {
  filter: FilterTypes;
  handleChange: (data: SelectChangeEvent) => {};
  F1Val: any;
  F2Val: any;
  setF2Val: any;
  MainfilterSelected: any;
  filterSelected: any;
  distinctVals: any;
  setError: any;
  isLoading?: boolean;
};

const FilterItem = (props: FilterItemProps) => {
  const {
    filter,
    handleChange,
    F1Val,
    F2Val,
    setF2Val,
    MainfilterSelected,
    filterSelected,
    distinctVals,
    setError,
    isLoading,
  } = props;
const [MuiIp,setMuiIp]=useState("")
const [view,setView]=useState<'year' | 'month' | 'day'>("day")
  switch (filter) {
    case FilterTypes.MULTI_SELECT:
      return (
        <Stack direction={"column"} gap={2}>
          <FormControl sx={{ m: 0, width: "98%", mt: "2vh" }}>
            <FormLabel>Select Criteria</FormLabel>
            <Select
              fullWidth
              size="small"
              sx={{ ml: "0" }}
              value={F1Val}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                "data-testid": "multi-select",
              }}
            >
              <MenuItem value="Includes">Includes</MenuItem>
              <MenuItem value="Excludes">Excludes</MenuItem>
              <MenuItem value="Is Blank">Is Blank</MenuItem>
            </Select>
          </FormControl>
          {F1Val !== "Is Blank" && (
            <Autocomplete
            inputValue={MuiIp}
              value={F2Val}
              size="small"
              onChange={(e, nv) => {
                setF2Val(nv);
              }}
              sx={{ m: 0, width: "98%", mt: "2vh" }}
              multiple
              
              id="checkboxes-tags-demo"
              options={distinctVals ?? []}
              disableCloseOnSelect={true}
              getOptionLabel={(option) => option}
              ListboxComponent={ListboxComponent}
              renderOption={(props, option, state) => [
                props,
                option,
                state,
               
              ]}
              // style={{ width: 500 }}
              renderInput={(params) =>
                isLoading ? (
                  <CircularProgress />
                ) : (
                  <FormGroup>
                    <FormLabel>Select Value</FormLabel>
                    <TextField onChange={(e)=>{setMuiIp(e.target.value)}} {...params} size="small" />
                  </FormGroup>
                )
              }
            />
          )}
        </Stack>
      );
    case FilterTypes.NUMBER_RANGE:
      return (
        <Stack direction={"column"} gap={2}>
          <FormControl sx={{ m: 0, width: "98%", mt: "2vh" }}>
            <FormLabel>Select Criteria</FormLabel>
            <Select
              fullWidth
              size="small"
              sx={{ ml: "0" }}
              value={F1Val}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                "data-testid": "select",
              }}
            >
              <MenuItem value="Greater Than">Greater Than</MenuItem>
              <MenuItem value="Less Than">Less Than</MenuItem>
              <MenuItem value="Equal To">Equals</MenuItem>
              <MenuItem value="Between">In Between</MenuItem>
            </Select>
          </FormControl>
          {F1Val !== "Between" && (
            <FormGroup
              sx={{
                m: 0,
                mt: "2vh",
                display: "flex",
                flexDirection: "column",
                width: "98%",
              }}
            >
              <FormLabel>Enter Number</FormLabel>
              <TextField
                type="number"
                autoComplete="off"
                size="small"
                sx={{ ml: "0" }}
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {
                  setF2Val(e.target.value);
                }}
                value={F2Val}
                InputProps={{
                  inputProps: {
                    min: 1,
                    "data-testid": "num-field",
                  },
                }}
              />
            </FormGroup>
          )}
          {F1Val === "Between" && (
            <Box sx={{ m: 0, mt: "2vh", width: "98%" }}>
              <FormLabel>Select Criteria</FormLabel>
              <RangeInput
                initialStartVal={F2Val && F2Val?.length > 1 && F2Val[0]}
                InitialEndVal={F2Val && F2Val?.length > 1 && F2Val[1]}
                onRangeAdded={(numbers: any) => {
                  setF2Val(numbers);
                }}
              />
            </Box>
          )}
        </Stack>
      );
    case FilterTypes.DATE_RANGE:
      return (
        <Stack direction={"column"} gap={2}>
          <FormControl sx={{ m: 0, width: "98%", mt: "2vh" }}>
            <FormLabel>Select Criteria</FormLabel>
            <Select
              fullWidth
              size="small"
              sx={{ ml: "0" }}
              value={F1Val}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                "data-testid": "select",
              }}
            >
              <MenuItem value="Greater Than">After</MenuItem>
              <MenuItem value="Less Than">Before</MenuItem>
              <MenuItem value="Equal To">On</MenuItem>
              <MenuItem value="Between">In Between</MenuItem>
            </Select>
          </FormControl>
          {F1Val !== "Between" && (
            <Box sx={{ m: 0, mt: "2.1vh", width: "98%", minHeight: "10vh" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormLabel>Pick a Date</FormLabel>
                <DatePicker
                  format="MM-DD-YYYY"
                  value={
                    F2Val ? dayjs(dayjs(F2Val, "DD-MM-YYYY").toDate()) : null
                  }
                  onChange={(newVal) => {
                    setF2Val(dayjs(newVal).format("DD-MM-YYYY"));
                    setError(false);
                  }}
                  //--
                  onError={(error) => setError(error === null ? false : true)}
                  views={['year', 'month', 'day']}  
        openTo={view}  
        onViewChange={(newView:any) => setView(newView)}
                />
              </LocalizationProvider>
            </Box>
          )}
          {F1Val === "Between" && (
            <Box sx={{ m: 0, mt: "2.1vh", width: "98%", minHeight: "10vh" }}>
              <DateRangePicker
                initialStartDate={
                  F2Val && F2Val?.length > 0
                    ? dayjs(F2Val[0], "DD-MM-YYYY")
                    : null
                }
                initialEndDate={
                  F2Val && F2Val?.length > 1
                    ? dayjs(F2Val[1], "DD-MM-YYYY")
                    : null
                }
                setDateRange={(newVal: any) => {
                  setF2Val(newVal);
                }}
                setError={(value: boolean) => setError(value)}
              />
            </Box>
          )}
        </Stack>
      );

    default:
      return (
        <Stack direction={"column"} gap={2}>
          <FormControl sx={{ m: 0, width: "98%", mt: "2vh" }}>
            <FormLabel>Select Criteria</FormLabel>
            <Select
              fullWidth
              size="small"
              sx={{ ml: "0" }}
              value={F1Val}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                "data-testid": "select",
              }}
            >
              <MenuItem value="Like">Like</MenuItem>
              <MenuItem value="Equal To">Equals</MenuItem>
            </Select>
          </FormControl>
          <FormGroup
            sx={{
              m: 0,
              mt: "2vh",
              width: "98%",
            }}
          >
            <FormLabel>Enter Value</FormLabel>
            <TextField
              autoComplete="off"
              sx={{ ml: "0" }}
              size="small"
              id="outlined-basic"
              variant="outlined"
              onChange={(e) => {
                setF2Val(e.target.value);
              }}
              value={F2Val}
            />
          </FormGroup>
        </Stack>
      );
  }
};

export default FilterItem;
