import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { getFormData } from "../../serviceUtils";
import { IUserBase } from "./getGroups";

export interface UserFormData {
  group_name: string;
  email: string;
  isnew: "True" | "False";
  user_lst: IUserBase[];
}

const saveGroupData = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, UserFormData>({
    query: (payload) => {
      let body = null;
      if (payload.isnew === "False") {
        const updateObj = {
          group_name: payload.group_name,
          email: payload.email,
          email_id: payload.user_lst[0].email,
          first_name: payload.user_lst[0].first_name,
          last_name: payload.user_lst[0].last_name,
        };
        body = getFormData(updateObj);
      } else {
        body = getFormData(payload);
      }

      return {
        url: payload.isnew === "True" ? "/add_user" : "/update_user",
        method: "POST",
        body,
        formData: true,
      };
    },
  });

export default saveGroupData;
