import React from "react";

/**
 *  Component to ignore the nodes under it when exporting, it to the PDF
 *
 * @param
 * @returns {Component}
 */
const HTMLToCanvasIgnore = ({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement | React.ReactNode;
}) => {
  return <div data-html2canvas-ignore="true">{children}</div>;
};

export default HTMLToCanvasIgnore;
