import { api } from "../..";
import getFilterData from "./getFilterData";
import getPatientCount from "./getPatientCount";
import getPatientData from "./getPatientData";
import saveCohortData from "./saveCohortData";
import getPatientList from "./getPatientList";
import getCohortDescription from "./getCohortDescription";
import downloadPatientList from "./downloadPatientList";
import patientSummary from "./patientSummary";
import patientDetails from "./patientDetails";
import getCombinedCohortCount from "./getCombinedCohortCount";
import getAppToken from "./getAppToken";

export const filterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFilterData: getFilterData(build),
    saveCohortData: saveCohortData(build),
    getPatientCount: getPatientCount(build),
    getPatientData: getPatientData(build),
    getPatientList: getPatientList(build),
    getCohortDescription: getCohortDescription(build),
    downloadPatientList: downloadPatientList(build),
    patientSummary: patientSummary(build),
    getPatientDetails: patientDetails(build),
    getCombinedCohortCount: getCombinedCohortCount(build),
    getAppToken: getAppToken(build)

  }),
});

export const {
  useLazyGetFilterDataQuery,
  useGetFilterDataQuery,
  useSaveCohortDataMutation,
  useLazyGetPatientDataQuery,
  useGetPatientDataQuery,
  useGetPatientCountMutation,
  useLazyGetPatientListQuery,
  useGetCohortDescriptionMutation,
  useDownloadPatientListMutation,
  useLazyPatientSummaryQuery,
  useLazyGetPatientDetailsQuery,
  useGetCombinedCohortCountMutation,
  useGetAppTokenMutation,
} = filterApi;
