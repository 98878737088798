import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import AdminLayout from "../layout/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import { setUserName } from "../../store/reducers/LoginReducer";
import { RootState } from "../../store/configureStore";

export const RequiredAuth: React.FC = () => {
  // New Comment
  const { oktaAuth, authState } = useOktaAuth();
  const { userInfo } = useSelector((state: RootState) => state.LoginReducer);
  const dispatch = useDispatch();

  const loginWithItem = async () => {
    if (oktaAuth.isLoginRedirect()) {
      try {
        await oktaAuth.handleRedirect();
      } catch (e) {
        console.log("okauth has =============================> error");
      }
    } else if (!(await oktaAuth.isAuthenticated())) {
      await oktaAuth.signInWithRedirect();
      await new Promise((r) => setTimeout(r, 3000));
    } else {
      console.log("user is authenticated");
    }
  };

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      loginWithItem();
    } else {
   
      oktaAuth.token.getUserInfo().then((userInfo: any) => {
        // @ts-ignore - oktaAuth.token.getUserInfo() returns a promise
        dispatch(setUserName(userInfo));
      });
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated || !userInfo) {
    return <div>Loading ....</div>;
  }

  return <AdminLayout />;
};
