import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { IUserBase } from "./getGroups";

export type IUserDetails = {
  group_name: string;
  role: string;
  practice_team: string;
  is_super_admin: boolean;
  is_group_admin: boolean;
  Last_Modified: string;
} & IUserBase;

const getUsersAll = (build: EndpointBuilder<any, any, any>) =>
  build.query<IUserDetails[], void>({
    query: () => ({
      url: "/get_user_detail",
      method: "GET",
    }),
  });

export default getUsersAll;
