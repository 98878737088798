import React, { forwardRef, CSSProperties } from "react";
import classNames from "classnames";

import styles from "./Action.module.css";
import { IconButton } from "@mui/material";

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties["cursor"];
}

export const Action = forwardRef<HTMLButtonElement, Props>(
  ({ active, className, cursor, style, ...props }, ref) => {
    return (
      //@ts-ignore
      <IconButton
        ref={ref}
        {...props}
        className={classNames(styles.Action, className)}
        size="small"
        style={
          {
            ...style,
            cursor,
            "--fill": active?.fill,
            "--background": active?.background,
          } as CSSProperties
        }
      />
    );
  }
);
