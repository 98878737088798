import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialData = {
  Filters: {},
  CohortName: "Untitled Cohort",
  Description: "",
  Dashboards: "",
  Columns: {},
};
const initialState = {
  cohortData: initialData,
  filterSaved: undefined,
  patientCount: 0,
  totalCount: 0,
  cohortType: "",
  comparedCohortTitle: "",
  ComparableFilters: [],
  combineLogic: "",
  isExportable: false,
};
export const cohortDataSlice = createSlice({
  name: "cohortData",
  initialState,
  reducers: {
    setCohortData: (state, action: PayloadAction<any>) => {
      state.cohortData = action.payload;
    },
    setFilterSaved: (state, action: PayloadAction<any>) => {
      state.filterSaved = action.payload;
    },
    resetCohortData: (state) => {
      state.cohortData = initialData;
    },
    setPatientCount: (
      state,
      action: PayloadAction<{
        patientCount: number;
        totalCount?: number;
      }>
    ) => {
      const { patientCount, totalCount } = action.payload;
      state.patientCount = patientCount;
      if (totalCount) {
        state.totalCount = totalCount;
      }
    },
    setCohortType: (state, action: PayloadAction<string>) => {
      state.cohortType = action.payload;
    },
    setComparableFilters: (state, action: PayloadAction<any>) => {
      state.ComparableFilters = action.payload;
    },
    setComparedCohortTitle: (state, action: PayloadAction<any>) => {
      state.comparedCohortTitle = action.payload;
    },
    setCombineLogic: (state, action: PayloadAction<string>) => {
      state.combineLogic = action.payload;
    },
    setExportable: (state, action: PayloadAction<boolean>) => {
      state.isExportable = action.payload;
    },
  },
});

export const {
  setCohortData,
  setFilterSaved,
  resetCohortData,
  setPatientCount,
  setCohortType,
  setComparableFilters,
  setComparedCohortTitle,
  setCombineLogic,
  setExportable,
} = cohortDataSlice.actions;
export default cohortDataSlice.reducer;
