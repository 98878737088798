import { Clear, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedAccordionsVisual from "../../components/Accordion/AccordionVisual";
import SearchBar from "../../components/Search/SearchBar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { FaChartPie } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa6";
import { CiViewTable } from "react-icons/ci";
import { FaChartArea } from "react-icons/fa";
import { LuLineChart, LuScatterChart } from "react-icons/lu";

import { useGetFilterDataQuery } from "../../../services/modules/queryBuilder";
import IndvidualVisGetter from "./IndvidualVisualGetter";
import DOMPurify from 'dompurify';

const BuilderVisualSideBar = ({
  open,
  toggleDrawer,
  getVal,
  selectedFilters,
  cohortType,
  combineLogic,
  Modify,
  ModifyIndex,
  ModifyData,
  clearModify,
}) => {
  const [searchT, setSearchT] = useState(false);
  const [DisableMoidify, setDisableModify] = useState(false);
  const { data } = useGetFilterDataQuery();
  const [StaticData, setStaticData] = useState(data ?? {});
  const [filteredData, setFilteredData] = useState({});
  const [PreviewState, setPreviewState] = useState(false);
  const [url, setUrl] = useState("");
  const [filterValueD2, setValD2] = React.useState([]);
  const [gettingVisuals, setGettingVisuals] = useState(false);
  const [alignment, setAlignment] = React.useState(
    cohortType !== "" ? "looker_area" : null
  );

  const [GraphKPI, setGraphKPI] = React.useState("Unique Patient Records");

  const handleChangeKPI = (event) => {
    if (event.target.value !== GraphKPI) {
      setGraphKPI(event.target.value);
      setUrl("");
      setPreviewState(false);
      setDisableModify(false);
    }
  };

  useEffect(() => {
    if (Modify) {
      setValD2(ModifyData);
      setAlignment(ModifyData[0]["Chart"]);
      setDisableModify(true);
      setGraphKPI(ModifyData[0]["KPI"] ?? "Unique Patient Records");
    }
  }, [Modify]);
  useEffect(() => {
    if (data) {
      setStaticData(data);
      setFilteredData(data);
    }
  }, [data]);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setUrl("");
      setPreviewState(false);
      setDisableModify(false);
      setAlignment(newAlignment);
    }
  };

  const getFilterVal = (filter) => {
    setDisableModify(false);
    setValD2([...filterValueD2, filter]);
  };

  const SingleGraphTypes = (val) => {
    const { group, value } = val;
    let dt = StaticData[group][value]["types"];
    if (dt === "Multi Select" || dt === "Text Box" || dt === "Number Range") {
   
      setAlignment("looker_bar");
    }

    if (dt === "Date Range") {
      setAlignment("looker_line");
    }
  };

  useEffect(() => {
    setUrl("");
    setPreviewState(false);
    if (filterValueD2.length === 1 && cohortType === "" && !DisableMoidify) {
      SingleGraphTypes(filterValueD2[0]);
    } else if (
      filterValueD2.length === 2 &&
      cohortType === "" &&
      !DisableMoidify
    ) {
      setAlignment("looker_bar");
    }
  }, [filterValueD2]);

  const ModifyPropsTosend = (a) => {
    for (let i = 0; i < a.length; i++) {
      a[i]["Chart"] = alignment;
      a[i]["KPI"] = GraphKPI;
    }
    return a;
  };

  // const dateFormatter = (dt) => {
  //   let a = dt;
  //   let b = a.split("-");
  //   b = b.reverse();
  //   let res = `${b[0]},${b[1]},${b[2]}`;
  //   return res;
  // };

  const searchFilter = (searchString) => {
    if (searchString.trim() !== "") {
      setSearchT(true);
    }

    if (searchString.trim() === "") {
      setSearchT(false);
    }
    const filtered = {};
    Object.keys(data).forEach((key) => {
      // Match the Parent keys
      if (key?.toLowerCase().includes(searchString?.toLowerCase())) {
        filtered[key] = data[key];
      } else {
        const filteredChild = {};
        // Match the child key and add them to the parent and push
        Object.keys(data[key]).forEach((child) => {
          if (child?.toLowerCase().includes(searchString?.toLowerCase())) {
            filteredChild[child] = data[key][child];
          }
        });

        if (Object.keys(filteredChild).length > 0) {
          filtered[key] = filteredChild;
        }
      }
    });
    // setValD2("");
    setFilteredData(filtered);
  };

  const isValidURL = (url) =>{
    const allowedDomain = "looker"
    return url.includes(allowedDomain);
  }

  const getVisualsVal = async (a) => {
    setGettingVisuals(true);
    let resp = await IndvidualVisGetter(
      a,
      cohortType,
      selectedFilters,
      combineLogic,
      alignment,
      GraphKPI,
      StaticData,
      filterValueD2
    );
    if (resp && resp?.status) {
   
      setUrl(resp?.url);
    } else {
   
      setUrl(null);
    }
    setGettingVisuals(false);
  };

  return (
    <Stack direction={"row"} sx={{ maxHeight: "100vh" }}>
      <Box
        sx={{
          width: "30vw",
          p: 3,
          pt: 1,
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="h2">Add a Visual to Cohort</Typography>
          {filterValueD2.length === 0 && (
            <IconButton
              aria-label="clear-button"
              style={{ marginLeft: "auto", fontSize: "1.5vw", padding: 0 }}
              onClick={() => {
                clearModify();
                toggleDrawer(false);
              }}
            >
              <Clear />
            </IconButton>
          )}
        </Stack>
        <SearchBar onSearch={searchFilter} />
        <CustomizedAccordionsVisual
          getVal={getFilterVal}
          disable={filterValueD2.length >= 2}
          staticData={filteredData}
          InitVal={filterValueD2}
          searchT={searchT}
        />
      </Box>
      {filterValueD2.length > 0 && (
        <Box
          sx={{
            width: "30vw",
            p: 3,
            pt: 1,
            pb: "2vh",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mb: "1vh" }}>
            <IconButton
              aria-label="clear-button2"
              style={{ marginLeft: "auto", fontSize: "1.5vw", padding: 0 }}
              onClick={() => {
                clearModify();
                toggleDrawer(false);
              }}
            >
              <Clear />
            </IconButton>
          </Box>

          <Stack
            direction={"row"}
            sx={{ display: "flex" }}
            alignItems={"center"}
          >
            <Typography sx={{ mt: "3vh" }} variant="h2">
              Data Field details
            </Typography>
          </Stack>
          {filterValueD2.map((valD2, index) => {
            return (
              <Card
                sx={{
                  p: 2,
                  mt: 4,
                  boxShadow: "0px 3px 10px #00000014",
                  border: "1px solid #C3C3C3",
                  borderRadius: "1vh",
                  minHeight: "30vh",
                  maxHeight: "30vh",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h3">{valD2.value}</Typography>
                  <IconButton
                    aria-label="clear-selection"
                    size="small"
                    sx={{
                      ml: "auto",
                    }}
                    onClick={() => {
                      setDisableModify(false);
                      setValD2((prev) => {
                        let p = [...prev];
                        let s = p.filter((a, ind) => ind !== index);
                        return s;
                      });
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>

                <Divider sx={{ my: 1 }} />
                <CardContent
                  sx={{ p: 0, maxHeight: "20vh", overflowY: "auto" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mt: 2,
                    }}
                  >
                    <Stack sx={{ width: "50%", gap: 0.7 }}>
                      <Typography variant="body2">Attribute Group</Typography>
                      <Typography
                        variant="body1"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {valD2.group}
                      </Typography>
                    </Stack>

                    <Stack sx={{ gap: 0.7 }}>
                      <Typography variant="body2">Value Type</Typography>
                      <Typography variant="body1">
                        {(data[valD2.group][valD2.value]["types"] ===
                          "Multi Select" ||
                          data[valD2.group][valD2.value]["types"] ===
                            "Text Box") &&
                          "String"}
                        {data[valD2.group][valD2.value]["types"] ===
                          "Number Range" && "Numeric"}
                        {data[valD2.group][valD2.value]["types"] ===
                          "Date Range" && "Date"}
                      </Typography>
                    </Stack>
                  </Box>
                  <Stack sx={{ gap: 0.7, width: "90%", mt: "1vh" }}>
                    <Typography variant="body2">Description</Typography>
                    <Typography variant="body1">
                      {data[valD2.group][valD2.value]["description"]}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            );
          })}
          <Stack sx={{ mt: "2vh" }}>
            <FormLabel>Select Visual Type</FormLabel>
            <Box
              sx={{
                display: "flex",
                width: "100%",

                mt: "1vh",
              }}
            >
              {(cohortType === "compare" || cohortType === "combine") && (
                <Stack>
                  <ToggleButtonGroup
                    disabled={gettingVisuals}
                    size="small"
                    value={alignment}
                    exclusive
                    color="primary"
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    sx={{
                      fontSize: "1vw",
                    }}
                  >
                    <Tooltip title="Area">
                      {" "}
                      <ToggleButton
                        sx={{
                          fontSize: "1vw",
                          height: "2vw",
                        }}
                        value="looker_area"
                        aria-label="left aligned"
                      >
                        <FaChartArea />{" "}
                        <Typography
                          sx={{
                            ml: "0.5vw",
                            fontSize: "0.9vw",
                            fontWeight: "bold",
                            textTransform: "none",
                          }}
                        >
                          {" "}
                          Area Chart
                        </Typography>
                      </ToggleButton>
                    </Tooltip>

                    <Tooltip title="Scatter">
                      <ToggleButton
                        sx={{
                          fontSize: "1vw",
                          height: "2vw",
                        }}
                        value="looker_scatter"
                        aria-label="right aligned"
                      >
                        <LuScatterChart />{" "}
                        <Typography
                          sx={{
                            ml: "0.5vw",
                            fontSize: "0.9vw",
                            fontWeight: "bold",
                            textTransform: "none",
                          }}
                        >
                          {" "}
                          Scatter Chart
                        </Typography>
                      </ToggleButton>
                    </Tooltip>
                  </ToggleButtonGroup>
                </Stack>
              )}
              {cohortType === "" && (
                <Stack>
                  {filterValueD2.length === 1 ? (
                    <>
                      {(data[filterValueD2[0].group][filterValueD2[0].value][
                        "types"
                      ] === "Text Box" ||
                        data[filterValueD2[0].group][filterValueD2[0].value][
                          "types"
                        ] === "Multi Select" ||
                        data[filterValueD2[0].group][filterValueD2[0].value][
                          "types"
                        ] === "Number Range") && (
                        <ToggleButtonGroup
                          disabled={gettingVisuals}
                          size="small"
                          value={alignment}
                          exclusive
                          color="primary"
                          onChange={handleAlignment}
                          aria-label="text alignment"
                          sx={{
                            fontSize: "1vw",
                          }}
                        >
                          <Tooltip title="Bar Chart">
                            {" "}
                            <ToggleButton
                              sx={{
                                fontSize: "1vw",
                                height: "2vw",
                              }}
                              value="looker_bar"
                              aria-label="left aligned"
                            >
                              <FaChartBar />{" "}
                              <Typography
                                sx={{
                                  ml: "0.5vw",
                                  fontSize: "0.9vw",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                {" "}
                                Bar Chart
                              </Typography>
                            </ToggleButton>
                          </Tooltip>

                          {/* <Tooltip title="Column Chart">
                            {" "}
                            <ToggleButton
                              sx={{
                                fontSize: "1vw",
                                height: "2vw",
                              }}
                              value="looker_column"
                              aria-label="left aligned"
                            >
                              <IoBarChart />{" "}
                              <Typography
                                sx={{
                                  ml: "0.5vw",
                                  fontSize: "0.9vw",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                {" "}
                                Column Chart
                              </Typography>
                            </ToggleButton>
                          </Tooltip> */}

                          <Tooltip title="Tabular View">
                            {" "}
                            <ToggleButton
                              sx={{
                                fontSize: "1vw",
                                height: "2vw",
                              }}
                              value="looker_grid"
                              aria-label="left aligned"
                            >
                              <CiViewTable />{" "}
                              <Typography
                                sx={{
                                  ml: "0.5vw",
                                  fontSize: "0.9vw",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                Tabular
                              </Typography>
                            </ToggleButton>
                          </Tooltip>

                          {data[filterValueD2[0].group][filterValueD2[0].value][
                            "types"
                          ] === "Multi Select" && (
                            <Tooltip title="Pie Chart">
                              <ToggleButton
                                sx={{
                                  fontSize: "1vw",
                                  height: "2vw",
                                }}
                                value="looker_pie"
                                aria-label="right aligned"
                              >
                                <FaChartPie />{" "}
                                <Typography
                                  sx={{
                                    ml: "0.5vw",
                                    fontSize: "0.9vw",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                  }}
                                >
                                  {" "}
                                  Pie Chart
                                </Typography>
                              </ToggleButton>
                            </Tooltip>
                          )}
                        </ToggleButtonGroup>
                      )}

                      {data[filterValueD2[0].group][filterValueD2[0].value][
                        "types"
                      ] === "Date Range" && (
                        <ToggleButtonGroup
                          disabled={gettingVisuals}
                          size="small"
                          value={alignment}
                          exclusive
                          color="primary"
                          onChange={handleAlignment}
                          aria-label="text alignment"
                          sx={{
                            fontSize: "1vw",
                          }}
                        >
                          <Tooltip title="Line Chart">
                            {" "}
                            <ToggleButton
                              sx={{
                                fontSize: "1vw",
                                height: "2vw",
                              }}
                              value="looker_line"
                              aria-label="left aligned"
                            >
                              <LuLineChart />{" "}
                              <Typography
                                sx={{
                                  ml: "0.5vw",
                                  fontSize: "0.9vw",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                {" "}
                                Line Chart
                              </Typography>
                            </ToggleButton>
                          </Tooltip>

                          <Tooltip title="Area Chart">
                            {" "}
                            <ToggleButton
                              sx={{
                                fontSize: "1vw",
                                height: "2vw",
                              }}
                              value="looker_area"
                              aria-label="left aligned"
                            >
                              <FaChartArea />{" "}
                              <Typography
                                sx={{
                                  ml: "0.5vw",
                                  fontSize: "0.9vw",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                {" "}
                                Area Chart
                              </Typography>
                            </ToggleButton>
                          </Tooltip>
                          <Tooltip title="Tabular View">
                            {" "}
                            <ToggleButton
                              sx={{
                                fontSize: "1vw",
                                height: "2vw",
                              }}
                              value="looker_grid"
                              aria-label="left aligned"
                            >
                              <CiViewTable />{" "}
                              <Typography
                                sx={{
                                  ml: "0.5vw",
                                  fontSize: "0.9vw",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                Tabular
                              </Typography>
                            </ToggleButton>
                          </Tooltip>
                        </ToggleButtonGroup>
                      )}
                    </>
                  ) : (
                    <ToggleButtonGroup
                      disabled={gettingVisuals}
                      size="small"
                      value={alignment}
                      exclusive
                      color="primary"
                      onChange={handleAlignment}
                      aria-label="text alignment"
                      sx={{
                        fontSize: "1vw",
                      }}
                    >
                      <Tooltip title="Bar Chart">
                        {" "}
                        <ToggleButton
                          sx={{
                            fontSize: "1vw",
                            height: "2vw",
                          }}
                          value="looker_bar"
                          aria-label="left aligned"
                        >
                          <FaChartBar />{" "}
                          <Typography
                            sx={{
                              ml: "0.5vw",
                              fontSize: "0.9vw",
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                          >
                            {" "}
                            Bar Chart
                          </Typography>
                        </ToggleButton>
                      </Tooltip>

                      <Tooltip title="Line Chart">
                        {" "}
                        <ToggleButton
                          sx={{
                            fontSize: "1vw",
                            height: "2vw",
                          }}
                          value="looker_line"
                          aria-label="left aligned"
                        >
                          <LuLineChart />{" "}
                          <Typography
                            sx={{
                              ml: "0.5vw",
                              fontSize: "0.9vw",
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                          >
                            Line Chart
                          </Typography>
                        </ToggleButton>
                      </Tooltip>

                      <Tooltip title="Tabular View">
                        {" "}
                        <ToggleButton
                          sx={{
                            fontSize: "1vw",
                            height: "2vw",
                          }}
                          value="looker_grid"
                          aria-label="left aligned"
                        >
                          <CiViewTable />{" "}
                          <Typography
                            sx={{
                              ml: "0.5vw",
                              fontSize: "0.9vw",
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                          >
                            Tabular
                          </Typography>
                        </ToggleButton>
                      </Tooltip>
                    </ToggleButtonGroup>
                  )}
                </Stack>
              )}
            </Box>

            <Stack sx={{ mt: "2vh" }}>
              <FormLabel sx={{ mt: "0vh" }}>Select KPI</FormLabel>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={GraphKPI}
                  onChange={handleChangeKPI}
                >
                  <FormControlLabel
                    value="Unique Patient Records"
                    control={<Radio />}
                    label="Unique Patient Records"
                  />
                  <FormControlLabel
                    value="All Records"
                    control={<Radio />}
                    label="All Records"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                mt: "2vh",
              }}
            >
              <Button
              data-testId="Prevw-btn"
                size="small"
                disabled={gettingVisuals || url}
                sx={{
                  py: "0.5vh",
                  ml: "auto",
                  mt: "0vh",
                  maxHeight: "fit-content",

                  borderRadius: "0",
                }}
                onClick={() => {
                  setPreviewState(true);
                  getVisualsVal(filterValueD2);
                }}
                variant="contained"
              >
                See Preview
              </Button>

              <Button
                data-testId="add-vis"
                size="small"
                disabled={gettingVisuals || DisableMoidify}
                sx={{
                  py: "0.5vh",
                  ml: "1vw",
                  mt: "0vh",
                  //   fontSize: "0.729vw",
                  borderRadius: "0",
                }}
                onClick={() => {
                  toggleDrawer(false);
                  getVal(ModifyPropsTosend(filterValueD2), url);
                }}
                variant="contained"
              >
                {Modify ? "Modify Visual" : "Add a Visual"}
              </Button>
            </Box>
          </Stack>

          {gettingVisuals && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ mx: "auto", mt: "1vh" }} />
              Getting Chart
            </Box>
          )}
          {PreviewState && url && !gettingVisuals && (
            <Box
              sx={{
                boxShadow: "0px 3px 6px #00000014",
                border: "1px solid #EAEBEA",
                padding: "1vw",
                mb: "10vh",
                mt: "2vh",
                width: "90%",
                height: "50vh",
                ml: "1vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                  }}
                  variant="h3"
                >
                  Visual
                </Typography>
              </Box>
              <Divider sx={{ mt: 1 }} />
             <iframe
                title="Map 1"
                src={DOMPurify.sanitize(url)}
                sandbox="allow-scripts allow-same-origin"
                width="100%"
                height="90%"
                scrolling="yes"
                frameBorder={0}
              ></iframe>
            </Box>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default BuilderVisualSideBar;
