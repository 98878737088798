import { AppBar, Button, Divider, Grid, Stack, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useState } from "react";
import { MdGrading } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  resetCohortData,
  setCohortType,
  setComparableFilters,
  setFilterSaved,
} from "../../../store/reducers/CohortReducer";
import { UseStaticDash } from "../../Context/StaticDashboardsContext";
import { USER_ROUTES } from "../../routes/UserRoutes";
import { ADMIN_ROUTES, isAdminPath } from "../../routes/utils";
import AlertDialog from "../Dialogs/AlertDialog";
import CohortCompareDialog from "../Dialogs/CohortCompareDialog";
import AppDrawer from "../SideDrawer";
import StaticDashboardDrawer from "./StaticDashboardDrawer";
import ExpandedView from "../../pages/Looker Static Dashboards/ExpandedView";
import { RootState } from "../../../store/configureStore";
import { useLazyGetAllCohortsQuery } from "../../../services/modules/viewAllCohorts";

const ADMIN_TABS = [
  {
    title: "Home",
    path: ADMIN_ROUTES.admin,
  },
  {
    title: "Groups",
    path: ADMIN_ROUTES.admin_groups_full_path,
  },
  {
    title: "Users",
    path: ADMIN_ROUTES.admin_users_full_path,
  },
  {
    title: "Audit Log",
    path: ADMIN_ROUTES.admin_history_full_path,
  },
];

export const ToolBarStyled = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  display: "flex",
  alignItems: "center",
}));

export default function AppTabs(props: any) {
  const location = useLocation();
  const { pathname } = location || {};
  const nav = useNavigate();
  const { filterSaved, cohortType } = useSelector(
    (state: RootState) => state.CohortReducer
  );

  const handleBlur = (event: any) => {
    if (!event.target.value) {
      // Set default value here
      setNm("No Cohort");
    }
  };
  const [open, toggleDrawer] = React.useState(false);
  const [getAllCohorts, { data,isLoading }] =
  useLazyGetAllCohortsQuery();
  const [showUnsavedDialog, setShowUnsavedDialog] = useState<boolean>();
  const [showCompareDialog, setShowCompareDialog] = useState<boolean>();
  const [showCompareDialogOpen, setShowCompareDialogOpen] = useState(false);
  const [isCombine, setCombine] = useState(false);
  const [TabValue, setTabValue] = useState(0);
  const [cohortNm, setNm] = useState("No Cohort");
  const [value, setValue] = React.useState<string>(USER_ROUTES.builder);
  const [prevEvent, setPrevEvent] = useState("");
  const dispatch = useDispatch();
  const { CohortNames, setcnm, StaticUrl } = UseStaticDash();
  const [TempTabNumber, setTempTabNumber] = useState(0);

  React.useEffect(() => {
    const allowedRoutes = {
      [USER_ROUTES.builder]: true,
      [USER_ROUTES.nlp_builder]: true,
      [USER_ROUTES.all_cohorts]: true,
      [USER_ROUTES.patient_profiling]: true,
      [USER_ROUTES.diagnonis_staging]: true,
      [USER_ROUTES.therapy_analysis]: true,
      [ADMIN_ROUTES.admin]: true,
      [ADMIN_ROUTES.admin_users_full_path]: true,
      [ADMIN_ROUTES.admin_groups_full_path]: true,
      [ADMIN_ROUTES.admin_history_full_path]: true,
    };
    if (allowedRoutes[pathname] === true) {
      setValue(pathname);
    } else {
      setValue("");
    }
  }, [pathname]);


 

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setPrevEvent(newValue);
    checkFilter(event) ?? setValue(newValue);
  };

  const checkFilter = (event: React.SyntheticEvent) => {
    if (cohortType !== "") {
      setShowCompareDialog(true);
      event.preventDefault();
      return true;
    } else {
      if (filterSaved === false) {
        setShowUnsavedDialog(true);
        event.preventDefault();
        return true;
      }
    }
  };

  const handleButtonClick = (event: React.SyntheticEvent) => {
    if (cohortType !== "") {
      setShowCompareDialog(false);
     
    } else {
      setShowUnsavedDialog(false);
    }
    dispatch(setCohortType(""));
    dispatch(setComparableFilters([]));
    dispatch(resetCohortData());
    dispatch(setFilterSaved(undefined));

   
    handleMenuClick(event, TempTabNumber, null);
   
  };

  const handleMenuClick = (
    event: React.SyntheticEvent,
    index: number,
    dis: any
  ) => {
    

    switch (index) {
      case 0:
        setTabValue(index);
        nav("/practice-explorer");
        break;
      case 1:
      case 2:
        setCombine(index === 2);
        setShowCompareDialogOpen(true);

        if (TabValue === 3) {
          nav("/practice-explorer");
        }
        break;
      case 3:
        setTabValue(index);
        nav("/all-corhots");
        break;
    }
  };

  const handleClose = () => {
    setShowUnsavedDialog(false);
    setShowCompareDialog(false);
  };
  const handleViewallCohorts = (event: React.SyntheticEvent) => {
    setPrevEvent("/all-corhots");
    checkFilter(event) ?? nav("/all-corhots");
    checkFilter(event) ?? setTabValue(3);
  };

  React.useEffect(() => {

    if (cohortNm !== null) {
      setcnm(cohortNm);
    }
  }, [cohortNm]);

  React.useEffect(() => {
    if (pathname === "/practice-explorer") {
      setTabValue(0);
    }

    if (pathname === "/all-corhots") {
      setTabValue(3);
    }
  }, [pathname]);

  /**
   * Showing the tabs only on the screen it was needed
   */
  if (value === "") {
    return null;
  }
  const navTabs = {
    "Create Cohort": "Create",
    "Compare Cohort": "Compare",
    "Combine Cohort": "Combine",
  };
  return (
    <AppBar
      elevation={0}
      sx={{
        top: "0vh",
        position: "sticky",
        backgroundColor: "white",
      }}
    >
      <AppDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        clearModify={() => {
  
        }}
      >
        <StaticDashboardDrawer
          open={open}
          toggleDrawer={toggleDrawer}
          ExistingCoh={["No Cohort", ...Object.keys(CohortNames)]}
          CohortNames={CohortNames}
          cohortNm={cohortNm}
          setNm={setNm}
        />
      </AppDrawer>
      <Toolbar>
        {(pathname === "/practice-explorer" || pathname === "/all-corhots") && (
          <Stack
            direction={"column"}
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Tabs
                value={TabValue}
                //@ts-expect-error
                textColor="black"
                // onChange={(e) => {
                //   e.preventDefault();
                // }}
                sx={{ mr: "3vw", color: "black", mt: "1vh" }}
              >
                {Object.keys(navTabs).map((nt, index) => {
                  return (
                    <Tab
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        fontSize: "0.729vw",
                      }}
                      // disabled={
                      //   (index === 1 || index === 2) &&
                      //   (!cohortNm || cohortNm === "Untitled" || !filterSaved)
                      // }
                      onClick={(e) => {
                        if (index !== TabValue) {
                          setTempTabNumber(index);
                          checkFilter(e) ?? handleMenuClick(e, index, null);
                        }
                      }}
                      disableFocusRipple
                      disableRipple
                      value={index}
                      label={nt}
                    />
                  );
                })}

                <Tab
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "0.729vw",
                  }}
                  onClick={(e) => {
                    if (TabValue !== 3) {
                      setTempTabNumber(3);
                      handleViewallCohorts(e);
                    }
                  }}
                  disableFocusRipple
                  disableRipple
                  value={3}
                  label="View All Cohorts"
                />
              </Tabs>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              />
            </Grid>
            <Divider sx={{ height: "1px", mx: "0vw", mb: 0 }} />
          </Stack>
        )}

        {(pathname === "/population-profiling" ||
          pathname === "/therapy-pathways" ||
          pathname === "/diagnosis-and-disease-staging") && (
          <Stack direction={"column"} sx={{ width: "100%" }}>
            <Box
              sx={{
                maxWidth: "auto",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  toggleDrawer(true);
                }}
                startIcon={<MdGrading />}
              >
                Select Attributes
              </Button>
              <Box sx={{ display: "flex", ml: "auto" }}>
                {StaticUrl && StaticUrl !== "" && (
                  <ExpandedView
                    url={StaticUrl}
                    head={
                      pathname === "/population-profiling"
                        ? "Population Profiling"
                        : pathname === "/therapy-pathways"
                          ? "Therapy Pathway Analysis"
                          : "Disease and Diagnosis Staging"
                    }
                  />
                )}
              </Box>
            </Box>
          </Stack>
        )}

        {isAdminPath(pathname) && (
          <Stack
            direction={"column"}
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Tabs
                //@ts-expect-error
                textColor="black"
                sx={{ mr: "3vw", color: "black", mt: "1vh" }}
                value={value}
                onChange={handleChange}
              >
                {ADMIN_TABS.map((item) => (
                  <Tab
                    disableFocusRipple
                    disableRipple
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "0.729vw",
                    }}
                    label={item.title}
                    component={Link}
                    to={item.path}
                    value={item.path}
                  />
                ))}
              </Tabs>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              />
            </Grid>
            <Divider sx={{ height: "1px", mx: "1vw", mb: 0 }} />
          </Stack>
        )}
        {showUnsavedDialog && (
          <AlertDialog
            title={"Unsaved Changes"}
            buttonName={"Discard"}
            description={
              "You have unsaved changes. Changes will be discarded if you proceed. Do you want to Discard?"
            }
            onButtonClick={handleButtonClick}
            onClose={handleClose}
          />
        )}

        {showCompareDialogOpen && (
          <CohortCompareDialog
            isCombine={isCombine}
            onClose={() => setShowCompareDialogOpen(false)}
            setTabVal={setTabValue}
            Temp={TempTabNumber}
          />
        )}
        {showCompareDialog && (
          <AlertDialog
            title={cohortType === "combine" ? "Combine View" : "Compare View"}
            buttonName={"Discard"}
            description={
              cohortType === "combine"
                ? "Your combined view will be discarded if you proceed. Do you want to Discard?"
                : "Your compared view will be discarded if you proceed. Do you want to Discard?"
            }
            onButtonClick={handleButtonClick}
            onClose={handleClose}
          />
        )}
      </Toolbar>
    </AppBar>
  );
}
