import { styled } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";
import LandingPage from "../pages/Landing/LandingPage";
import ProtectedRoute from "../routes/ProtectedRoute";

import AppTabs from "../components/Tabs";
import Footer from "../pages/Landing/Footer";
import DashboardNavbar, {
  APP_BAR_HEIGHT_DESKTOP,
  APP_BAR_HEIGHT_MOBILE,
} from "./DashboardNavbar";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  flexDirection: "column",
});

/**
 * Handles the Layout of the app,
 * Provides with Top Navbar and app Layout
 */
export default function DashboardLayout() {
  const location = useLocation();
  const { pathname } = location;

  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight:
      pathname === "/" || pathname === "/nlp-builder" ? "100%" : "90vh",
    marginTop:
      pathname === "/" ? "4vh" : pathname === "/nlp-builder" ? "0vh" : "75px",
    backgroundColor: "#F7F7F7",
    boxShadow: "none",
    paddingTop: APP_BAR_HEIGHT_MOBILE + 10,
    // paddingBottom: theme.spacing(10),
    //   paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_HEIGHT_DESKTOP + 10,
    },
  }));

  return (
    <RootStyle>
      <ProtectedRoute>
        <DashboardNavbar>
          {pathname !== "/" && pathname !== "/nlp-builder" && <AppTabs />}
        </DashboardNavbar>
        <MainStyle>
          {pathname === "/" && <LandingPage />}
          <Outlet />
        </MainStyle>
        <Footer />
      </ProtectedRoute>
    </RootStyle>
  );
}
