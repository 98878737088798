import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
// SnapShots Fixed
type Item = {
  label: string;
  disabled?: boolean;
  onClick: () => void;
};

interface ActionMenuProps {
  items: Item[];
}

export default function ActionMenu({ items }: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="action-menu"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="action-menu"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items?.map((i) => (
          <MenuItem
            key={i.label}
            onClick={(e) => {
              i?.onClick();
              handleClose();
            }}
            disabled={i.disabled}
          >
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
