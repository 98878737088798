import { EndpointBuilder } from "@reduxjs/toolkit/query"

const renameCohort = (build: EndpointBuilder<any, any, any>) => build.mutation<any, any>({

    query: (payload) => {
        return {
            url: "/rename_cohort",
            method: 'POST',
            body: payload
        };
    }
})

export default renameCohort;