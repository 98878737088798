import { Suspense } from "react";
import DashboardLayout from "./DashboardLayout";
import { CircularProgress } from "@mui/material";

//Admin Protected routes

const AdminLayout = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <DashboardLayout />
    </Suspense>
  );
};

export default AdminLayout;
