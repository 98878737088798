import Admin from "../pages/Admin/Admin";
import Groups from "../pages/Admin/groups/Groups";
import History from "../pages/Admin/history/History";
import Users from "../pages/Admin/users/Users";
import { RequiredAuth } from "./SecureRoute";

const AdminRoutes = {
  path: "/admin",
  title: "admin",
  element: <RequiredAuth />,
  children: [
    {
      path: "",
      element: <Admin />,
    },
    {
      path: "groups",
      element: <Groups />,
    },
    {
      path: "users",
      element: <Users />,
    },
    {
      path: "history",
      element: <History />,
    },
  ],
};

export default AdminRoutes;
