import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { styled, SxProps } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounceCallBack";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  right: 0,
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export type SearchInputProps = TextFieldProps & {
  onSearch: (s: string) => void;
  placeholder?: string;
  clearOnBlur?: boolean;
};

export type SearchBarProps = SearchInputProps & {
  hideIcon?: boolean;
  containerSX?: SxProps;
};

export default function SearchBar(props: SearchBarProps) {
  const { hideIcon = false, containerSX = {}, ...rest } = props;

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mb: 1, ...containerSX }}>
      <Search>
        {!hideIcon && (
          <SearchIconWrapper>
            <SearchIcon
              sx={(theme) => ({
                color: theme.palette.primary.main,
              })}
              fontSize="small"
            />
          </SearchIconWrapper>
        )}
        <SearchInput {...rest} />
      </Search>
    </Box>
  );
}

export const SearchInput = (props: SearchInputProps) => {
  const { onSearch, placeholder, clearOnBlur } = props;

  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    onSearch(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <TextField
      value={value}
      placeholder={placeholder ?? "Search Attribute"}
      inputProps={{ "aria-label": "search" }}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => {
        if (clearOnBlur) {
          setValue("");
        }
      }}
    />
  );
};
