import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  return (
    <Stack
      sx={{ display: "flex", flexDirection: "row", height: "auto", my: "auto" }}
    >
      You have Been Succesfully Logged Out !
      <Button
        onClick={() => {
          nav("/practice-explorer");
        }}
        variant="contained"
      >
        Go to Login
      </Button>
    </Stack>
  );
};

export default Logout;
