import "./App.css";
import ThemeProvider from "./theme";
import Routes from "./routes";
import { NavProvider } from "./Context/NavbarContext";
import { StaticChartProvider } from "./Context/StaticDashboardsContext";
import { SnackbarProvider } from "notistack";
import CustomSnackbar from "./components/Snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import { useState, useEffect } from "react";

declare global {
  interface Window {
    __env: {
      REACT_APP_API_BASE_URL: string;
      REACT_APP_AUTH0_DOMAIN: string;
      REACT_APP_AUTH0_CLIENT_ID: string;
      REACT_APP_AUTH_REDIRECT_URL: string;
      REACT_APIGEE_END_POINT: string;
      REACT_APP_APIGEE_USERNAME: string;
      REACT_APP_APIGEE_PASSWORD: string;
      REACT_APP_APIGEE_AUTH_URL: string
      REACT_APP_APIGEE_AUTH_USERNAME:string
      REACT_APP_APIGEE_AUTH_PASSWORD: string
    };
  }
}

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    //App Start
    <ThemeProvider>
      <SnackbarProvider
        Components={{
          success: CustomSnackbar,
          warning: CustomSnackbar,
          error: CustomSnackbar,
          info: CustomSnackbar,
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <NavProvider>
            <StaticChartProvider>
              <Routes />
            </StaticChartProvider>
          </NavProvider>
        </Security>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
