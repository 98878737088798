import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  useGetPracticesQuery,
  useGetRolesQuery,
  useLazyGetGroupsQuery,
} from "../../../services/modules/admin";
import { IGroupDetails } from "../../../services/modules/admin/getGroups";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import GroupCard from "./GroupCard";
import SearchBar from "../../components/Search/SearchBar";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "./hideScrollbar.css";
import { LeftArrow, RightArrow } from "./Arrows";

interface GroupsProps {}

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const Groups: FC<GroupsProps> = () => {
  const { data: roles = [] } = useGetRolesQuery();
  const { data: practices = [] } = useGetPracticesQuery();
  const [getGroupData, { isLoading, data: groupsRaw = [] }] =
    useLazyGetGroupsQuery();

  const [groupData, setGroupData] = useState<IGroupDetails[]>([]);
  const [filters, setFilters] = useState({
    search: "",
    practice: "",
    role: "",
  });
  // fetch groups
  useEffect(() => {
    getGroupData();
  }, []);

  //Set group data on
  useEffect(() => {
    setGroupData(groupsRaw);
  }, [groupsRaw]);

  const onFilter = () => {
    let filterData = [...groupsRaw];
    // search filter
    if (filters.search) {
      const searchRegex = new RegExp(escapeRegExp(filters.search), "i");
      filterData = filterData?.filter((row: any) => {
        return Object.keys(row).some((field: any) => {
          return searchRegex.test(row[field]?.toString());
        });
      });
    }
    // practice filter
    if (filters.practice) {
      filterData = filterData.filter((row: IGroupDetails) => {
        return row.PracticeTeam === filters.practice;
      });
    }
    // role filter
    if (filters.role) {
      filterData = filterData.filter((row: IGroupDetails) => {
        return row.Role === filters.role;
      });
    }
    setGroupData(filterData);
  };

  useEffect(() => {
    onFilter();
  }, [filters.search, filters.practice, filters.role]);

  //Group Modifications

  return (
    <>
      <Typography variant="h2">Group Details</Typography>
      <Box>
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid gap={2} container mt={2}>
            <FormGroup>
              <FormLabel>Search</FormLabel>
              <SearchBar
                onSearch={(t: string) => {
                  setFilters({
                    ...filters,
                    search: t,
                  });
                }}
                containerSX={{
                  minWidth: "50vh",
                  mt: 0,
                }}
                placeholder="Search groups"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Practice</FormLabel>
              <Select
                sx={{ height: "36px", minWidth: "30vh" }}
                value={filters.practice}
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    practice: v.target.value,
                  });
                }}
                inputProps={{ "data-testid": "practice-select"}}
              >
                {practices?.map((practice: string) => (
                  <MenuItem key={practice} value={practice}>
                    {practice}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <FormLabel>Role</FormLabel>
              <Select
                sx={{ height: "36px", minWidth: "30vh" }}
                value={filters.role}
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    role: v.target.value,
                  });
                }}
                inputProps={{ "data-testid": "role-select"}}
              >
                {roles?.map((role: string) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
            <Button
              sx={{ mt: 2 }}
              onClick={() => {
                setFilters({
                  search: "",
                  role: "",
                  practice: "",
                });
                setGroupData(groupsRaw);
              }}
            >
              Clear filters
            </Button>
            <Box sx={{ flexGrow: 1 }} />
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            // onWheel={onWheel}
          >
            {groupData.length > 0
              ? groupData.map((group, _idx) => (
                  <GroupCard index={_idx} item={group} />
                ))
              : []}
          </ScrollMenu>
        </Box>
      </Box>
    </>
  );
};

export default Groups;
