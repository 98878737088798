import { EndpointBuilder } from "@reduxjs/toolkit/query";

export interface Filter {}

const saveCohortData = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, any>({
    query: (payload) => ({
      url: "/save_cohort",
      method: "POST",
      body: payload,
    }),
  });

export default saveCohortData;
