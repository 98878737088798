import {
  Button,
  FormGroup,
  FormLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { RootState } from "../../../../store/configureStore";
import { useSelector } from "react-redux";
import { UserFormData } from "../../../../services/modules/admin/modifyUser";
import { IUserDetails } from "../../../../services/modules/admin/getUsersAll";
import { truncate } from "fs";

interface AddUserFormProps {
  groupNames: string[];
  onAddUser: (a: UserFormData) => void;
  editData: IUserDetails | null;
}

const validationSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Please provide a valid Email")
    .required("Email is required"),
  groupName: yup.string().required("Group Name is required"),
});

const AddUser: FC<AddUserFormProps> = ({ groupNames, onAddUser, editData }) => {
  const { userName: currentUser } = useSelector(
    (state: RootState) => state.LoginReducer
  );
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      groupName: "",
    },
    onSubmit: (e) => {
      onAddUser({
        group_name: e.groupName,
        user_lst: [
          {
            first_name: e.first_name,
            last_name: e.last_name,
            email: e.email,
          },
        ],
        email: currentUser,
        isnew: editData ? "False" : "True",
      });
    },
    validationSchema,
  });
  const [disableMail, setDisableMail] = useState(false);
  useEffect(() => {
    if (editData) {
      setDisableMail(true);
      formik.setValues({
        first_name: editData.first_name,
        last_name: editData.last_name,
        email: editData.email,
        groupName: editData.group_name,
      });
    }
  }, [editData]);

  return (
    <form>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {!editData && (
          <Typography>
            Adding
            <b> new user</b>
          </Typography>
        )}

        {editData && (
          <Typography>
            Editing
            <b> User</b>
          </Typography>
        )}
        <Button variant="outlined" onClick={formik.submitForm}>
          Save
        </Button>
      </Stack>
      <Stack spacing={2} mt={3}>
        <FormGroup>
          <FormLabel>First Name</FormLabel>
          <TextField
            fullWidth
            value={formik.values.first_name}
            error={!!formik.errors.first_name}
            placeholder="First Name"
            name="first_name"
            helperText={formik.touched.first_name && formik.errors.first_name}
            onChange={formik.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Last Name</FormLabel>
          <TextField
            fullWidth
            value={formik.values.last_name}
            error={!!formik.errors.last_name}
            placeholder="Last Name"
            name="last_name"
            helperText={formik.touched.last_name && formik.errors.last_name}
            onChange={formik.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Associated Email</FormLabel>
          <TextField
            disabled={disableMail}
            fullWidth
            value={formik.values.email}
            error={!!formik.errors.email}
            placeholder="Email"
            name="email"
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Group Name</FormLabel>
          <TextField
            fullWidth
            disabled={disableMail}
            value={formik.values.groupName}
            error={!!formik.errors.groupName}
            placeholder="Group Name"
            select
            name="groupName"
            helperText={formik.touched.groupName && formik.errors.groupName}
            onChange={formik.handleChange}
          >
            {groupNames.map((g) => (
              <MenuItem key={g} value={g}>
                {g}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
      </Stack>
    </form>
  );
};

export default AddUser;
