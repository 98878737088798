import {
  Box,
  Divider,
  Grid,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
} from "@mui/material";
import React from "react";
import { IPatientDetails } from "../../../../services/modules/queryBuilder/patientDetails";
import { DetailItem } from "./AboutPatient";

type Props = {
  data: IPatientDetails["data"];
};

const TABS = ["Administered Medication", "Prescribed Medication", "Labs"];
const SummaryTabs = ({ data }: Props) => {
  const [value, setValue] = React.useState(TABS[0]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        border: "1px solid #d0d0d0",
        boxShadow: "0px 3px 6px #00000014",
        borderRadius: "12px",
        width: "100%",
        minHeight: "220px",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        //@ts-ignore
        textColor="black"
        scrollButtons="auto"
        sx={{
          ml: 1,
          "& .MuiTab-root.Mui-selected": {
            fontWeight: "bold",
          },
        }}
      >
        {TABS.map((key: string) => (
          <Tab
            key={key}
            value={key}
            label={key}
            sx={{
              textTransform: "capitalize",
              color: "black",
            }}
          />
        ))}
      </Tabs>
      <Divider />
      {value === TABS[0] && (
        <Table>
          {data?.administered_medication?.map((item) => (
            <TableRow key={item.Drug_Administration_Date}>
              <TableCell>{item.Drug_Administered_Brand_Name ?? "-"}</TableCell>
              <TableCell>{item.Drug_Form_Administered ?? "-"}</TableCell>
              <TableCell>{item.Drug_Dose_Administered ?? "-"}</TableCell>
              <TableCell>{item.Drug_Administration_Date ?? "-"}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}
      {value === TABS[1] && (
        <Table>
          {data?.prescribed_medication?.map((item) => (
            <TableRow key={item.Drug_Prescription_Date}>
              <TableCell>{item.Prescribed_Drug_Brand_Name ?? "-"}</TableCell>
              <TableCell>{item.Prescribed_Drug_Form ?? "-"}</TableCell>
              <TableCell>{item.Prescribed_Drug_Route ?? "-"}</TableCell>
              <TableCell>{item.Drug_Prescription_Date ?? "-"}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}
      {value === TABS[2] && (
        <Table>
          {data?.Labs?.map((item) => (
            <TableRow key={item.Diagnostic_Result_Date}>
              <TableCell>{item.Diagnostic_Test_Lab_Test_Name ?? "-"}</TableCell>
              <TableCell>{item.Diagnostic_Test_Date ?? "-"}</TableCell>
              <TableCell>{item.Diagnostic_Result_Date ?? "-"}</TableCell>
              <TableCell>{item.Diagnostic_Test_Result ?? "-"}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}
    </Box>
  );
};
export default SummaryTabs;
