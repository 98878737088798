import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "../../store/configureStore";
import { useLazyGetAllCohortsQuery } from "../../services/modules/viewAllCohorts";
import { useLocation, useNavigate } from "react-router-dom";
const StaticDashContext = createContext();

export const UseStaticDash = () => {
  return useContext(StaticDashContext);
};

export const StaticChartProvider = ({ children }) => {
  const { userName } = useSelector((state) => state.LoginReducer);
  const userAppToken = useSelector(
    (state) => state.LoginReducer.token
  );
  const [CohortNames, setAllCohotNames] = useState({});
  const [cnm, setcnm] = useState("No Cohort");
  const [listChange, setListChange] = useState(0);
  const [StaticUrl, setStaticUrl] = useState("");
  const [GlobalFilters, setGlobalFilters] = useState({});
  const location = useLocation();
  const { pathname } = location || {};

  const [getAllCohorts, { data: allCohorts, error, isLoading }] =
    useLazyGetAllCohortsQuery();

  useEffect(() => {
    setStaticUrl("");
  }, [pathname, cnm]);
  useEffect(() => {
    if(userName && userAppToken){
    getAllCohorts(userName);}
  }, [listChange,userName,userAppToken]);

  useEffect(() => {
    if (allCohorts && !isLoading) {
  
      let a = {};
      allCohorts?.data?.map((chrt) => {
        a[chrt.CohortName] = {
          Filter: chrt.Filters,
          Desc: chrt.Description
            ? chrt.Description
            : "No Description Available",
        };
      });
      setAllCohotNames(a);
    }
  }, [allCohorts, listChange, isLoading]);
  return (
    <StaticDashContext.Provider
      value={{
        CohortNames,
        setcnm,
        cnm,
        setListChange,
        StaticUrl,
        setStaticUrl,
        GlobalFilters,
        setGlobalFilters,
      }}
    >
      {children}
    </StaticDashContext.Provider>
  );
};
