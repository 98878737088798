import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { FC } from "react";
import { IHistoryDetails } from "../../../../services/modules/admin/getHistory";
import StyledDataGrid from "../../../components/Datagrid/StyledDatagrid";

interface HistoryTableProps {
  items: IHistoryDetails[];
}

const HistoryTable: FC<HistoryTableProps> = ({ items }) => {
  const columns = React.useMemo<GridColDef<IHistoryDetails>[]>(() => {
    return [
      {
        field: "Action_Type",
        headerName: "Action Type",
        minWidth: 200,
      },
      {
        field: "Description",
        headerName: "Description",
        minWidth: 200,
      },
      {
        field: "Owner",
        headerName: "User",
        minWidth: 200,
      },
      {
        field: "id",
        headerName: "Date",
        minWidth: 200,
        renderCell: (params) =>
          dayjs(params.row.id).format("DD-MM-YYYY hh:mm a"),
      },
    ];
  }, []);

  return (
    <div style={{ height: 450, width: "100%", marginTop: "20px" }}>
      <StyledDataGrid
        rows={items}
        sx={{
          backgroundColor: "white",
        }}
        //@ts-expect-error
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default HistoryTable;
