import { Box, Button, Tooltip } from "@mui/material";
import React from "react";

const DisplayButtons = (props) => {
  return (
    <>
      {props?.orientation === 0 ||
      props?.orientation === props?.openOrientation ? (
        <Button
          disabled={props?.disable}
          onClick={() => {
            props?.onClickBtn();
          }}
          variant={props?.variant === "contained" ? "contained" : "outlined"}
          sx={{
            maxHeight: "auto",
            minHeight: "1.771vh",
            minWidth: "fit-content",
            px: "1vw",
          //  fontSize: "0.833vw",
            borderTopLeftRadius: props?.position === "start" ? "0.7vh" : "0",
            borderBottomLeftRadius: props?.position === "start" ? "0.7vh" : "0",
            borderTopRightRadius: props?.position === "end" ? "0.7vh" : "0",
            borderBottomRightRadius: props?.position === "end" ? "0.7vh" : "0",
            ml: "-1vw",
          }}
          startIcon={props?.logo ?? <></>}
        >
          {props?.BtnTxt}
        </Button>
      ) : (
        <Tooltip title={props?.toolTipText} placement="bottom">
          <span>
            <Button
              disabled={props?.disable}
              onClick={() => {
                props?.onClickBtn();
              }}
              variant="outlined"
              sx={{
                maxHeight: "1.771vw",
                minHeight: "1.771vw",
                fontSize: "1.5vw",
                minWidth: "auto",
                px: "1.5vw",

                borderTopLeftRadius:
                  props?.position === "start" ? "0.7vh" : "0",
                borderBottomLeftRadius:
                  props?.position === "start" ? "0.7vh" : "0",
                borderTopRightRadius: props?.position === "end" ? "0.7vh" : "0",
                borderBottomRightRadius:
                  props?.position === "end" ? "0.7vh" : "0",
                ml: "-1vw",
              }}
            >
              {props?.logo}
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default DisplayButtons;
