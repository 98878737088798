import { ArrowDropDown } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Box,
  Divider,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import { useOktaAuth } from "@okta/okta-react";
import { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyGetMenuQuery } from "../../services/modules/apgee";
import { RootState } from "../../store/configureStore";
import {
  resetCohortData,
  setCohortType,
  setComparableFilters,
  setFilterSaved,
  setPatientCount,
} from "../../store/reducers/CohortReducer";
import HTMLToCanvasIgnore from "../components/Common/HtmlToCanvasIgnore";
import AlertDialog from "../components/Dialogs/AlertDialog";
import CONSTANTS from "../constants";
import { ProcessedMenuItem, processMenuItems, isValidHttp } from "./menuData";
import startCase from "lodash.startcase";
import UserDetails from "./UserDetails";

export const APP_BAR_HEIGHT_MOBILE = 90;
export const APP_BAR_HEIGHT_DESKTOP = 90;
export const DRAWER_WIDTH = 450;

export const ToolBarStyled = styled(Toolbar)(() => ({
  backgroundColor: "white",
  color: "black",
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #EAEBEA",
  boxShadow: "0px 2px 24px #0000001F",
  padding: 0,
}));

export default function DashboardNavbar({
  children,
}: {
  children: JSX.Element | React.ReactNode;
}) {
  const nav = useNavigate();
  const { filterSaved } = useSelector(
    (state: RootState) => state.CohortReducer
  );
  const { oktaAuth } = useOktaAuth();

  const [showUnsavedDialog, setShowUnsavedDialog] = useState<
    boolean | undefined
  >();
  const [prevEvent, setPrevEvent] = useState("");
  const dispatch = useDispatch();
  const [showCompareDiscardDialog, setShowCompareDiscardDialog] =
    useState(false);
  const [isCombine, setCombine] = useState(false);
  const { userName, userInfo } = useSelector(
    (state: RootState) => state.LoginReducer
  );
  const [menuItems, SetMenuItems] = useState<ProcessedMenuItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<Record<number, any>>({});
  const [getMenuData, { data }] = useLazyGetMenuQuery();

  useEffect(() => {
    if (userName) {
      // fetch Menu Data
      getMenuData({
        userId: userInfo?.preferred_username ?? "",
      });
    }
  }, [userName]);

  useEffect(() => {

    if (data) {
      const processData = processMenuItems(data);
      SetMenuItems(processData);
    }
  }, [data]);
  const redirectHandle = (arg: string) => {
    if (isValidHttp(arg)) {
      window.location.href = arg;
    } else {
      nav(arg);
    }
  };

  const handleButtonClick = () => {
    setShowUnsavedDialog(false);
    setCombine(false);
    setShowCompareDiscardDialog(false);
    redirectHandle(prevEvent);
    dispatch(setFilterSaved(undefined));
    dispatch(
      setPatientCount({
        patientCount: 0,
      })
    );
    dispatch(setCohortType(""));
    dispatch(setComparableFilters([]));
    dispatch(resetCohortData());
  };

  const handleClose = () => {
    setShowUnsavedDialog(false);
    setShowCompareDiscardDialog(false);
    setPrevEvent("");
  };

  const handleLogout = () => {
    oktaAuth.signOut({
      postLogoutRedirectUri:
        window.__env.REACT_APP_AUTH_REDIRECT_URL ||
        `${window.location.origin}/logout`,
    });
  };

  const handleOpenMenu = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    groupId: number
  ) => {
    setAnchorEl((prev) => ({
      ...prev,
      [groupId]: e.currentTarget,
    }));
  };

  const handleCloseMenu = (groupId: number) => {
    setAnchorEl((prev) => ({
      ...prev,
      [groupId]: null,
    }));
  };

  const navigateTo = (
    url: string,
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    if (filterSaved === false) {
      setShowUnsavedDialog(true);
      e.preventDefault();
      setPrevEvent(url);

      return;
    }

    redirectHandle(url);
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        position: "absolute",
        backgroundColor: "white",
        p: 0,
      }}
    >
      <Divider sx={{ backgroundColor: "#E41F35", height: "7px" }} />
      <ToolBarStyled sx={{ padding: 0, zIndex: 2000 }}>
        <Box
          sx={{
            display: "flex",

            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"row"} spacing={1} alignItems="center">
            <img src="logo-dark.jpeg" height="64px" alt="Logo"></img>
            <Typography
              sx={{ fontSize: "1.354vw" }}
              variant="h1"
              data-testid="app-title"
            >
              {CONSTANTS.APP_TITLE}
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {menuItems?.map((group) => (
              <Box
                key={group.name}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => handleOpenMenu(e, group.id)}
                  endIcon={<ArrowDropDown />}
                  color="inherit"
                  data-testid="menu-item"
                >
                  {group.name}
                </Button>
                <Menu
                  anchorEl={anchorEl[group.id]}
                  open={Boolean(anchorEl[group.id])}
                  onClose={() => handleCloseMenu(group.id)}
                >
                  <Stack spacing={2} p={2}>
                  {group.subItems.map((item) =>
                      !item.isSubModuleDisabled  ? (
                        <Link
                          key={item.id}
                          onClick={(e) => {
                            handleCloseMenu(group.id);
                            if (item.isExternal) {
                              window.open(item.url, "_blank");
                              return;
                            }
                            navigateTo(item.url, e);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: "inherit",
                            textDecoration: "none",
                            "&:hover": {
                              color: "error",
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {item.name}
                        </Link>
                      ) : null
                    )}
                  </Stack>
                </Menu>
              </Box>
            ))}
          </Box>
          <HTMLToCanvasIgnore>
            <Stack
              direction={"row"}
              gap={2}
              alignItems={"center"}
              color={"primary"}
              sx={{ ml: "auto" }}
            >
              <Typography variant="h4">
                {startCase(userInfo?.preferred_username ?? "")}
              </Typography>
              <Stack
                direction={"row"}
                gap={0.5}
                sx={{ m: 1, alignItems: "center", cursor: "pointer" }}
                onClick={() => {
                  handleLogout();
                }}
              >
                <LogoutIcon fontSize="small" />
                <Typography variant="h4">Logout</Typography>
              </Stack>
              <UserDetails />
            </Stack>
          </HTMLToCanvasIgnore>
        </Box>
      </ToolBarStyled>
      {children}
      {showUnsavedDialog && (
        <AlertDialog
          title={"Unsaved Changes"}
          buttonName={"Discard"}
          description={
            "You have unsaved changes. Changes will be discarded if you proceed. Do you want to Discard?"
          }
          onButtonClick={handleButtonClick}
          onClose={handleClose}
        />
      )}
      {showCompareDiscardDialog && (
        <AlertDialog
          title={isCombine ? "Combine View" : "Compare View"}
          buttonName={"Discard"}
          description={
            isCombine
              ? "Your combined view will be discarded if you proceed. Do you want to Discard?"
              : "Your compared view will be discarded if you proceed. Do you want to Discard?"
          }
          onButtonClick={handleButtonClick}
          onClose={handleClose}
        />
      )}
    </AppBar>
  );
}
