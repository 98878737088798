import { Box, BoxProps } from "@mui/material";

interface PageContainerProps extends BoxProps {
  pageTitle?: string;
}

function PageContainer({ children, sx, ...rest }: PageContainerProps) {
  return (
    <Box
      sx={(theme) => {
        return {
          px: "2vw",
          mt: "0vh",
          mb: "1vh",
          [theme.breakpoints.down("md")]: {
            px: "20px",
          },
        };
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default PageContainer;
