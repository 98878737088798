import {ReactComponent as unionIcon} from "./ic_union.svg";
import {ReactComponent as subIcon} from "./ic_subtraction.svg";
import {ReactComponent as intersectIcon} from "./ic_intersection.svg";
import {ReactComponent as uniqueIcon} from "./ic_unique.svg";
import {ReactComponent as copyIcon} from "./ic_copy.svg";
import {ReactComponent as chatIcon} from "./ic_ai_animated_white.svg";


 const images =  {
    'ic_union': unionIcon,
    'ic_subtraction': subIcon,
    'ic_intersection': intersectIcon,
    'ic_unique': uniqueIcon,
    'ic_copy':copyIcon,
    'ic_ai_animated':chatIcon
};


export default images;
   