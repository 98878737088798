import { EndpointBuilder } from "@reduxjs/toolkit/query";
import config from "../../../app/config";

const getAppToken = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<
    any,
    {
      username: string;
      password: string;
    }
  >({
    query: ({ username, password }) => {
      return {
        url: config.apigee.apiAuthUrl,
        method: "POST",
        headers: {
          authorization: "Basic " + btoa(username + ":" + password),
        },
      };
    },
  });

export default getAppToken;