import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { getFormData } from "../../serviceUtils";
import { UserFormData } from "./modifyUser";

const deleteUser = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, Omit<UserFormData, "isnew">>({
    query: (payload) => {
      const body = getFormData(payload);
      return {
        url: "/delete_user",
        method: "POST",
        body,
        formData: true,
      };
    },
  });

export default deleteUser;
