import { Box, Card, Chip, Stack, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { IGroupDetails } from "../../../services/modules/admin/getGroups";

interface GroupCardProps {
  item: IGroupDetails;
  index: number;
}

const GroupCard: FC<GroupCardProps> = ({ item, index }) => {
  const theme = useTheme();

  const chipColors = [
    theme.palette.common.success,
    theme.palette.common.warning,
    theme.palette.common.info,
  ];

  return (
    <Card
      sx={{
        padding: "15px",
        boxShadow: "0px 3px 10px #0000001A",
        border: "1px solid #cacaca",
        minWidth: "400px",
        marginRight: 2,
      }}
    >
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <Box
          sx={{
            height: "60px",
            width: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `0.5px solid ${theme.palette.divider}`,
          }}
        >
          <PersonIcon />
        </Box>
        <Stack>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {item.id}
          </Typography>
          <Typography component="p">{item.Description}</Typography>
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack spacing={1} mt={3}>
          <Typography variant="h2">{item?.users?.length}</Typography>
          <Typography>Total Members</Typography>
        </Stack>
        <Stack direction="column" alignItems={"flex-end"}>
          <Stack spacing={1} mt={3} direction={"row"} alignItems={"center"}>
            <PersonIcon fontSize="small" />
            <Typography>
              {item.Admin?.map((t) => t.first_name + " " + t.last_name).join(
                ", "
              )}
            </Typography>
          </Stack>
          <Chip
            size="small"
            label={item.PracticeTeam}
            sx={{
              backgroundColor: chipColors[index % 3],
              fontSize: "14px",
              mt: 1,
            }}
          ></Chip>
        </Stack>
      </Stack>
    </Card>
  );
};

export default GroupCard;
