import React, { FC } from "react";
import GroupDetails from "./GroupDetails";
import PageContainer from "../../components/PageContainer/PageContainer";
import MemberDetails from "./MemberDetails";

interface AdminProps {}

const Admin: FC<AdminProps> = () => {
  return (
    <PageContainer>
      <GroupDetails />
      <MemberDetails />
    </PageContainer>
  );
};

export default Admin;
