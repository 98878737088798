import { PaletteOptions } from "@mui/material";

const palette: PaletteOptions = {
  primary: {
    main: "#3F7D98",
  },
  secondary: {
    main: "#e6f5f7",
  },
  common: {
    brandBackground: "#E6F5F7",
    bg1: "#C3C3C3",
    tableHead: "#E2E1E1",
    tableRow: "#F7F7F7",
    tableSelectedRow: "#E6F5F7",
    tableDivider: "#C3C3C3",
    divider2: "#888888",
    hover: "#005c78",
    disabled: "#b3cbd6",
    //snackbar
    success: "#E2F0D7",
    warning: "#FFEDC4",
    error: "#FCE3E5",
    info: "#E6F5F7",
  },
  text: {
    primary: "#27251F",
  },
  divider: "#C3C3C3",
};

export default palette;
