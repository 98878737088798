import { EndpointBuilder } from "@reduxjs/toolkit/query";

const getPatientCount = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, any>({
    query: (payload) => ({
      url: "/get_count",
      method: "POST",
      body: payload,
    }),
  });

export default getPatientCount;
