import { EndpointBuilder } from "@reduxjs/toolkit/query"

const getAllCohortsData = (build: EndpointBuilder<any, any, any>) => build.query<any, string>({

    query: (userid) => {
        return {
            url: "/get_cohort_list",
            method: 'GET',
            params: { user_id: userid.replace("@","#") }
        };
    }
})

export default getAllCohortsData;