import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import PatientProfiling from "./PatientProfiling";
import { useLocation } from "react-router-dom";
import useDebounce from "../../hooks/useDebounceCallBack";
import { UseStaticDash } from "../../Context/StaticDashboardsContext";

const StaticDashboardsWrapper = () => {
  const location = useLocation();
  const { pathname } = location || {};
  const { cnm, CohortNames, setStaticUrl, GlobalFilters } = UseStaticDash();
  const [cohortAll, setAll] = useState({});
  const debouncedValue = useDebounce({cnm, CohortNames, setStaticUrl, GlobalFilters}, 5000);

  useEffect(() => {
    if (CohortNames && Object.keys(CohortNames).length > 0) {
      setAll(CohortNames);
    }
  }, [CohortNames]);

  return (
    <Box
      sx={{
        minHeight: "75vh",
        maxHeight: "75vh",
        width: "100%",
        mx: "0",
        // border: "1px solid red",
        mt: "0vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {pathname === "/population-profiling" && (
        <PatientProfiling
          cnm={debouncedValue?.cnm}
          cohortAll={cohortAll}
          setStaticUrl={debouncedValue?.setStaticUrl}
          GlobalFilters={debouncedValue?.GlobalFilters}
          SDNO={0}
        />
      )}
      {pathname === "/diagnosis-and-disease-staging" && (
        <PatientProfiling
          cnm={debouncedValue?.cnm}
          cohortAll={cohortAll}
          setStaticUrl={debouncedValue?.setStaticUrl}
          GlobalFilters={debouncedValue?.GlobalFilters}
          SDNO={1}
        />
      )}
      {pathname === "/therapy-pathways" && (
        <PatientProfiling
          cnm={debouncedValue?.cnm}
          cohortAll={cohortAll}
          setStaticUrl={debouncedValue?.setStaticUrl}
          GlobalFilters={debouncedValue?.GlobalFilters}
          SDNO={2}
        />
      )}
    </Box>
  );
};

export default StaticDashboardsWrapper;
