import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { getFormData } from "../../serviceUtils";

export type IPatientSummary = {
  summary: string;
  data: Record<string, string>[];
};

const getPatientData = (build: EndpointBuilder<any, any, any>) =>
  build.query<IPatientSummary, { patient_id: string; table_name: string }>({
    query: (payload) => {
      const body = getFormData(payload);
      return {
        url: "/patient_view_sumry",
        method: "POST",
        body,
        formData: true,
      };
    },
    // transformResponse: (resp: IPatientSummary) => {
    //   const items = resp.data;

    //   const item = [{}];

    //   items.forEach((i) => {
    //     Object.keys(i).forEach((j) => {
    //       // @ts-ignore
    //       if (!item[0][j]) {
    //         // @ts-ignore
    //         item[0][j] = i[j];
    //       } else {
    //         //make it an array of items
    //         // @ts-ignore
    //         if (Array.isArray(item[0][j])) {
    //           // @ts-ignore
    //           item[0][j].push(i[j]);
    //         } else {
    //           // @ts-ignore
    //           item[0][j] = [item[0][j], i[j]];
    //         }
    //       }
    //     });
    //   });
    //   return {
    //     ...resp,
    //     data: item,
    //   };
    // },
  });

export default getPatientData;
