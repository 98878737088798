import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { api } from "../services";
import LoginReducer from "./reducers/LoginReducer";
import CohortReducer from "./reducers/CohortReducer";
import {
  TypedUseSelectorHook,
  useSelector as rawUseSelector,
} from "react-redux";
import { apgeeApi } from "../services/apgeeService";

const createReducer = () => {
  return combineReducers({
    // app data reducers
    LoginReducer,
    CohortReducer,
    // App service related data
    [api.reducerPath]: api.reducer,
    [apgeeApi.reducerPath]:apgeeApi.reducer
  });
};

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(api.middleware).concat(apgeeApi.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
