import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import config from "../app/config";
import { RootState } from "../store/configureStore";

const baseQuery = fetchBaseQuery({
  baseUrl: config.apigee.apiGeeEndPoint,
  prepareHeaders: async (headers, { getState }) => {
    const apToken = (getState() as RootState).LoginReducer.apToken;

    if (apToken) {
      headers.set("Authorization", `Bearer ${apToken}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // auth cases
  if (result.error && result.error.status === 401) {
    // Refresh token or logout token logic etc
  }
  return result;
};

export const apgeeApi = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
  reducerPath:"apgeeApi"
});
