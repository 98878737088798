import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { FC } from "react";
import { IGroupDetails } from "../../../../services/modules/admin/getGroups";
import ActionMenu from "../../../components/Common/ActionMenu";
import StyledDataGrid from "../../../components/Datagrid/StyledDatagrid";

interface GroupTableProps {
  groups: IGroupDetails[];
  isLoading: boolean;
  onEdit: (item: IGroupDetails, index?: number) => void;
  onDelete: (item: IGroupDetails) => void;
}

const GroupTable: FC<GroupTableProps> = ({
  groups,
  isLoading,
  onEdit,
  onDelete,
}) => {
  const columns = React.useMemo<GridColDef<IGroupDetails>[]>(() => {
    return [
      {
        field: "id",
        headerName: "Group Name",
        minWidth: 200,
      },
      {
        field: "Description",
        headerName: "Description",
        minWidth: 200,
      },
      {
        field: "Admin",
        headerName: "Admin",
        minWidth: 200,
        renderCell: (params) => {
          return params.row.Admin?.map(
            (a) => a.first_name + " " + a.last_name
          ).join(", ");
        },
      },
      {
        field: "totalMembers",
        headerName: "Total Members",
        minWidth: 200,
        renderCell: (params) => {
          return params.row.users?.length ?? 0;
        },
      },
      {
        field: "PracticeTeam",
        headerName: "Practice",
        minWidth: 200,
      },
      {
        field: "Role",
        headerName: "Role",
        minWidth: 200,
      },
      {
        field: "Last_Modified",
        headerName: "Last Updated",
        width: 220,
        renderCell: (parmas) => {
          return dayjs(parmas.row.Last_Modified).format("DD-MM-YYYY hh:mm a");
        },
      },
      {
        field: " ",
        width: 100,
        flex: 1,
        renderCell: (params) => (
          <Grid container alignItems={"center"} justifyContent={"flex-end"}>
            <ActionMenu
              items={[
                {
                  label: "Edit",
                  onClick: () => {
                    onEdit(params.row);
                  },
                },
                {
                  label: "Delete",
                  disabled: params.row.id === "CAH Admin Team",
                  onClick: () => {
                    onDelete(params.row);
                  },
                },
              ]}
            />
          </Grid>
        ),
      },
    ];
  }, []);

  return (
    <div style={{ height: 450, width: "100%", marginTop: "20px" }}>
      <StyledDataGrid
        rows={groups}
        sx={{
          backgroundColor: "white",
        }}
        loading={isLoading}
        //@ts-expect-error
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default GroupTable;
