import { Box, Link as MUiLink, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StyledTypography = styled(Typography)(() => ({
  color: "#999",
}));

const FooterContainer = styled(Box)(() => ({
  position: "sticky",
  width: "100vw",
  minHeight: "10vh",
  maxHeight: "15vh",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid #333",
  backgroundColor: "#333",
  bottom: 0,
  paddingLeft: "2vw",
  paddingRight: "2vw",
  color: "#999",
}));

const Footer = () => {
  return (
    <footer data-testid="footer">
      <FooterContainer>
        <Link>
          <img src="logo.png" height="50%" alt="Logo"></img>
        </Link>
        <Stack direction="row" gap={2}>
          <StyledTypography variant="body1">
            ©2024 Cardinal Health
          </StyledTypography>
          <StyledTypography variant="body1">|</StyledTypography>
          <MUiLink color="inherit" href="">
            Privacy Policy
          </MUiLink>
          <StyledTypography variant="body1">|</StyledTypography>
          <MUiLink color="inherit">Terms of use</MUiLink>
        </Stack>
      </FooterContainer>
    </footer>
  );
};

export default Footer;
