import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Box, Stack, Tooltip } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FaCheck } from "react-icons/fa6";
export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  "& .Mui-expanded": {
    backgroundColor: theme.palette.common.brandBackground,
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon color="primary" sx={{ fontSize: "0.9rem" }} />
    }
    {...props}
  />
))(() => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  backgroundColor: "transparent",
  "& .Mui-expanded": {
    backgroundColor: "transparent",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = React.useState();
  const [FilterValue, setFilterValue] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box mt={2}>
      {Object.keys(props.staticData)?.map((fl, index) => {
        return (
          <>
            <Accordion
              expanded={expanded === index || props?.searchT}
              onChange={handleChange(index)}
              key={fl}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ border: "1px solid #C3C3C3" }}
              >
                <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                  {fl}
                </Typography>
              </AccordionSummary>
              {Object.keys(props.staticData[fl])
                .sort((a, b) => a.localeCompare(b))
                ?.map((fval) => {
                  return (
                    <>
                      {props.staticData[fl][fval]["ActualTable"] !== null ? (
                        <AccordionDetails key={fval}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Tooltip
                              title={props.staticData[fl][fval]["description"]}
                              disableInteractive
                            >
                              <Typography
                                onClick={() => {
                                  setFilterValue(fval);
                                  props.getVal(index, fval);
                                }}
                                sx={{
                                  fontWeight:
                                    props?.selectedVal === fval ? "bold" : null,
                                  cursor: "pointer",
                                  ml: "1vw",
                                }}
                                variant="h4"
                              >
                                {fval}
                              </Typography>
                            </Tooltip>
                            {props?.selectedVal === fval && (
                              <FaCheck
                                color="primary"
                                style={{ cursor: "pointer", fontSize: "1vw" }}
                              />
                            )}
                          </Stack>
                        </AccordionDetails>
                      ) : null}
                    </>
                  );
                })}
            </Accordion>
          </>
        );
      })}
    </Box>
  );
}
