import {
  Box,
  Link,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { HiMiniUserGroup } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/configureStore";
import React, { useEffect, useState } from "react";
import { useGetPatientCountMutation } from "../../../services/modules/queryBuilder";
import { setPatientCount } from "../../../store/reducers/CohortReducer";

const CountContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ mt: 1 }}>
      <HiMiniUserGroup
        style={{ fontSize: "1.5vw", color: theme.palette.common.divider2 }}
      />
      {children}
    </Stack>
  );
};

const PatientCount = ({
  viewPatientList,
  selectedFilters = {},
  isLoading = false,
  isCompare = false,
  patientCountProps = 0,
}: {
  viewPatientList: any;
  selectedFilters: any;
  isLoading: boolean;
  isCompare: boolean;
  patientCountProps: any;
}) => {
  const [ExportLoading, setExportLoading] = useState(false);
  const [getCount] = useGetPatientCountMutation(); 
  const dispatch = useDispatch();
  const getPatientCount = (selectedFilters: any) => {
    setExportLoading(true);

    getCount({ query: JSON.stringify(selectedFilters) })
      .unwrap()
      .then((result:any) => {
        setExportLoading(false);
        dispatch(
          setPatientCount({
            patientCount: result.filter_count,
            totalCount: result.total_count,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setExportLoading(false);
      });
  };
  const { patientCount, totalCount, isExportable } = useSelector(
    (state: RootState) => state.CohortReducer
  );
  useEffect(() => {
    if (isExportable) {
      getPatientCount(selectedFilters);
    }
  }, [isExportable]);

  if (isLoading || ExportLoading) {
    return (
      <CountContainer>
        <Skeleton height={"40px"} width={"10vw"} />
        <Box sx={{ flexGrow: 1 }} />
      </CountContainer>
    );
  }

  if (isCompare) {
    return (
      <CountContainer>
        <Typography variant="h3">
          {Object.keys(selectedFilters).length > 0 && (
            <>
              <b style={{ cursor: "pointer" }}>
                {patientCountProps.toLocaleString("en-Us")}
              </b>
            </>
          )}{" "}
          out of {totalCount.toLocaleString("en-Us")} Patients
        </Typography>
      </CountContainer>
    );
  }

  const renderPatientString = () => {
    if (Object.keys(selectedFilters).length > 0) {
      if (patientCount > 0) {
        return (
          <>
            <Typography
              variant="h3"
              component={Link}
              onClick={() => {
                viewPatientList();
              }}
              sx={{
                color: "primary",
              }}
            >
              <b style={{ cursor: "pointer" }}>
                {patientCount.toLocaleString("en-Us")}
              </b>
            </Typography>
            <Typography variant="h3">
              {" "}
              out of {totalCount?.toLocaleString("en-Us")} Patients
            </Typography>
          </>
        );
      } else {
        return (
          <>
            <Typography
              component={Link}
              variant="h3"
              onClick={() => {
                viewPatientList();
              }}
            >
              <b style={{ cursor: "pointer" }}>
                {patientCount?.toLocaleString("en-Us")}
              </b>
            </Typography>
            <Typography variant="h3">
              {" "}
              out of {totalCount?.toLocaleString("en-Us")} Patients
            </Typography>
          </>
        );
      }
    } else {
      return (
        <Stack
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{ cursor: "pointer" }}
            component={Link}
            variant="h3"
            sx={{
              color: "primary",
            }}
            onClick={viewPatientList}
          >
            {totalCount.toLocaleString("en-Us")} Patients
          </Typography>
        </Stack>
      );
    }
  };

  return <CountContainer>{renderPatientString()}</CountContainer>;
};

export default PatientCount;
