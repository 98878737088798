export const USER_ROLES = [
  {
    name: "CAH Tech Team",
    description: "System Administrators",
  },
  {
    name: "CAH Product Team",
    description: "Product Owners",
  },
  {
    name: "NN Business Teams",
    description:
      "Business development, Customer support,Finacial team, practice Analytics ",
  },
  {
    name: "NN Clinical Care and Ops",
    description: "Clinical and practice leaders",
  },
  {
    name: "Practice Team",
    description: "Clinical care team ,...",
  },
  {
    name: "I&E team",
    description: "Business Developoment , Live & Digital Engagement",
  },
];

export const HISTORY_ACTIONS = [
  "Create Group",
  "Update Group",
  "Delete Group",
  "Create User",
  "Update User",
  "Delete User",
];
