import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Typography from "@mui/material/Typography";

const AlertDialog = (props: any) => {
  const handleClose = () => {
    props?.onClose();
  };

  const handleButtonClick = () => {
    props?.onButtonClick();
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography variant="h4">{props?.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{props?.description}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
        data-testId="action-modal-btn"
          size="small"
          variant="contained"
          onClick={handleButtonClick}
          autoFocus
        >
          {props?.buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
