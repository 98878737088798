import * as React from "react";
import Drawer from "@mui/material/Drawer";

export default function AppDrawer({
  open,
  toggleDrawer,
  clearModify,
 
  children,
}: {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
  clearModify: () => void;
 
  children: React.ReactNode;
}) {
  return (
    <div>
      <Drawer
        open={open}
        onClose={() => {
          toggleDrawer(false);
          clearModify()
        }}
      >
        {children}
      </Drawer>
    </div>
  );
}
