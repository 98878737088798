import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(
  DataGrid,
  {}
)(({ theme }) => ({
  border: 0,
  backgroundColor: theme.palette.common.white,
  fontSize: "0.729vw",
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    fontWeight: "normal",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-filler": {
    backgroundColor: theme.palette.common.tableHead,
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "0.729vw",
    fontWeight: "bold",
  },
  // @ts-ignore
  [`& .MuiDataGrid-row.odd`]: {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell": {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-row.odd:not(:hover)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default StyledDataGrid;
