import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { getFormData } from "../../serviceUtils";

// "Patient_Name": "9ac2407ac627200b9aa492f830e0e813 9ac2407ac627200b9aa492f830e0e813",
//     "Age": 91,
//     "Language_Preference": "English",
//     "Contact_Preference": null,
//     "Preferred_Phone": null,
//     "Preferred_Mail": null,
//     "Address": null,
//     "Zip": "350",
//     "Provider_ID": "1134447980",
//     "Provider_Name": "Jones MD, Benjamin",
//     "Practice_Name": "Alabama Oncology Grandview",
//     "Practice_City": "Birmingham",
//     "Last_Follow_Up_Date": "NaT",
//     "Last_Follow_Up_Type": null,
//     "Plan_Name": "Unitedhealth Grp",
//     "Plan_Type": "Medicare Advantage",
//     "Member_Id": "PIH_06031681117440581_36",
//     "Plan_Status": "active",
//     "Effective_From": "NaT",
//     "Effective_To": "NaT",
//     "Tumor_Site": "lymphoid, hematopoietic, and related tissue",
//     "Metastatis_Reached": "N",
//     "Comorbidities": null,
//     "Charlson_Comorbidity_Index": null,
//     "Recent_Diagnosis_Date": "2017-04-27",
//     "Recent_Clinical_Disease_Status": "stable",
//     "Last_Visit_Type": null,
//     "Last_Visit_Date": null,
//     "Therapy_Type": null,
//     "Current_Regimen": null,
//     "Line_of_Therapy": null,
//     "Treatment_History": null,
//     "Weight": "Unknown",
//     "Height": "Unknown",
//     "BP": null,
//     "Blood_Sugar": null,
//     "Disease_Progression_Trend": [
//       {
//         "Clinical_Disease_Status_raw": "stable",
//         "Date_of_Diagnosis": "2017-04-27"
//       },
//       {
//         "Clinical_Disease_Status_raw": "active",
//         "Date_of_Diagnosis": "2017-05-16"
//       },
//       {
//         "Clinical_Disease_Status_raw": "none",
//         "Date_of_Diagnosis": "2017-05-17"
//       },
//       {
//         "Clinical_Disease_Status_raw": "none",
//         "Date_of_Diagnosis": "2017-05-22"
//       },
//       {
//         "Clinical_Disease_Status_raw": "active",
//         "Date_of_Diagnosis": "2018-05-10"
//       },
//       {
//         "Clinical_Disease_Status_raw": "none",
//         "Date_of_Diagnosis": "2020-06-18"
//       },
//       {
//         "Clinical_Disease_Status_raw": "none",
//         "Date_of_Diagnosis": "2021-12-02"
//       }
//     ],
//     "Labs": [],
//     "prescribed_medication": [
//       {
//         "Prescribed_Drug_Route": "other / miscellaneous",
//         "Prescribed_Drug_Form": null,
//         "Prescribed_Drug_Brand_Name": null,
//         "Drug_Prescription_Date": null
//       }
//     ],
//     "administered_medication": []

export type IPatientDetails = {
  summary: string;
  data: {
    Patient_Name: string;
    Age: number;
    DOB: string;
    Gender: string;
    Height: number;
    BMI: number;
    Language_Preference: string;
    Contact_Preference: string;
    Preferred_Phone: string;
    Preferred_Mail: string;
    Address: string;
    Zip: string;
    Provider_ID: string;
    Provider_Name: string;
    Practice_Name: string;
    Practice_City: string;
    Last_Follow_Up_Date: string;
    Last_Follow_Up_Type: string;
    Plan_Name: string;
    Plan_Type: string;
    Member_Id: string;
    Plan_Status: string;
    Effective_From: string;
    Effective_To: string;
    Tumor_Site: string;
    Metastatis_Reached: string;
    Comorbidities: string;
    Charlson_Comorbidity_Index: number;
    Recent_Diagnosis_Date: string;
    Recent_Clinical_Disease_Status: string;
    Visit_Type: string;
    Visit_Date: string;
    Treatment_History: string;
    Most_Recent_Follow_up_Type: string;
    Therapy_Type: string;
    Current_Regimen: string;
    Line_of_Therapy: string;
    Diagnosis_Date: string;
    Weight: string;
    BP: string;
    Blood_Sugar: string;
    Disease_Progression_Trend: {
      Clinical_Disease_Status_raw: string;
      Date_of_Diagnosis: string;
    }[];
    Labs: {
      Diagnostic_Test_Date: string;
      Diagnostic_Result_Date: string;
      Diagnostic_Test_Result: string;
      Diagnostic_Test_Lab_Test_Name: string;
    }[];
    prescribed_medication: {
      Prescribed_Drug_Route: string;
      Prescribed_Drug_Form: string;
      Prescribed_Drug_Brand_Name: string;
      Drug_Prescription_Date: string;
    }[];
    administered_medication: {
      Drug_Dose_Administered: string;
      Drug_Administration_Date: string;
      Drug_Form_Administered: string;
      Drug_Administered_Brand_Name: string;
    }[];
  };
};

const getPatientDetails = (build: EndpointBuilder<any, any, any>) =>
  build.query<IPatientDetails, { patient_id: string }>({
    query: (payload) => {
      const body = getFormData(payload);
      return {
        url: "/patient_view_sumry_new",
        method: "POST",
        body,
        formData: true,
      };
    },
  });

export default getPatientDetails;
