import React, { useEffect, useState } from "react";
import PatientTable from "./PatientTable";

import { GridPaginationModel } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import {
  useGetFilterDataQuery,
  useLazyGetPatientListQuery,
} from "../../../../services/modules/queryBuilder";
import { useSelector } from "../../../../store/configureStore";

interface PatientListProps {
  onPatientListDismiss: () => any;
  selectedFilters: any;
  onApplyColumns: (columns: any) => any;
  columns: any;
}

const PatientList = ({
  onPatientListDismiss,
  selectedFilters,
  onApplyColumns,
  columns,
}: PatientListProps) => {
  const [appliedColumns, setAppliedColumns] = useState(columns);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const { data } = useGetFilterDataQuery();

  const [staticData, setStaticData] = useState([]);
  const { cohortType, ComparableFilters, combineLogic } = useSelector(
    (state) => state.CohortReducer
  );

  useEffect(() => {
    if (data) {
      setStaticData(data);
    }
  }, [data]);

  // Api
  const [getPatients, { data: patientList, isError, isLoading, isFetching }] =
    useLazyGetPatientListQuery();

  const applyColumnFilters = (
    columns: any = {},
    pageData?: GridPaginationModel
  ) => {
    onApplyColumns(columns);
    setAppliedColumns(columns);
    const fData = new FormData();
    if (cohortType === "combine") {
      fData.append(
        "filter2",
        JSON.stringify({
          query: ComparableFilters[1]["Filters"],
          columns: columns,
          limit: pageData?.pageSize ?? paginationModel.pageSize,
          pages: pageData?.page ?? paginationModel.page,
        })
      );
      fData.append(
        "filter1",
        JSON.stringify({
          query: ComparableFilters[0]["Filters"],
          columns: columns,
          limit: pageData?.pageSize ?? paginationModel.pageSize,
          pages: pageData?.page ?? paginationModel.page,
        })
      );
      fData.append("operation", combineLogic);
    }
    let pload =
      cohortType === "combine"
        ? fData
        : {
            query: JSON.stringify(selectedFilters),
            columns: columns,
            limit: pageData?.pageSize ?? paginationModel.pageSize,
            pages: pageData?.page ?? paginationModel.page,
          };
    getPatients(pload).catch(() => {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    });
  };

  const onRetry = () => {
    applyColumnFilters(appliedColumns, paginationModel);
  };

  const onPageUpdated = (pageData: GridPaginationModel) => {
    setPaginationModel(pageData);
    applyColumnFilters(appliedColumns, pageData);
  };

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
    applyColumnFilters(appliedColumns, {
      page: 0,
      pageSize: 100,
    });
  }, [selectedFilters]);

  if (isError) {
    return (
      <PatientTable
        data={[]}
        staticData={staticData}
        isError={isError}
        onRetry={onRetry}
        totalRowCount={0}
        onPageUpdated={onPageUpdated}
        isLoading={isLoading || isFetching}
        onPatientListDismiss={onPatientListDismiss}
        applyColumnFilters={applyColumnFilters}
        filters={selectedFilters}
        appliedColumns={appliedColumns}
      />
    );
  }

  return (
    <PatientTable
      data={patientList?.data ?? []}
      staticData={staticData}
      isError={isError}
      onRetry={onRetry}
      totalRowCount={patientList?.total_pages ?? 1}
      onPageUpdated={onPageUpdated}
      isLoading={isLoading || isFetching}
      onPatientListDismiss={onPatientListDismiss}
      applyColumnFilters={applyColumnFilters}
      filters={selectedFilters}
      appliedColumns={appliedColumns}
    />
  );
};

export default PatientList;
