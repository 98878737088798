import { Stack, Typography } from "@mui/material";
function ToolFeedBack() {
  return (
    <Stack
      direction="row"
      sx={{
        mb: "3vh",
        mx: "0vw",
        display: "flex",
        alignItems: "flex-end",
        mt: "-5vh",
      }}
    >
      <img
        src="ask-question-outline.svg"
        style={{
          marginRight: 0,
          height: "3vh",
          width: "3vw",
          marginLeft: "5vw",
        }}
        height="100%"
        width="100%"
        alt="aLOGO"
      />
      <Typography
        sx={{
          color: "primary.main",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        variant="body1"
      >
        Give Feedback
      </Typography>
      <img style={{ marginLeft: "auto" }} src="/Doctor.svg" alt="Logo" />
    </Stack>
  );
}

export default ToolFeedBack;
