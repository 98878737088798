import {
  Box,
  Button,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteUserMutation,
  useGetGroupListNamesQuery,
  useGetPracticesQuery,
  useLazyGetUsersAllQuery,
  useModifyUserMutation,
} from "../../../../services/modules/admin";
import { IUserDetails } from "../../../../services/modules/admin/getUsersAll";
import { UserFormData } from "../../../../services/modules/admin/modifyUser";
import { RootState } from "../../../../store/configureStore";
import TableLoading from "../../../components/Common/TableLoading";
import PageContainer from "../../../components/PageContainer/PageContainer";
import SearchBar from "../../../components/Search/SearchBar";
import AddUser from "../Components/AddUser";
import UserTable from "../UserTable";

interface UsersProps {
  isDisplay?: boolean;
}

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
const Users: FC<UsersProps> = ({ isDisplay }) => {
  const [open, setOpen] = useState(false);
  const { data: practices = [] } = useGetPracticesQuery();

  const [users, setUsers] = useState<IUserDetails[]>([]);

  const [getUsers, { isLoading, data: usersRaw = [] }] =
    useLazyGetUsersAllQuery();
  const { data: groupNames = [] } = useGetGroupListNamesQuery();
  const [saveUser] = useModifyUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [filters, setFilters] = useState({
    search: "",
    group: "",
    practice: "",
  });
  const [editData, setEditData] = useState<IUserDetails | null>(null);
  const { userName: currentUser } = useSelector(
    (state: RootState) => state.LoginReducer
  );

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setUsers(usersRaw);
  }, [usersRaw]);

  const onFilter = () => {
    let filterData = [...usersRaw];
    // search filter
    if (filters.search) {
      const searchRegex = new RegExp(escapeRegExp(filters.search), "i");
      filterData = filterData?.filter((row: any) => {
        return Object.keys(row).some((field: any) => {
          return searchRegex.test(row[field]?.toString());
        });
      });
    }
    // practice filter
    if (filters.practice) {
      filterData = filterData.filter((row: IUserDetails) => {
        return row.practice_team === filters.practice;
      });
    }
    // role filter
    if (filters.group) {
      filterData = filterData.filter((row: IUserDetails) => {
        return row.group_name === filters.group;
      });
    }
    setUsers(filterData);
  };

  useEffect(() => {
    // clear edit data when form closes
    if (!open && editData) {
      setEditData(null);
    }
  }, [open]);

  useEffect(() => {
    onFilter();
  }, [filters.search, filters.practice, filters.group]);

  const onAddUser = async (a: UserFormData) => {
    const result = await saveUser(a);
    if ("error" in result && result) {
      enqueueSnackbar("User Creation failed ", {
        variant: "error",
      });
    } else {
      const { data } = result;
      if (data.code === 200) {
        setOpen(false);
        getUsers();
      }
      enqueueSnackbar(data.msg, {
        variant: data.code > 200 ? "error" : "success",
      });
    }
  };

  const onDeleteUser = async (a: IUserDetails) => {
    const result = await deleteUser({
      group_name: a.group_name,
      email: currentUser,
      user_lst: [
        { first_name: a.first_name, last_name: a.last_name, email: a.email },
      ],
    });
    if ("error" in result && result) {
      enqueueSnackbar("User deletion failed ", {
        variant: "error",
      });
    } else {
      const { data } = result;
      if (data.code === 200) {
        setOpen(false);
        getUsers();
      }
      enqueueSnackbar(data.msg, {
        variant: data.code > 200 ? "error" : "success",
      });
    }
  };

  const Container = isDisplay ? React.Fragment : PageContainer;

  return (
    <Container>
      {!isDisplay && (
        <Typography variant="h2">View All Member Details</Typography>
      )}
      {/* Header */}
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid direction={"row"} gap={2} mt={2} container>
          <FormGroup>
            <FormLabel>Search</FormLabel>
            <SearchBar
              containerSX={{
                minWidth: "50vh",
                mt: 0,
              }}
              onSearch={(t) => {
                setFilters({
                  ...filters,
                  search: t,
                });
              }}
              placeholder="Search Users"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Group Name</FormLabel>
            <Select
              sx={{ height: "36px", minWidth: "30vh" }}
              onChange={(v) => {
                setFilters({
                  ...filters,
                  group: v.target.value as string,
                });
              }}
              inputProps={{ "data-testid": "group-select" }}
            >
              {groupNames.map((g) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <FormLabel>Practice</FormLabel>
            <Select
              sx={{ height: "36px", minWidth: "30vh" }}
              onChange={(v) => {
                setFilters({
                  ...filters,
                  practice: v.target.value as string,
                });
              }}
              inputProps={{ "data-testid": "practice-select" }}
            >
              {practices.map((g) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <Button
            sx={{ mt: 2 }}
            onClick={() => {
              setFilters({
                search: "",
                group: "",
                practice: "",
              });
              setUsers(usersRaw);
            }}
          >
            Clear filters
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          {!isDisplay && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
                name="add-user"
              >
                Add User
              </Button>
              {/* <Button variant="outlined">Delete</Button> */}
            </>
          )}
        </Grid>
      </Grid>
      {/* Body */}
      {isLoading ? (
        <TableLoading />
      ) : (
        <UserTable
          users={users}
          onEdit={function (a: IUserDetails): void {
            setEditData(a);
            setOpen(true);
          }}
          onDelete={onDeleteUser}
          isLoading={isLoading}
          isDisplay={isDisplay}
        />
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            border: "1px solid #EAEBEA",
            margin: "1.5vw",
            minWidth: "30vw",
            borderRadius: "6px",
            padding: 2,
          }}
        >
          <AddUser
            groupNames={groupNames}
            onAddUser={onAddUser}
            editData={editData}
          />
        </Box>
      </Dialog>
    </Container>
  );
};

export default Users;
