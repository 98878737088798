import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { CiShare2 } from "react-icons/ci";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";
import { useSelector } from "../../../store/configureStore";
import images from "../../assets/Images";

const OptionsButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userName } = useSelector((state) => state.LoginReducer);
  const open = Boolean(anchorEl);
  const CopyIcon = images.ic_copy;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    props?.onDelete(props?.row.CohortName);
  };

  const handleMenuClick = (option) => {
    setAnchorEl(null);
    props?.onMenuClick(props?.row, option);
  };

  const handleShareClick = (option) => {
    setAnchorEl(null);
    props?.handleShare(props?.row.CohortName);
  };

  const handleRename = () => {
    setAnchorEl(null);
    props?.onRename(props?.row.CohortName);
  };

  return (
    <div>
      <Button
        disableRipple
        disableElevation
        disableFocusRipple
        disableTouchRipple
        sx={{
          maxWidth: "fit-content",
          color: "primary.text",
          "&:hover": { background: "transparent" },
          padding: "0",
          fontSize: "1.3vw",
        }}
        id="basic-button"
        aria-label="menu-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <SlOptionsVertical />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          boxShadow: "none",
          ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
            minWidth: "12vw",
            boxShadow: "0px 4px 8px #00000029",
            border: "1px solid #EAEBEA",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          sx={{
            borderBottom: "1px solid #EAEBEA",
            fontSize: "0.729vw",
          }}
          onClick={() => {
            handleMenuClick("Export");
          }}
        >
          <FaRegShareFromSquare style={{ marginRight: "1vw" }} /> Download Cohort
        </MenuItem>
        <MenuItem
          sx={{
            borderBottom: "1px solid #EAEBEA",
            fontSize: "0.729vw",
          }}
          onClick={() => {
            handleShareClick();
          }}
        >
          <CiShare2 style={{ marginRight: "1vw" }} /> Share
        </MenuItem>
        <MenuItem
          sx={{ borderBottom: "1px solid #EAEBEA", fontSize: "0.729vw" }}
          onClick={() => {
            handleDelete();
          }}
        >
          <RiDeleteBin6Line style={{ marginRight: "1vw" }} /> Delete
        </MenuItem>
        <MenuItem
          sx={{ borderBottom: "1px solid #EAEBEA", fontSize: "0.729vw" }}
          onClick={() => {
            handleMenuClick("Copy");
          }}
        >
          <CopyIcon
            style={{ marginRight: "1vw", width: "14px", height: "14px" }}
          />
          Create copy
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "0.729vw" }}
          onClick={() => {
            handleRename();
          }}
        >
          <DriveFileRenameOutlineOutlinedIcon
            style={{ marginRight: "1vw", fontSize: "1vw" }}
          />
          Rename
        </MenuItem>
      </Menu>
    </div>
  );
};

export default OptionsButton;
