import { api } from "../..";
import getRoles from "./getRoles";
import getGroups from "./getGroups";
import modifyGroup from "./modifyGroup";
import getHistory from "./getHistory";
import deleteGroup from "./deleteGroup";
import getUsersAll from "./getUsersAll";
import getGroupListNames from "./getGroupListNames";
import modifyUser from "./modifyUser";
import deleteUser from "./deleteUser";
import getPractices from "./getPractices";

export const adminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoles: getRoles(build),
    getGroups: getGroups(build),
    modifyGroup: modifyGroup(build),
    getHistory: getHistory(build),
    deleteGroup: deleteGroup(build),
    getUsersAll: getUsersAll(build),
    getGroupListNames: getGroupListNames(build),
    modifyUser: modifyUser(build),
    deleteUser: deleteUser(build),
    getPractices: getPractices(build),
  }),
});

export const {
  useGetRolesQuery,
  useLazyGetGroupsQuery,
  useModifyGroupMutation,
  useLazyGetHistoryQuery,
  useDeleteGroupMutation,
  useLazyGetUsersAllQuery,
  useGetGroupListNamesQuery,
  useModifyUserMutation,
  useDeleteUserMutation,
  useGetPracticesQuery,
} = adminApi;
