export const ADMIN_ROUTES = {
  admin: "/admin",
  admin_groups: "/groups",
  admin_groups_full_path: "/admin/groups",
  admin_users: "/users",
  admin_users_full_path: "/admin/users",
  admin_history: "/history",
  admin_history_full_path: "/admin/history",
};

/**
 *
 * @param path
 *  Check if the path is valid admin routes
 * @returns
 */
export const isAdminPath = (path: string): boolean =>
  path === ADMIN_ROUTES.admin ||
  path === ADMIN_ROUTES.admin_groups_full_path ||
  path === ADMIN_ROUTES.admin_users_full_path ||
  path === ADMIN_ROUTES.admin_history_full_path;

export const getUserFacility = (accessItem: string[]) => {
  // [EMR~2052037669,EMR~2052037670,EMR~2052037671,EMR~2052037672,EMR~2052037673,EMR~2052037674,EMR~2052037675,EMR~2052037889,EMR~2052037890,EMR~2052041661,EMR~2052041688,EMR~2057182543,EMR~73,SPD~2052037669,SPD~2052037670,SPD~2052037671,SPD~2052037672,SPD~2052037673,SPD~2052037674,SPD~2052037675,SPD~2052037889,SPD~2052037890,SPD~2052041661,SPD~2052041688,SPD~2057182543,SPD~73,GPO~86,APA~2052037669,APA~2052037670,APA~2052037671,APA~2052037672,APA~2052037673,APA~2052037674,APA~2052037675,APA~2052037889,APA~2052037890,APA~2052041661,APA~2052041688,APA~2057182543,APA~73],
  const find = accessItem.filter(
    (item) => item.startsWith("EMR~") && item.length < 10
  );
  return find.map((item) => Number(item.split("~")[1]));
};

export const checkAlabamaFaciltyUser = (
  items: {
    is_internal: string;
    is_active: string;
    facility_id: number;
    facility_name: string;
    is_eobone: string;
  }[],
  facility_id: number[]
) => {
  return !!items.find(
    (item) =>
      item.facility_name === "Alabama Oncology" &&
      facility_id.includes(item.facility_id)
  );
};
