import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { BiExpand } from "react-icons/bi";
import { LuShrink } from "react-icons/lu";
import { Box, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExpandedView(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ maxWidth: "fit-content", ml: "auto" }}
        onClick={handleClickOpen}
        startIcon={<BiExpand />}
      >
        Full Screen
      </Button>
      <Dialog
        sx={{ backgroundColor: "#F7F7F7" }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            height: "98vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              mt: "1vh",
              pl: "2vw",
            }}
          >
            <Typography variant="h2">{props?.head}</Typography>
            <Button
              sx={{ ml: "auto", mr: "1vw", mb: "2vh", pt: "0" }}
              variant="outlined"
              autoFocus
              color="inherit"
              onClick={handleClose}
              startIcon={<LuShrink />}
            >
              Minimize
            </Button>
          </Box>
          <Box sx={{ height: "95vh", width: "100vw", overflow: "hidden" }}>
            <iframe
              src={props?.url}
              title="test"
              height="100%"
              width="100%"
              frameBorder={0}
            ></iframe>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
