import { Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetFilterDataQuery } from "../../../services/modules/queryBuilder";
import CustomizedAccordions from "../../components/Accordion/Accordion";
import LoadingIndicator from "../../components/Common/LoadingIndicator";
import { OverflowTypography } from "../../components/Common/OverflowTypography";
import SearchBar from "../../components/Search/SearchBar";
import { API_BASE_URL } from "../../constants";
import FiltersModal from "./FiltersModal";



const BuilderSideBar = ({
  open,
  getVal,
  Filters,
  toggleDrawer,
  onClose,
  ApplyFilter,
  vald1,
  vald2,
}) => {
 
  const { data, isLoading } = useGetFilterDataQuery();
  const [StaticData, setStaticData] = useState(data ?? {});
  const [filterValueD2, setValD2] = React.useState("");
  const [filterValueD1, setValD1] = React.useState();
  const [filterValueD3, setValD3] = React.useState(false);
  const [PreviewState, setPreviewState] = useState(false);
  const [previewVal, setPrevVal] = useState(undefined);
  const [gettingPreviewData, setGettingPreviewData] = useState(false);
  const [searchT, setSearchT] = useState(false);

  useEffect(() => {
    setValD2(vald2);
    setValD1(vald1);
    if (vald1 && vald2) {
      setValD3(true);
    }
  }, [vald1, vald2]);
  const getFilterVal = (Fval1, Fval) => {
    setValD1(Object.keys(StaticData)[Fval1]);
    setPrevVal(undefined);
    setPreviewState(false);
    setGettingPreviewData(false);
    setValD2(Fval);
    setValD3(false);
  };
  useEffect(() => {
    setPrevVal(undefined);
    setPreviewState(false);
    setGettingPreviewData(false);
  }, [filterValueD2]);

  useEffect(() => {
    if (data) {
      setStaticData(data);
    }
  }, [data]);

  const searchFilter = (searchString) => {
    if (searchString.trim() !== "") {
      setSearchT(true);
    }

    if (searchString.trim() === "") {
      setSearchT(false);
    }
    const filtered = {};
    Object.keys(data ?? {}).forEach((key) => {
      // Match the Parent keys
      if (key?.toLowerCase().includes(searchString?.toLowerCase())) {
        filtered[key] = data[key];
      } else {
        const filteredChild = {};
        // Match the child key and add them to the parent and push
        Object.keys(data[key]).forEach((child) => {
          if (child?.toLowerCase().includes(searchString?.toLowerCase())) {
            filteredChild[child] = data[key][child];
          }
        });

        if (Object.keys(filteredChild).length > 0) {
          filtered[key] = filteredChild;
        }
      }
    });
    setStaticData(filtered);
  };
  const DateFormatter = (d) => {
    let a = d.split("-");
    return `${a[1]}-${a[0]}-${a[2]}`;
  };
  const SampleValFormatter = (val) => {
    let arr = val.split(";");
    let arr2 = arr[0].split(",");
    return arr2;
  };

  const getDitVals = () => {
    setGettingPreviewData(true);
    const url = `${API_BASE_URL}/get_Distinct`;
    const formdata = new FormData();
    formdata.append(
      "Attribute_Group",

      data[filterValueD1][filterValueD2]["ActualTable"]
    );
    formdata.append(
      "Attribute_Name",

      data[filterValueD1][filterValueD2]["ActualColumn"]
    );
    formdata.append(
      "Attribute_Type",
      data[filterValueD1][filterValueD2]["types"]
    );

    fetch(url, {
      method: "POST",
      body: formdata,
      headers:{
        "Authorization": `Bearer ${sessionStorage.getItem("AppToken")}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        setPrevVal(data);
        setPreviewState(true);
        setGettingPreviewData(false);
      })
      .catch((error) => {
        setPreviewState(false);
        setGettingPreviewData(false);
        console.log(error);
      });
  };

  return (
    <Stack direction={"row"} sx={{ maxHeight: "100vh", pb: "5vh" }}>
      <Box
        sx={{
          width: "30vw",
          p: 3,
          pt: 1,
          minHeight: "100vh",
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="h2">Add Filter to Cohort</Typography>
          {!filterValueD2 && (
            <IconButton
              aria-label="clear-button"
              style={{ marginLeft: "auto", fontSize: "1.5vw", padding: 0 }}
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              <Clear />
            </IconButton>
          )}
        </Stack>
        <SearchBar onSearch={searchFilter} />
        {isLoading && <LoadingIndicator />}
        <CustomizedAccordions
          getVal={getFilterVal}
          filters={Filters}
          staticData={StaticData}
          selectedVal={filterValueD2}
          searchT={searchT}
        />
      </Box>
      {filterValueD2 && (
        <Box
          sx={{
            width: "30vw",
            p: 3,
            pt: 1,

            maxHeight: "100vh",
            overflowY: "auto",
            borderLeft: "1px solid #c3c3c3",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mt: 1 }}>
            {!filterValueD3 && (
              <IconButton
                aria-label="clear-button2"
                style={{
                  marginLeft: "auto",
                  fontSize: "1.5vw",
                  padding: 0,
                }}
                onClick={() => {
                  toggleDrawer(false);
                }}
              >
                <Clear />
              </IconButton>
            )}
          </Box>
          <Stack
            direction={"row"}
            sx={{ display: "flex", width: "100%" }}
            alignItems={"center"}
          >
            <Typography sx={{ mt: "3vh" }} variant="h2">
              Data Field details
            </Typography>
          </Stack>
          <Card
            sx={{
              p: 2,
              mt: 2,
              boxShadow: "0px 3px 10px #00000014",
              border: "1px solid #C3C3C3",
              borderRadius: "1vh",
              minHeight: "30vh",
              maxHeight: "30vh",
            }}
          >
            <Typography variant="h3">{filterValueD2}</Typography>
            <Divider sx={{ my: 1 }} />
            <CardContent sx={{ p: 0, maxHeight: "20vh", overflowY: "auto" }}>
              <Box
                sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}
              >
                <Stack sx={{ gap: 0.7, width: "50%" }}>
                  <Typography variant="body2">Attribute Group</Typography>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {filterValueD1}
                  </Typography>
                </Stack>
                <Stack sx={{ gap: 0.7, width: "50%" }}>
                  <Typography variant="body2">Value Type</Typography>
                  <Typography variant="body1">
                    {data[filterValueD1][filterValueD2]["types"] ===
                      "Multi Select" && "String"}
                    {data[filterValueD1][filterValueD2]["types"] ===
                      "Text Box" && "String"}
                    {data[filterValueD1][filterValueD2]["types"] ===
                      "Number Range" && "Numeric"}
                    {data[filterValueD1][filterValueD2]["types"] ===
                      "Date Range" && "Date"}
                  </Typography>
                </Stack>
              </Box>
              <Stack sx={{ gap: 0.7, width: "90%", mt: "1vh" }}>
                <Typography variant="body2">Description</Typography>
                <Typography variant="body1">
                  {data[filterValueD1][filterValueD2]["description"]}
                </Typography>
              </Stack>
            </CardContent>
          </Card>

          <Box
            sx={{
              width: "100%",
              height: "auto",
              mt: "1vh",
              display: "flex",
            }}
          >
            <Button
              size="small"
              disabled={PreviewState || gettingPreviewData}
              sx={{
                py: "0.5vh",
                ml: "auto",
                mt: "1vh",
                borderRadius: "0",
              }}
              onClick={() => {
                getDitVals();
              }}
              variant="contained"
              data-testid="see-preview"
            >
              See Preview
            </Button>
            {!filterValueD3 && (
              <Button
                size="small"
                sx={{
                  py: "0.5vh",
                  ml: "1vw",
                  mt: "1vh",
                  borderRadius: "0",
                }}
                onClick={() => {
                  toggleDrawer(false);
                  getVal(filterValueD2, filterValueD1);
                }}
                variant="contained"
              >
                Add Criteria
              </Button>
            )}
          </Box>
          {gettingPreviewData && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ mx: "auto", mt: "2vh" }} />
              Getting Preview
            </Box>
          )}
          {PreviewState === true &&
            !gettingPreviewData &&
            previewVal !== undefined && (
              <>
                {gettingPreviewData && "Loading..."}
                {data[filterValueD1][filterValueD2]["types"] ===
                  "Multi Select" && (
                  <Card
                    sx={{
                      p: 2,
                      mt: 2,
                      boxShadow: "0px 3px 10px #00000014",
                      border: "1px solid #C3C3C3",
                      borderRadius: "1vh",
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Stack
                          direction={"row"}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: "1vw",
                            fontWeight: "bold",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1vw",
                              width: "50%",
                              height: "auto",
                              fontWeight: "bold",
                            }}
                          >
                            Attribute Value
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "1vw",
                              width: "20%",
                              height: "auto",
                              fontWeight: "bold",
                            }}
                          >
                            Patient Count
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "1vw",
                              width: "20%",
                              height: "auto",
                              ml: "auto",
                              fontWeight: "bold",
                            }}
                          >
                            Patient Count %
                          </Typography>
                        </Stack>
                        <Divider />
                        {previewVal
                          ?.sort((a, b) => b.count - a.count)
                          ?.map((v) => {
                            let k =
                              data[filterValueD1][filterValueD2][
                                "ActualColumn"
                              ];
                            return (
                              <>
                                {v["count"] > 0 && v[k] !== null && (
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      mt: "1vw",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <OverflowTypography
                                      sx={{
                                        fontSize: "1vw",
                                        width: "50%",
                                        height: "auto",
                                      }}
                                    >
                                      {v[k]}
                                    </OverflowTypography>
                                    <Typography
                                      sx={{
                                        fontSize: "1vw",
                                        width: "20%",
                                        height: "auto",
                                      }}
                                    >
                                      {v["count"].toLocaleString("en-Us")}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: "1vw",
                                        width: "20%",
                                        height: "auto",
                                        ml: "auto",
                                      }}
                                    >
                                      {(
                                        (v["count"] /
                                        previewVal.filter(item => item[k]!== null).reduce((acc, current) => acc + current.count, 0)) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </Typography>
                                  </Stack>
                                )}
                                <Divider />
                              </>
                            );
                          })}
                      </Box>
                    </CardContent>
                  </Card>
                )}

                {data[filterValueD1][filterValueD2]["types"] === "Date Range" &&
                  previewVal?.length > 0 &&
                  PreviewState && (
                    <Card
                      sx={{
                        p: 2,
                        mt: 2,
                        boxShadow: "0px 3px 10px #00000014",
                        border: "1px solid #C3C3C3",
                        borderRadius: "1vh",
                      }}
                    >
                      <CardContent>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {["Latest Date", "Oldest Date", "Null Values"].map(
                            (v, index) => {
                              return (
                                <>
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      mt: "1vw",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "1vw",
                                        width: "40%",
                                        height: "auto",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {v}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "1vw",
                                        width: "60%",
                                        height: "auto",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {index === 0 &&
                                        DateFormatter(
                                          previewVal[0]["latest_date"]
                                            ?.split("-")
                                            .reverse()
                                            .join("-")
                                        )}
                                      {index === 1 &&
                                        DateFormatter(
                                          previewVal[0]["oldest_date"]
                                            .split("-")
                                            .reverse()
                                            .join("-")
                                        )}
                                      {index === 2 &&
                                        previewVal[0][
                                          "null_values_count"
                                        ].toLocaleString("en-Us")}
                                    </Typography>
                                  </Stack>
                                  <Divider />
                                </>
                              );
                            }
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                {data[filterValueD1][filterValueD2]["types"] === "Text Box" &&
                  PreviewState && (
                    <>
                      <Card
                        sx={{
                          p: 2,
                          mt: 2,
                          boxShadow: "0px 3px 10px #00000014",
                          border: "1px solid #C3C3C3",
                          borderRadius: "1vh",
                        }}
                      >
                        <Typography variant="h3">Sample Values</Typography>
                        <Divider />
                        <CardContent>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            {SampleValFormatter(
                              data[filterValueD1][filterValueD2][
                                "distinct_values"
                              ][0]
                            )?.map((v, index) => {
                              return (
                                <>
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      mt: "1vw",
                                    }}
                                  >
                                    <OverflowTypography
                                      sx={{
                                        fontSize: "1vw",
                                        width: "90%",
                                        height: "auto",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {v}
                                    </OverflowTypography>
                                  </Stack>
                                  <Divider />
                                </>
                              );
                            })}
                          </Box>
                        </CardContent>
                      </Card>
                    </>
                  )}

                {data[filterValueD1][filterValueD2]["types"] ===
                  "Number Range" &&
                  previewVal?.length > 0 &&
                  PreviewState && (
                    <Card
                      sx={{
                        p: 2,
                        mt: 2,
                        boxShadow: "0px 3px 10px #00000014",
                        border: "1px solid #C3C3C3",
                        borderRadius: "1vh",
                      }}
                    >
                      <CardContent>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {[
                            "Minimum Value",
                            "Maximum Value",
                            "Null Values",
                          ].map((v, index) => {
                            return (
                              <>
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: "1vw",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "1vw",
                                      width: "40%",
                                      height: "auto",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {v}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "1vw",
                                      width: "60%",
                                      height: "auto",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {index === 0 && previewVal[0]["min_value"]}
                                    {index === 1 && previewVal[0]["max_value"]}
                                    {index === 2 &&
                                      previewVal[0][
                                        "null_count"
                                      ].toLocaleString("en-Us")}
                                  </Typography>
                                </Stack>
                                <Divider />
                              </>
                            );
                          })}
                        </Box>
                      </CardContent>
                    </Card>
                  )}
              </>
            )}
        </Box>
      )}

      {filterValueD3 && (
        <Box
          sx={{
            width: "30vw",
            p: 3,
            pt: 1,
            //  p: "1vw",
            maxHeight: "100vh",
            minHeight: "100vh",
            overflowY: "auto",
            borderLeft: "1px solid #c3c3c3",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mt: 1 }}>
            <IconButton
              aria-label="clear-button3"
              style={{ marginLeft: "auto", fontSize: "1.5vw", padding: 0 }}
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              <Clear style={{ padding: "0" }} />
            </IconButton>
          </Box>
          <Typography variant="h2">Add Criteria</Typography>
          <FiltersModal
            filterVal={filterValueD2}
            HeadFilterVal={filterValueD1}
            onClose={onClose}
            ApplyFilter={ApplyFilter}
            initialVal={
              Filters.hasOwnProperty(filterValueD2)
                ? Filters[filterValueD2]
                : null
            }
            initialFilterCount={Object.keys(Filters).length > 0 ? true : false}
            filterIndex={
              filterValueD2 ? Object.keys(Filters).indexOf(filterValueD2) : 0
            }
            StaticData={StaticData}
          />
        </Box>
      )}
    </Stack>
  );
};

export default BuilderSideBar;
