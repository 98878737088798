import React, { FC, useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer/PageContainer";
import {
  Box,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  MenuItem,
  Button,
} from "@mui/material";
import HistoryTable from "./HistoryTable";
import { useLazyGetHistoryQuery } from "../../../../services/modules/admin";
import { IHistoryDetails } from "../../../../services/modules/admin/getHistory";
import { HISTORY_ACTIONS } from "../adminUtils";
import SearchBar from "../../../components/Search/SearchBar";
import dayjs from "dayjs";
import TableLoading from "../../../components/Common/TableLoading";

interface HistoryProps {}

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
const History: FC<HistoryProps> = () => {
  const [getHistory, { isLoading, data: historyRaw = [] }] =
    useLazyGetHistoryQuery();
  const [history, setHistory] = useState<IHistoryDetails[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [filters, setFilters] = useState({
    search: "",
    actionType: "",
    user: "",
    date: "",
  });
  useEffect(() => {
    getHistory();
  }, []);

  useEffect(() => {
    setHistory(historyRaw);
    // get uniques user in the history
    const userAll = historyRaw.map((h) => h.Owner);
    //@ts-ignore
    setUsers([...new Set(userAll)]);
  }, [historyRaw]);

  const onFilter = () => {
    let filterData = [...historyRaw];
    // search filter
    if (filters.search) {
      const searchRegex = new RegExp(escapeRegExp(filters.search), "i");
      filterData = filterData?.filter((row: any) => {
        return Object.keys(row).some((field: any) => {
          return searchRegex.test(row[field]?.toString());
        });
      });
    }
    // type filter
    if (filters.actionType) {
      filterData = filterData.filter((row: IHistoryDetails) => {
        return row.Action_Type === filters.actionType;
      });
    }
    // user filter
    if (filters.user) {
      filterData = filterData.filter((row: IHistoryDetails) => {
        return row.Owner === filters.user;
      });
    }
    // date
    if (filters.date) {
      filterData = filterData.filter((row: IHistoryDetails) => {
        return dayjs(row.id ?? "").isSame(filters.date, "D");
      });
    }
    setHistory(filterData);
  };

  useEffect(() => {
    onFilter();
  }, [filters.search, filters.actionType, filters.user, filters.date]);

  return (
    <PageContainer>
      <Typography variant="h2">Audit Logs</Typography>
      <Box sx={{ mt: 2 }}>
        <Grid container gap={2}>
          <FormGroup>
            <FormLabel>Search</FormLabel>
            <SearchBar
              onSearch={(t) => {
                setFilters({
                  ...filters,
                  search: t,
                });
              }}
              containerSX={{
                minWidth: "50vh",
                mt: 0,
              }}
              placeholder="Search Logs"
            />
          </FormGroup>
          <FormGroup
            sx={{
              minWidth: "30vh",
            }}
          >
            <FormLabel>Action</FormLabel>
            <TextField
              select
              value={filters.actionType}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  actionType: e.target.value ?? "",
                });
              }}
              inputProps ={{ "data-testid":"action" }}
            >
              {HISTORY_ACTIONS.map((h) => (
                <MenuItem value={h} key={h}>
                  {h}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
          <FormGroup
            sx={{
              minWidth: "30vh",
            }}
          >
            <FormLabel>Date</FormLabel>
            <TextField
              value={filters.date}
              type="date"
              onChange={(e) => {
                setFilters({
                  ...filters,
                  date: e.target.value ?? "",
                });
              }}
              inputProps ={{ "data-testid":"date-field" }}
            ></TextField>
          </FormGroup>
          <FormGroup
            sx={{
              minWidth: "30vh",
            }}
          >
            <FormLabel>User</FormLabel>
            <Autocomplete
              options={users}
              value={filters.user}
              onChange={(event: any, newValue: string | null) => {
                setFilters({
                  ...filters,
                  user: newValue ?? "",
                });
              }}
              renderInput={(params) => <TextField {...params}  inputProps={{"data-testid":"user-field"}}/>}
            />
          </FormGroup>
          <Button
            sx={{ mt: 1 }}
            onClick={() => {
              setFilters({
                search: "",
                user: "",
                date: "",
                actionType: "",
              });
              setHistory(historyRaw);
            }}
          >
            Clear filters
          </Button>
        </Grid>
        {isLoading ? <TableLoading /> : <HistoryTable items={history} />}
      </Box>
    </PageContainer>
  );
};

export default History;
