import { Clear, Filter } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineDeleteForever } from "react-icons/md";
import { useGetFilterDataQuery } from "../../../services/modules/queryBuilder";
import LoadingIndicator from "../../components/Common/LoadingIndicator";
import { UseStaticDash } from "../../Context/StaticDashboardsContext";
import SingleCohortFilters from "../../pages/Builder/SingleCohortFilters";
import FiltersModal from "../../pages/Builder/FiltersModal";
const StaticDashboardDrawer = ({
  open,
  toggleDrawer,
  ExistingCoh,
  setNm,
  cohortNm,
  CohortNames,
}) => {
  const [Filters, setFilters] = useState({});
  const { data, isLoading } = useGetFilterDataQuery();
  const [StaticData, setStaticData] = useState(data ?? {});
  const [filterValueD2, setValD2] = React.useState("");
  const [filterValueD1, setValD1] = React.useState();
  const { GlobalFilters, setGlobalFilters } = UseStaticDash();
  const [showDescFltrs, setShowDscFlt] = useState(false);
  const [Cohoname, setCohoName] = useState("");

  useEffect(() => {
    if (data) {
      setStaticData(data);
    }
  }, [data]);

  useEffect(() => {
    setFilters(GlobalFilters);
  }, [GlobalFilters]);

  const [value, setValue] = React.useState(
    cohortNm === "No Cohort" ? "Global" : "Cohort"
  );
  /* istanbul ignore next */
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setValue(newValue);
      setValD2();
      setValD1();
      setCohoName("");
      setShowDscFlt(false);
    }
  };
  /* istanbul ignore next */
  const ApplyFilter = (a, b) => {
    setFilters((prev) => {
      let obj = { ...prev };
      obj[a] = b;
      return obj;
    });
  };

  let StatGlobalFilters = {
    "Service Date": {
      ActualColumn: "Service_Date",
      ActualTable: "chargeitem",
      Table: "Visit",
      types: "Date Range",
    },

    "Visit Date": {
      ActualColumn: "Visit_Date",
      ActualTable: "encounter",
      Table: "Visit",
      types: "Date Range",
    },

    "Age Group": {
      ActualColumn: "Age_Group",
      ActualTable: "patient",
      Table: "Demographics",
      types: "Multi Select",
    },
    // "Payer Type": {
    //   ActualColumn: "Payer_Type",
    //   ActualTable: "coverage",
    //   Table: "Payer",
    //   types: "Multi Select",
    // },
    "Primary Tumor Site": {
      ActualColumn: "Primary_Tumor_Site",
      ActualTable: "condition",
      Table: "Disease",

      types: "Multi Select",
    },
    "Secondary Tumor Site": {
      ActualColumn: "Secondary_Tumor_Site",
      ActualTable: "condition",
      Table: "Disease",
      types: "Multi Select",
    },
    "Clinical Disease Status": {
      ActualColumn: "Clinical_Disease_Status",
      ActualTable: "condition",
      Table: "Disease",
      types: "Multi Select",
    },
    "Cancer Stage": {
      ActualColumn: "Cancer_Stage",
      ActualTable: "observation",
      Table: "Disease",
      types: "Multi Select",
    },
    "Therapy Type": {
      ActualColumn: "Therapy_Type",
      ActualTable: "service_request",
      Table: "Treatment",
      types: "Multi Select",
    },
  };

  return (
    <Stack direction={"row"} sx={{ maxHeight: "100vh", pb: "5vh" }}>
      <Box
        sx={{
          width: "30vw",
          p: 3,
          pt: 1,
          minHeight: "100vh",
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="black"
          >
            <Tab value="Global" label="Global Filters" />
            <Tab value="Cohort" label="Existing Cohorts" />
          </Tabs>
        </Box>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: "1vh" }}>
          {value === "Global" ? (
            <Typography variant="h2">Apply Global Filters</Typography>
          ) : (
            <Typography variant="h2">Apply Cohort Filters</Typography>
          )}
          {((value === "Cohort" && !showDescFltrs) ||
            (value === "Global" &&
              !filterValueD2 &&
              Object.keys(Filters).length === 0)) && (
            <IconButton
              style={{ marginLeft: "auto", fontSize: "1.5vw", padding: 0 }}
              onClick={() => {
                toggleDrawer(false);
              }}
              data-testid="clear"
            >
              <Clear />
            </IconButton>
          )}
        </Stack>
        {/* <SearchBar onSearch={searchFilter} /> */}

        {value === "Cohort" &&
          ExistingCoh?.map((coh) => {
            return (
              <Stack sx={{ mt: "2vh" }}>
                <Stack
                  onClick={() => {
                    /* istanbul ignore next */
                    if (coh === "No Cohort") {
                      setNm(coh);
                      setGlobalFilters({});
                      toggleDrawer(false);
                    } else {
                      setCohoName(coh);
                      setShowDscFlt(true);
                    }
                  }}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    py: "1.5vh",
                    pl: "1vw",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  data-testid="existing-coh"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight:
                        cohortNm === coh && value === "Cohort" ? "bold" : "",
                    }}
                  >
                    {coh}
                  </Typography>
                  {(cohortNm === coh || Cohoname === coh) &&
                    value === "Cohort" && (
                      <FaCheck
                        color="primary"
                        style={{ cursor: "pointer", fontSize: "1vw" }}
                      />
                    )}
                </Stack>
                <Divider />
              </Stack>
            );
          })}

        {isLoading && value === "Global" && <LoadingIndicator />}
        {value === "Global" &&
          !isLoading &&
          Object.keys(StatGlobalFilters)?.map((fl) => {
            return (
              <Stack sx={{ mt: "2vh" }}>
                <Stack
                  onClick={() => {
                    /* istanbul ignore next */
                    if (!filterValueD1 && !filterValueD2) {
                      setValD2(fl);
                      setValD1(StatGlobalFilters[fl]["Table"]);
                    }
                  }}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    py: "1.5vh",
                    pl: "1vw",
                    cursor:
                      !filterValueD1 && !filterValueD2 ? "pointer" : "default",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  data-testid="global"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: Filters.hasOwnProperty(fl) ? "bold" : "",
                    }}
                  >
                    {fl}
                  </Typography>

                  {Filters.hasOwnProperty(fl) && (
                    <FaCheck
                      color="primary"
                      style={{ cursor: "pointer", fontSize: "1vw" }}
                    />
                  )}
                </Stack>
                <Divider />
              </Stack>
            );
          })}
      </Box>

      {value === "Global" && filterValueD2 && (
        <Box
          sx={{
            width: "30vw",
            p: 3,
            pt: 4,

            maxHeight: "100vh",
            overflowY: "auto",
            borderLeft: "1px solid #c3c3c3",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mt: 1 }}>
            {filterValueD2 && Object.keys(Filter).length === 0 && (
              <IconButton
                style={{
                  marginLeft: "auto",
                  fontSize: "1.5vw",
                  padding: 0,
                }}
                onClick={() => {
                  toggleDrawer(false);
                }}
              >
                <Clear />
              </IconButton>
            )}
          </Box>
          <Stack
            direction={"row"}
            sx={{ display: "flex", width: "100%" }}
            alignItems={"center"}
          >
            <Typography sx={{ mt: "3vh" }} variant="h2">
              Add Criteria
            </Typography>
          </Stack>

          <FiltersModal
            filterVal={filterValueD2}
            HeadFilterVal={filterValueD1}
            onClose={() => {
              setValD2();
              setValD1();
            }}
            ApplyFilter={ApplyFilter}
            initialVal={
              Filters.hasOwnProperty(filterValueD2)
                ? Filters[filterValueD2]
                : null
            }
            StaticData={StaticData}
            isGlobal={true}
          />
        </Box>
      )}

      {value === "Global" && Object.keys(Filters).length > 0 && (
        <Box
          sx={{
            width: "30vw",
            p: 3,
            pt: 1,

            maxHeight: "100vh",
            overflowY: "auto",
            borderLeft: "1px solid #c3c3c3",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mt: 1 }}>
            {(filterValueD2 || Object.keys(Filters).length > 0) && (
              <IconButton
                style={{
                  marginLeft: "auto",
                  fontSize: "1.5vw",
                  padding: 0,
                }}
                onClick={() => {
                  toggleDrawer(false);
                }}
              >
                <Clear />
              </IconButton>
            )}
          </Box>
          <Stack
            direction={"row"}
            sx={{ display: "flex", width: "100%" }}
            alignItems={"center"}
          >
            <Typography sx={{ mt: "3vh" }} variant="h2">
              Applied Filters
            </Typography>
          </Stack>

          {Object.keys(Filters).length === 0 && "No Filters Applied"}
          {Object.keys(Filters).length > 0 &&
            Object.keys(Filters).map((fl) => {
              return (
                <Stack
                  sx={{
                    mt: "3vh",
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "1vw",
                  }}
                >
                  <Typography sx={{ width: "85%", fontSize: "1vw" }}>
                    {fl}
                  </Typography>
                  <Box
                    sx={{ width: "10%", display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: "1vw" }}>
                      <CiEdit
                        disable={filterValueD1 || filterValueD2}
                        onClick={(e) => {
                          setValD2(fl);
                          setValD1(StatGlobalFilters[fl]["Table"]);
                        }}
                        style={{ cursor: "pointer", fontSize: "1vw" }}
                      />
                    </Typography>

                    <Typography sx={{ fontSize: "1vw" }}>
                      <MdOutlineDeleteForever
                        disable={filterValueD1 || filterValueD2}
                        onClick={(e) => {
                          setFilters((prev) => {
                            let fls = { ...prev };
                            delete fls[fl];
                            return fls;
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "1vw",
                          fontSize: "1vw",
                        }}
                      />
                    </Typography>
                  </Box>
                </Stack>
              );
            })}

          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mt: "1vh",
            }}
            alignItems={"center"}
          >
            <Button
              disabled={Object.keys(Filters).length === 0}
              variant="outlined"
              onClick={(e) => {
                setFilters({});
              }}
            >
              Clear All
            </Button>
            <Button
              disabled={Object.keys(Filters).length === 0}
              onClick={(e) => {
                setGlobalFilters(Filters);
                setNm("No Cohort");
                toggleDrawer(false);
              }}
              variant="outlined"
            >
              Apply to Dashboard
            </Button>
          </Stack>
        </Box>
      )}

      {value === "Cohort" && showDescFltrs && Cohoname && (
        <Box
          sx={{
            width: "30vw",
            p: 3,
            pt: 1,

            maxHeight: "100vh",
            overflowY: "auto",
            borderLeft: "1px solid #c3c3c3",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", mt: 1 }}>
            {(filterValueD2 || Object.keys(Filters).length > 0) && (
              <IconButton
                style={{
                  marginLeft: "auto",
                  fontSize: "1.5vw",
                  padding: 0,
                }}
                onClick={() => {
                  toggleDrawer(false);
                }}
                data-testid="cohoname"
              >
                <Clear />
              </IconButton>
            )}
          </Box>
          <Stack
            direction={"row"}
            sx={{ display: "flex", width: "100%" }}
            alignItems={"center"}
          >
            <Typography sx={{ mt: "6vh", ml: "0vw" }} variant="h2">
              Cohort Details
            </Typography>
          </Stack>

          <Card
            sx={{
              p: 2,
              mt: 4,
              boxShadow: "0px 3px 10px #00000014",
              border: "1px solid #C3C3C3",
              borderRadius: "1vh",
              minHeight: "20vh",
              maxHeight: "20vh",
              mb: 4,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h3">{Cohoname}</Typography>
            </Box>

            <Divider sx={{ my: 1 }} />
            <CardContent sx={{ p: 0, maxHeight: "20vh", overflowY: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                <Stack sx={{ width: "100%", gap: 0.7 }}>
                  <Typography variant="body2">Description</Typography>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {CohortNames[Cohoname]["Desc"]}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>

          <Typography variant="h3">Filter Preview</Typography>
           <SingleCohortFilters
           selectedFilters={JSON.parse(CohortNames[Cohoname]["Filter"])}
           isGlobal={true}
           />

          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              mt: "3vh",
            }}
            alignItems={"center"}
          >
            <Button
              variant="outlined"
              sx={{ ml: "auto" }}
              onClick={(e) => {
                setCohoName("");
                setShowDscFlt(false);
              }}
              data-testid="cancel"
            >
              Cancel
            </Button>
            <Button
              disabled={Cohoname === cohortNm}
              sx={{ ml: "1vw" }}
              onClick={(e) => {
                setNm(Cohoname);
                setGlobalFilters({});
                setShowDscFlt(false);
                setCohoName("");
                toggleDrawer(false);
              }}
              variant="outlined"
              data-testid="apply-dashboard"
            >
              Apply to Dashboard
            </Button>
          </Stack>

          {filterValueD2 && (
            <FiltersModal
              filterVal={filterValueD2}
              HeadFilterVal={filterValueD1}
              onClose={() => {
                setValD2();
                setValD1();
              }}
              ApplyFilter={ApplyFilter}
              initialVal={
                Filters.hasOwnProperty(filterValueD2)
                  ? Filters[filterValueD2]
                  : null
              }
              StaticData={StaticData}
              isGlobal={true}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

export default StaticDashboardDrawer;
