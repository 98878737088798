import { NoAccessPage } from "../layout/NoAccessPage";
import AllCohortsScreen from "../pages/All Corhots/AllCohortsScreen";
import Home from "../pages/Builder";
import StaticDashboardsWrapper from "../pages/Looker Static Dashboards/StaticDashboardsWrapper";
import { RequiredAuth } from "./SecureRoute";

export const USER_ROUTES = {
  builder: "/practice-explorer",
  nlp_builder: "/nlp-builder",
  all_cohorts: "/all-corhots",
  patient_profiling: "/population-profiling",
  diagnonis_staging: "/diagnosis-and-disease-staging",
  therapy_analysis: "/therapy-pathways",
};

const UserRoutes = {
  path: "/",
  title: "home",
  element: <RequiredAuth />,
  children: [
    {
      path: USER_ROUTES.builder,
      element: <Home />,
    },
    {
      path: USER_ROUTES.all_cohorts,
      element: <AllCohortsScreen />,
    },

    {
      path: USER_ROUTES.patient_profiling,
      element: <StaticDashboardsWrapper />,
    },

    {
      path: USER_ROUTES.diagnonis_staging,
      element: <StaticDashboardsWrapper />,
    },
    {
      path: USER_ROUTES.therapy_analysis,
      element: <StaticDashboardsWrapper />,
    },
    {
      path: "/no-access",
      element: <NoAccessPage />,
    },
  ],
};

export default UserRoutes;
