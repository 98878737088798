import dayjs from "dayjs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import startCase from "lodash.startcase";

export const exportToPDF = (data: any) => {
  const doc = new jsPDF();

  //generate headers from the data
  const headers = Object.keys(data[0]).map((key) => startCase(key));

  //generate data from the data
  const rows = data.map((row: any) => Object.values(row));

  // generate PDF file with all the
  // data in it
  autoTable(doc, {
    head: [headers],
    body: rows,
    theme: "striped",
    horizontalPageBreak: true,
  });

  //download the PDF file(
  doc.save(generateFilename());
};

export const generateFilename = (name: string = "patient-list") => {
  return `${name}_${dayjs().format("DD-MM-YYYY_HH:mm:ss")}`;
};
