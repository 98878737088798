import { EndpointBuilder } from "@reduxjs/toolkit/query";

const getPractices = (build: EndpointBuilder<any, any, any>) =>
  build.query<string[], void>({
    query: () => ({
      url: "/get_practice_name",
      method: "GET",
    }),
  });

export default getPractices;
