import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useLazyGetAllCohortsQuery } from "../../../services/modules/viewAllCohorts";
import { useSelector } from "../../../store/configureStore";
import {
  setCohortType,
  setCombineLogic,
  setComparableFilters,
  setComparedCohortTitle,
} from "../../../store/reducers/CohortReducer";
import images from "../../assets/Images";

const CohortCompareDialog = (props: any) => {
  const { cohortData, ComparableFilters, cohortType, combineLogic } =
    useSelector((state) => state.CohortReducer);
  const [selectedCohort1, setSelectedCohort1] = useState<any>();
  const [selectedCohort2, setSelectedCohort2] = useState<any>();
  const [expandedGroup1, setExpandedGroup1] = useState("");
  const [expandedGroup2, setExpandedGroup2] = useState("My Cohorts");
  const { userName } = useSelector((state) => state.LoginReducer);
  const [getAllCohorts, { data: allCohorts, error }] =
    useLazyGetAllCohortsQuery();
  const [allOptions, setAllOptions] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [options2, setOptions2] = useState<any>([]);
  const dispatch = useDispatch();
  const [selectedCombineLogic, setSelectedCombineLogic] = useState("");
  const [selectedLogicDesc, setSelectedLogicDesc] = useState("");
  const [isExpanded, setExpanded] = useState(false);
  const [isHovered, setHovered] = useState(-1);
  const combineOperations = [
    {
      title: "Union",
      img: images.ic_union,
      desc: " all members of Cohort A and Cohort B",
      hover: false,
    },
    {
      title: "Subtraction",
      img: images.ic_subtraction,
      desc: " Cohort A and not in Cohort B",
      hover: false,
    },
    {
      title: "Intersection",
      img: images.ic_intersection,
      desc: " both Cohort A and Cohort B",
      hover: false,
    },
    {
      title: "Unique",
      img: images.ic_unique,
      desc: " either Cohort A or Cohort B",
      hover: false,
    },
  ];

  const handleClose = () => {
   

    props?.onClose();
  };

  useEffect(() => {
    getAllCohorts(userName);
  }, []);

  useEffect(() => {
    if (allCohorts) {
      const options = allCohorts.data.map((item: any) => {
        const item1 = {
          title: item.Shared === true ? "Shared Cohorts" : "My Cohorts",
          CohortName: item.CohortName,
          Dashboards: item.Dashboards,
          Filters: item.Filters,
          Timestamp: item.Timestamp,
          Shared: item.Shared ?? false,
        };
        return item1;
      });

      setSelectedCohort1(
        options.filter(
          (item: any) => item.CohortName === cohortData.CohortName
        )[0]
      );
      setOptions(options);
      setAllOptions(options);
      setOptions2(
        options.filter((item: any) => item.CohortName !== cohortData.CohortName)
      );
      if (cohortType !== "") {
        setSelectedCohort1(
          options.filter(
            (item: any) =>
              item.CohortName === ComparableFilters[0]["CohortName"]
          )[0]
        );
        setSelectedCohort2(
          options.filter(
            (item: any) =>
              item.CohortName === ComparableFilters[1]["CohortName"]
          )[0]
        );
        setSelectedCombineLogic(combineLogic);
      }

 
    }
  }, [allCohorts]);

  useEffect(() => {
    if (selectedCohort1) {
      setOptions2(
        allOptions.filter(
          (item: any) => item.CohortName !== selectedCohort1?.CohortName
        )
      );
      setExpandedGroup1(
        selectedCohort1?.Shared === true ? "Shared Cohorts" : "My Cohorts"
      );
    }
  }, [selectedCohort1]);

  useEffect(() => {
    if (selectedCohort2) {
      setOptions(
        allOptions.filter(
          (item: any) => item.CohortName !== selectedCohort2?.CohortName
        )
      );
      setExpandedGroup2(
        selectedCohort2?.Shared === true ? "Shared Cohorts" : "My Cohorts"
      );
    }
  }, [selectedCohort2]);
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
    >
      <Box sx={{ minWidth: "50vw" }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", alignItems: "center", p: 3 }}
        >
          <Typography variant="h4">
            Select Cohort to
            {props?.isCombine ? " Combine" : " Compare"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack sx={{ width: "49%" }}>
              <FormLabel>Cohort A</FormLabel>
              <Autocomplete
                options={options.sort((a: any, b: any) =>
                  b.Shared === a.Shared ? 0 : b.Shared ? -1 : 1
                )}
                onChange={(event, value) => setSelectedCohort1(value)}
                groupBy={(options) => options.title}
                getOptionLabel={(options) => options.CohortName || ""}
                fullWidth
                sx={{ maxHeight: "6vh" }}
                value={selectedCohort1 || {}}
                isOptionEqualToValue={(options, value) =>
                  options.CohortName === value.CohortName
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    
                    style={{ fontSize: "1vw" }}
                    placeholder="Select Cohort"
                  />
                )}
                renderGroup={(params) => {
                  const { group, children } = params;
                  return (
                    <>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "common.tableHead",
                          px: 1,
                          mx: 1,
                          my: 0.5,
                        }}
                        direction={"row"}
                        onClick={() =>
                          expandedGroup1 !== params.group
                            ? setExpandedGroup1(params.group)
                            : setExpandedGroup1("")
                        }
                      >
                        <Typography sx={{ color: "primary.main" }}>
                          {params.group}
                        </Typography>
                        {params.group === expandedGroup1 ? (
                          <IconButton data-testid="icon-up">
                            {" "}
                            <IoChevronUp />
                          </IconButton>
                        ) : (
                          <IconButton data-testid="icon-down">
                            {" "}
                            <IoChevronDown />
                          </IconButton>
                        )}
                      </Stack>
                      {params.group === expandedGroup1 && <>{children}</>}
                    </>
                  );
                }}
                renderOption={(props, option) => (
                  <>
                    <MenuItem
                      {...props}
                      sx={{ mx: 1, whiteSpace: "normal" }}
                      key={option.key}
                    >
                      {option.CohortName}
                    </MenuItem>
                  </>
                )}
              />
            </Stack>
            <Stack sx={{ width: "49%" }}>
              <FormLabel>Cohort B</FormLabel>
              <Autocomplete
                id="grouped-demo"
                options={options2.sort((a: any, b: any) =>
                  b.Shared === a.Shared ? 0 : b.Shared ? -1 : 1
                )}
                onChange={(event, value) => {
                  setSelectedCohort2(value);
                }}
                groupBy={(options) => options.title}
                getOptionLabel={(options) => options.CohortName || ""}
                fullWidth
                disableListWrap={false}
                size="small"
                sx={{ maxHeight: "6vh" }}
                value={selectedCohort2 || {}}
                isOptionEqualToValue={(options, value) =>
                  options.CohortName === value.CohortName
                }
                onInputChange={() => setExpanded(true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                   
                    placeholder="Select Cohort"
                    style={{ fontSize: "1vw" }}
                  />
                )}
                renderGroup={(params) => {
                  const { group, children } = params;
                  return (
                    <>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "common.tableHead",
                          //width: "100%",
                          px: 1,
                          mx: 1,
                          my: 0.5,
                        }}
                        direction={"row"}
                        onClick={() => {
                          setExpanded(false);
                          expandedGroup2 !== params.group
                            ? setExpandedGroup2(params.group)
                            : setExpandedGroup2("");
                        }}
                      >
                        <Typography sx={{ color: "primary.main" }}>
                          {params.group}
                        </Typography>
                        {params.group === expandedGroup2 ? (
                          <IconButton>
                            {" "}
                            <IoChevronUp />
                          </IconButton>
                        ) : (
                          <IconButton>
                            {" "}
                            <IoChevronDown />
                          </IconButton>
                        )}
                      </Stack>
                      {(isExpanded || params.group === expandedGroup2) && (
                        <>{children}</>
                      )}
                    </>
                  );
                }}
                renderOption={(props, option) => (
                  <>
                    <MenuItem
                      {...props}
                      sx={{ mx: 1, whiteSpace: "normal" }}
                      key={option.key}
                    >
                      {/* <Typography variant="body1"> */}
                      {option.CohortName}
                      {/* </Typography> */}
                    </MenuItem>
                  </>
                )}
              />
            </Stack>
          </Stack>

          {props?.isCombine && (
            <Box sx={{ mt: 4 }}>
              <FormLabel>Select Logic</FormLabel>

              <Divider
                sx={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "common.bg1",
                  my: 1,
                }}
              />
              <Stack direction={"row"} gap={1} sx={{ display: "flex" }}>
                {combineOperations.map((item, index) => {
                  let ImageIcon = item.img;
                  return (
                    <Stack
                      sx={{
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <Button
                        sx={{
                          // width: "auto",
                          //px: 4,
                          //py: 4,
                          minHeight: "2vh",
                          border:
                            selectedCombineLogic === item.title
                              ? "1px solid #3F7D98"
                              : "1px solid #888888",
                          borderRadius: 1,
                          color:
                            selectedCombineLogic === item.title
                              ? "primary.main"
                              : "black",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "primary.main",
                          },
                        }}
                        onClick={() => {
                          setSelectedCombineLogic(item.title);
                          setSelectedLogicDesc(item.desc);
                        }}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(-1)}
                        startIcon={
                          <ImageIcon
                            style={{
                              color:
                                isHovered === index
                                  ? "white"
                                  : selectedCombineLogic === item.title
                                    ? "#3F7D98"
                                    : "black",
                              //  marginBottom: "0.5vh",
                            }}
                          />
                        }
                      >
                        <Typography variant="body1">{item.title}</Typography>
                      </Button>
                    </Stack>
                  );
                })}
              </Stack>
              {selectedLogicDesc && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1">
                    {selectedCombineLogic === "Union"
                      ? " Includes"
                      : " Includes members that are in"}
                    <strong>{selectedLogicDesc}</strong>
                  </Typography>
                </Stack>
              )}
            </Box>
          )}
          {/* <Divider sx={{
            width: "100%",
            height: 1,
            backgroundColor: "common.bg1",
            mt: 3,
            mb: 1
          }} /> */}
          <Stack
            direction={"row"}
            gap={1.5}
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                maxHeight: "5vh",
              }}
              disabled={
                (props?.isCombine && !selectedCombineLogic) ||
                !selectedCohort1 ||
                !selectedCohort2
              }
              variant="contained"
              size="small"
              onClick={() => {
                if (props?.isCombine) {
                  dispatch(setCohortType("combine"));
                  dispatch(setCombineLogic(selectedCombineLogic));
                } else {
                  dispatch(setCohortType("compare"));
                  dispatch(setCombineLogic(""));
                }

                dispatch(
                  setComparableFilters([selectedCohort1, selectedCohort2])
                );
                const title = props?.isCombine
                  ? "CohortCombine_" +
                    selectedCohort1.CohortName +
                    "_" +
                    selectedCohort2.CohortName
                  : "CohortCompare_" +
                    selectedCohort1.CohortName +
                    "_" +
                    selectedCohort2.CohortName;
                dispatch(setComparedCohortTitle(title));
                props?.setTabVal(props?.Temp);
                handleClose();
              }}
            >
              Apply
            </Button>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
export default CohortCompareDialog;
