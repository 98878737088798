import { EndpointBuilder } from "@reduxjs/toolkit/query";

const getJwtToken = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<
    any,
    {
      username: string;
      password: string;
    }
  >({
    query: ({ username, password }) => {
      return {
        url: "/oauth2/v1/token/jwttoken?grant_type=client_credentials",
        method: "POST",
        headers: {
          authorization: "Basic " + btoa(username + ":" + password),
        },
      };
    },
  });

export default getJwtToken;
