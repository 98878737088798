import { TypographyOptions } from "@mui/material/styles/createTypography";

// BASE VW : 1920 px
/**
 * sm - md - lg
 */

const typography: TypographyOptions = {
  fontFamily: ["Open Sans"].join(),
  fontSize: 14,
  htmlFontSize: 14,
  fontWeightBold: 700,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  h1: {
    // 28px font
    fontSize: `1.458vw`,
    fontWeight: 300,
    // ...generateResponsiveFonts({
    //   sm: 22,
    //   md: 24,
    //   lg: 26,
    // }),
  },
  h2: {
    // 26.25px font
    fontSize: "1.367rem",
    fontWeight: 700,
    // ...generateResponsiveFonts({
  },
  h3: {
    // 21
    fontSize: "1.094vw",
  },
  h3r: {
    // 21 font
    fontSize: "1.094vw",
    fontWeight: 400,
  },
  h4: {
    // 16 px
    fontSize: "0.833vw",
  },
  h4r: {
    // 16px
    fontSize: "0.833vw",
    fontWeight: "400",
  },
  h5: {
    // 14px
    fontSize: `0.729vw`,
    fontWeight: "light",
  },
  body1: {
    // 14px
    fontSize: "0.729vw",
  },
  body2: {
    // 14px
    fontSize: "0.729vw",
    fontWeight: "bold",
  },
  button: {
    // 17 px
    fontSize: `0.885vw`,
  },
  caption: {
    fontSize: "0.729vw",
    // lineHeight: pxToRem(16),
  },
  h6: {
    //11.38px
    fontSize: "0.593vw",
    fontWeight: 400,
  },
};

export default typography;
