import { useOktaAuth } from "@okta/okta-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetCohortData,
  setCohortType,
  setComparableFilters,
  setFilterSaved,
  setPatientCount,
} from "../../store/reducers/CohortReducer";
import AlertDialog from "../components/Dialogs/AlertDialog";

const NavContext = createContext();

export const useNav = () => {
  return useContext(NavContext);
};
//todo: make api call to get this userdata

export const NavProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location || {};
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/diagnosis-and-disease-staging" ||
      pathname === "/population-profiling" ||
      pathname === "/therapy-pathways"
    ) {
      dispatch(resetCohortData());
      dispatch(
        setPatientCount({
          patientCount: 0,
        })
      );
      dispatch(setFilterSaved(undefined));
      dispatch(setComparableFilters([]));
      dispatch(setCohortType(""));
    }
  }, [pathname]);

  
  const timeout = 900_000;
  const promptBeforeIdle = 60_000;
  const [NavDetails, setNavDetails] = useState();
  const [Tab, setTab] = useState(0);
  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setState("Idle");
    oktaAuth.signOut({
      postLogoutRedirectUri:
        window.__env.REACT_APP_AUTH_REDIRECT_URL ||
        `${window.location.origin}/logout`,
    });
    setOpen(false);
  };

  const onActive = () => {
    setState("Active");
    // setTimers(0)
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");

    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  const TabChangeHandler = (n) => {
    setTab(n);
  };
  useEffect(() => {
    setNavDetails({
      Tab: Tab,
      TabChange: TabChangeHandler,
    });
  }, [Tab]);

  return (
    <NavContext.Provider value={{ ...NavDetails }}>
      {open && (
        <AlertDialog
          title="Log Out"
          buttonName={"Log Out"}
          description={`You have been inactive for some time.You will be logged out in ${remaining} seconds.`}
          onButtonClick={onIdle}
          onClose={handleStillHere}
        />
      )}
      {children}
    </NavContext.Provider>
  );
};
