import { EndpointBuilder } from "@reduxjs/toolkit/query"

const getPatientData = (build: EndpointBuilder<any, any, any>) => build.query<any, { patientCount: number, filters: string }>({
    query: ({ patientCount, filters }) => ({
        url: '/patient_list',
        method: 'GET',
        params: { patient_count: patientCount, query: filters }
    })
})

export default getPatientData;