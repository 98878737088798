import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_ROUTES } from "../../routes/UserRoutes";
import DetailCard, { DetailCardProps } from "./Components/DetailCard";
import ToolFeedBack from "./Components/Feedback";

const LandingPage = () => {
  const nav = useNavigate();
  const [selectedItem, setSelectedItem] = useState<"query" | "insights" | "">(
    ""
  );

  const GROUP_DATA: DetailCardProps[] = [
    {
      title: "Navista™ Practice Explorer",
      description:
        "Access you pre-built patient cohorts,create and save custom patient cohorts, compare and analyse multiple cohorts, visualise and share data insights. Includes NLP Based query builder functionalities which is an alternate way to create cohorts by providing speech and text inputs.",
      image: "ic_querybuilder.svg",
      isSelected: false,
      onClick: () => {
        nav(USER_ROUTES.builder);
      },
    },
    {
      title: "Patient Insights Dashboards",
      description:
        "Pre-built use case driven dashboards to view insights and monitor pre-defined clinical, Operational, Financial KPIs.",
      image: "Component 169 – 1.svg",
      isSelected: selectedItem === "insights",
      onClick: () => {
        setSelectedItem("insights");
      },
    },
  ];

  const INSIGHTS_DATA = [
    {
      title: "Population Profiling",
      description:
        "Classifies a patient population across key categories, such as demographics, payers, histological categories etc., to summarize patients trends",
      image: "Group 2271.svg",
      onClick: () => {
        nav(USER_ROUTES.patient_profiling);
      },
    },
    {
      title: "Diagnosis and Disease Staging",
      description:
        "Delivers an insight into the prevalence of cancer types and stages across patient at practice",
      image: "Group 2270.svg",
      onClick: () => {
        nav(USER_ROUTES.diagnonis_staging);
      },
    },
    {
      title: "Therapy Pathways",
      description: "Highlights key therapies/treatments utilized at practice",
      image: "Group 2269.svg",
      onClick: () => {
        nav(USER_ROUTES.therapy_analysis);
      },
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(180deg, #FCFCFC, #ECECEC)",
        boxSizing: "border-box",
        pl: "8vw",
        pr: "5vw",
      }}
      gap={4}
    >
      <Stack direction={"row"} sx={{ justifyContent: "space-evenly" }}>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            maxWidth: "60%",
            flexDirection: "column",
            mt: "5vh",
          }}
          gap={3}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "primary.text",
                mb: 1,
              }}
              variant="h1"
            >
              Welcome to
            </Typography>
            <Typography
              sx={{
                color: "black",
              }}
              variant="h2"
            >
              Practice Intelligence Tool
            </Typography>
          </Box>
          <Typography
            sx={{ textAlign: "left", maxWidth: "80%", fontSize: "0.833vw" }}
            variant="h4r"
          >
            Tool to create and analyze user defined patient cohorts through a
            menu-driven method that allows dynamic selection to generate
            insights and drive actions related to specific population segments.
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "30%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="Elderly.svg"
            style={{ margin: "2vw", padding: "1vw" }}
            height="95%"
            width="95%"
            alt="aLOGO"
          ></img>
        </Box>
      </Stack>
      <Grid
        container
        gap={0.5}
        sx={{
          display: "flex",
          maxWidth: "55%",
          justifyContent: "space-between",
          flexDirection: "row",
          ml: "6vw",
        }}
      >
        {GROUP_DATA.map((item) => (
          <Grid sx={{ width: "48%" }}>
            <DetailCard {...item} />
          </Grid>
        ))}
      </Grid>
      {selectedItem === "insights" && (
        <Grid
          container
          gap={4}
          mt={4}
          sx={{
            display: "flex",
            maxWidth: "90%",
            flexDirection: "row",
            ml: "6vw",
          }}
        >
          {INSIGHTS_DATA.map((item) => (
            <Grid xs={3}>
              <DetailCard {...item} />
            </Grid>
          ))}
        </Grid>
      )}
      {/* Feedback for the tool */}
      <ToolFeedBack />
    </Box>
  );
};

export default LandingPage;
