import {
  Box,
  Card,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import { useGetFilterDataQuery } from "../../../services/modules/queryBuilder";
import { API_BASE_URL } from "../../constants";
import FilterItem from "./FilterSelectItem";
import { object } from "yup";

const FiltersModal = (props) => {
  const [F1Val, setF1Val] = React.useState("");
  const [F2Val, setF2Val] = useState([]);
  const [alignment, setAlignment] = React.useState("And With");
  const [distinctVals, setDistinctVals] = useState([]);
  const [filterSelected, setFilterSelected] = useState();
  const [MainfilterSelected, MainsetFilterSelected] = useState();
  const [changed, setChanged] = useState(true);
  const [isError, setError] = React.useState(false);
  const { data } = useGetFilterDataQuery();
  const [StaticData, setStaticData] = useState(data ?? {});
  const [checked, setChecked] = React.useState(false);
  const [distinctLoading, setDistinctLoading] = React.useState(false);

  const handleChangeNot = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeAlg = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  useEffect(() => {
    if (data) {
      setStaticData(data);
    }
  }, [data]);

  React.useEffect(() => {
    MainsetFilterSelected(props?.HeadFilterVal);
    setFilterSelected(props?.filterVal);

    if (props?.initialVal) {
      if (!props?.isGlobal) {
        setAlignment(props.initialVal["JoinBy"]);
        setF1Val(
          props.initialVal["condition"].includes("Not")
            ? props.initialVal["condition"].replace("Not", "").trim()
            : props.initialVal["condition"]
        );
        if (props.initialVal["condition"].includes("Not")) {
          setChecked(true);
        }
      } else {
        setF1Val(props.initialVal["condition"]);
      }
      setF2Val(props.initialVal["val"]);
    }
  }, [props]);

  const handleChange = (event) => {
    setChecked(false);
    setF1Val(event.target.value);
  };

  useEffect(() => {
    if (F1Val === "Is Blank") {
      setF2Val([]);
    }
    if (F1Val && (F1Val === "Is Blank" || F2Val) && alignment) {
      let k = false;
      if (F1Val === "Is Blank") {
        setChanged(false);
        return;
      }
      k =
        !F1Val ||
        (F1Val !== "Is Blank" && !F2Val) ||
        (F1Val === "Between" && (F2Val?.length < 2 || isError)) ||
        (F1Val !== "Is Blank" &&
          StaticData[MainfilterSelected][filterSelected]["types"] ===
          "Multi Select" &&
          F2Val?.length < 1) ||
        (F1Val !== "Between" &&
          StaticData[MainfilterSelected][filterSelected]["types"] ===
          "Date Range" &&
          (F2Val?.length < 1 || isError)) || //--
        (StaticData[MainfilterSelected][filterSelected]["types"] ===
          "Text Box" &&
          !F2Val);
      setChanged(k);
    }
  }, [F1Val, F2Val, alignment, isError]);

  const handleCloseApply = () => {
    let pload = {
      Main: MainfilterSelected,
      JoinBy: props?.isGlobal ? null : alignment,
      condition: checked ? `Not ${F1Val}` : F1Val,
      val: F2Val,
      type: StaticData[MainfilterSelected][filterSelected]["types"],
      ActualTable:
        StaticData[MainfilterSelected][filterSelected]["ActualTable"],
      ActualColumn:
        StaticData[MainfilterSelected][filterSelected]["ActualColumn"],
    };
    props?.ApplyFilter(props?.filterVal, pload);
    props?.onClose();
  };

  useEffect(() => {
    if (
      MainfilterSelected &&
      filterSelected &&
      StaticData[MainfilterSelected][filterSelected]["types"] === "Multi Select"
    ) {
      setDistinctLoading(true);
      const url = `${API_BASE_URL}/get_Distinct`;
      const formdata = new FormData();
      formdata.append(
        "Attribute_Group",
        StaticData[MainfilterSelected][filterSelected]["ActualTable"]
      );
      formdata.append(
        "Attribute_Name",
        StaticData[MainfilterSelected][filterSelected]["ActualColumn"]
      );
      formdata.append("Attribute_Type", "Multi Select");

      fetch(url, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("AppToken")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          let k =
            StaticData[MainfilterSelected][filterSelected]["ActualColumn"];
          let arr = [];
          data
            .sort((a, b) => b.count - a.count)
            .forEach((d) => {
              if (d[k]) {
                arr.push(d[k]);
              }
            });
          setDistinctVals(arr);
          setDistinctLoading(false);
        })
        .catch((error) => {
          setDistinctLoading(false);
          console.log(error);
        });
    }
  }, [MainfilterSelected, filterSelected]);

  return (
    <Box
      data-testid="filters-modal"
      sx={{
        minWidth: "100%",
        maxWidth: "100%",
        borderRadius: "6px",
      }}
    >
      <DialogContent
        sx={{
          p: 0,
          mb: 1,
          pb: 0,
          overflowX: "hidden",
        }}
      >
        <Card
          sx={{
            p: 2,
            mt: 2,
            boxShadow: "0px 3px 10px #00000014",
            border: "1px solid #C3C3C3",
            borderRadius: "1vh",
            minWidth: "100%",
            pb: 4,
            minHeight: "30vh",
          }}
        >
          {!props?.isGlobal &&
            ((props?.initialFilterCount && !props?.initialVal) ||
              (props?.initialVal && props?.filterIndex > 0)) && (
              <ToggleButtonGroup
                sx={{
                  maxHeight: "3vh",
                  mb: "2vh",
                  mt: "1vh",
                  borderRadius: "4px",
                  fontSize: "0.729vw",
                }}
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChangeAlg}
                aria-label="Platform"
              >
                <ToggleButton
                  sx={{
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontSize: "0.729vw",
                    p: 2,
                  }}
                  value="And With"
                >
                  And
                </ToggleButton>
                <ToggleButton
                  sx={{
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontSize: "0.729vw",
                    p: 2,
                  }}
                  value="OR With"
                >
                  Or
                </ToggleButton>
              </ToggleButtonGroup>
            )}

          {MainfilterSelected && filterSelected && (
            <FilterItem
              filter={StaticData[MainfilterSelected][filterSelected]["types"]}
              handleChange={handleChange}
              F1Val={F1Val}
              F2Val={F2Val}
              setF2Val={setF2Val}
              MainfilterSelected={MainfilterSelected}
              filterSelected={filterSelected}
              isLoading={distinctLoading}
              distinctVals={
                StaticData[MainfilterSelected][filterSelected]["types"] ===
                  "Multi Select"
                  ? distinctVals.sort((a, b) => (a > b ? 1 : -1))
                  : null
              }
              setError={setError}
            />
          )}
        </Card>
        {!props?.isGlobal &&
          MainfilterSelected &&
          filterSelected &&
          (StaticData[MainfilterSelected][filterSelected]["types"] ===
            "Text Box" ||
            (StaticData[MainfilterSelected][filterSelected]["types"] ===
              "Date Range" &&
              (F1Val === "Between" || F1Val === "Equal To"))) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeNot}
                    inputProps={{ "aria-label": "Not" }}
                  />
                }
                label="Exclude Selected Items"
              />
            </FormGroup>
          )}
      </DialogContent>
      <Stack
        direction={"row"}
        sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
      >
        {props?.isGlobal && (
          <Button
            sx={{
              py: "0.5vh",
              ml: "auto",
              mt: "1vh",
              fontSize: "0.729vw",
              borderRadius: "0",
            }}
            size="small"
            onClick={props?.onClose}
            variant="outlined"
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={changed}
          data-testid={props?.isGlobal ? "Add Criteria" : "Apply Criteria"}
          sx={{
            py: "0.5vh",
            ml: "1vw",
            mt: "1vh",
            fontSize: "0.729vw",
            borderRadius: "0",
          }}
          size="small"
          onClick={handleCloseApply}
          variant="contained"
        >
          Add Criteria
        </Button>
      </Stack>
    </Box>
  );
};

export default FiltersModal;
