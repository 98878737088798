import { apgeeApi } from "../../apgeeService";
import getToken from "./getToken";
import getMenu from "./getMenu";
import authenticateUser from "./authenticateUser";
import getFacilities from "./getFacilities";

export const apgeeService = apgeeApi.injectEndpoints({
  endpoints: (build) => ({
    getToken: getToken(build),
    getMenu: getMenu(build),
    authenticateUser: authenticateUser(build),
    getFacilities: getFacilities(build),
  }),
});

export const {
  useGetTokenMutation,
  useLazyGetMenuQuery,
  useLazyAuthenticateUserQuery,
  useLazyGetFacilitiesQuery,
} = apgeeService;
