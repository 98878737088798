import React, { FC, useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer/PageContainer";
import {
  Box,
  Button,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import SearchBar from "../../../components/Search/SearchBar";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CreateGroup from "../Components/CreateGroup";
import GroupTable from "./GroupDataTable";
import {
  useDeleteGroupMutation,
  useGetRolesQuery,
  useLazyGetGroupsQuery,
  useModifyGroupMutation,
  useGetPracticesQuery,
} from "../../../../services/modules/admin";
import { IGroupDetails } from "../../../../services/modules/admin/getGroups";
import { GroupFormData } from "../../../../services/modules/admin/modifyGroup";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/configureStore";

interface GroupsProps {}

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const Groups: FC<GroupsProps> = () => {
  const [open, setOpen] = useState(false);
  const { data: roles = [] } = useGetRolesQuery();
  const { data: practices = [] } = useGetPracticesQuery();

  const [getGroupData, { isLoading, data: groupsRaw = [] }] =
    useLazyGetGroupsQuery();
  const [saveGroup, { isLoading: saveGroupLoading }] = useModifyGroupMutation();
  const [deleteGroup, { isLoading: deleteGroupLoading }] =
    useDeleteGroupMutation();
  const { userName: currentUser } = useSelector(
    (state: RootState) => state.LoginReducer
  );

  const [groupData, setGroupData] = useState<IGroupDetails[]>([]);
  const [editData, setEditData] = useState<IGroupDetails | null>(null);
  const [filters, setFilters] = useState({
    search: "",
    practice: "",
    role: "",
  });
  // fetch groups
  useEffect(() => {
    getGroupData();
  }, []);

  //Set group data on
  useEffect(() => {
    setGroupData(groupsRaw);
  }, [groupsRaw]);

  const onFilter = () => {
    let filterData = [...groupsRaw];
    // search filter
    if (filters.search) {
      const searchRegex = new RegExp(escapeRegExp(filters.search), "i");
      filterData = filterData?.filter((row: any) => {
        return Object.keys(row).some((field: any) => {
          return searchRegex.test(row[field]?.toString());
        });
      });
    }
    // practice filter
    if (filters.practice) {
      filterData = filterData.filter((row: IGroupDetails) => {
        return row.PracticeTeam === filters.practice;
      });
    }
    // role filter
    if (filters.role) {
      filterData = filterData.filter((row: IGroupDetails) => {
        return row.Role === filters.role;
      });
    }
    setGroupData(filterData);
  };

  useEffect(() => {
    onFilter();
  }, [filters.search, filters.practice, filters.role]);

  useEffect(() => {
    // clear edit data when form closes
    if (!open && editData) {
      setEditData(null);
    }
  }, [open]);

  //Group Modifications

  const onGroupCreate = async (groupData: GroupFormData) => {
    const result = await saveGroup(groupData);
    if ("error" in result && result) {
      enqueueSnackbar(
        groupData.isnew ? "Group Creation failed" : "Group Update failed ",
        {
          variant: "error",
        }
      );
    } else {
      const { data } = result;
      if (data.code === 200) {
        setOpen(false);
        getGroupData();
      }
      enqueueSnackbar(data.msg, {
        variant: data.code > 200 ? "error" : "success",
      });
    }
  };

  const onGroupDelete = async (groupData: IGroupDetails) => {
    const result = await deleteGroup({
      group_name: groupData.id,
      email: currentUser,
    });
    if ("error" in result) {
      enqueueSnackbar("Group deletion failed", {
        variant: "error",
      });
    } else {
      const { data } = result;
      if (data.code === 200) {
        setOpen(false);
        getGroupData();
      }
      enqueueSnackbar(data.msg, {
        variant: data.code > 200 ? "error" : "success",
      });
    }
  };

  return (
    <PageContainer>
      <Typography variant="h2">View all Group Details</Typography>
      <Box>
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid gap={2} container mt={2}>
            <FormGroup>
              <FormLabel>Search</FormLabel>
              <SearchBar
                onSearch={(t) => {
                  setFilters({
                    ...filters,
                    search: t,
                  });
                }}
                containerSX={{
                  minWidth: "50vh",
                  mt: 0,
                }}
                placeholder="Search groups"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Practice</FormLabel>
              <Select
              aria-label="practice-select"
                sx={{ height: "36px", minWidth: "30vh" }}
                value={filters.practice}
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    practice: v.target.value,
                  });
                }}
                inputProps={{ "data-testid": "practice-select"}}
              >
                {practices?.map((practice) => (
                  <MenuItem value={practice}>{practice}</MenuItem>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <FormLabel>Role</FormLabel>
              <Select
              aria-label="role-select"
                sx={{ height: "36px", minWidth: "30vh" }}
                value={filters.role}
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    role: v.target.value,
                  });
                }}
                inputProps={{ "data-testid": "role-select"}}
              >
                {roles?.map((role) => (
                  <MenuItem value={role}>{role}</MenuItem>
                ))}
              </Select>
            </FormGroup>
            <Button
              sx={{ mt: 2 }}
              onClick={() => {
                setFilters({
                  search: "",
                  role: "",
                  practice: "",
                });
                setGroupData(groupsRaw);
              }}
            >
              Clear filters
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              sx={{ mt: 2 }}
              startIcon={<GroupAddIcon />}
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Group
            </Button>
          </Grid>
        </Grid>

        <GroupTable
          groups={groupData}
          isLoading={isLoading}
          onEdit={(edit: IGroupDetails) => {
            setEditData(edit);
            setOpen(true);
          }}
          onDelete={(d: IGroupDetails) => {
            onGroupDelete(d);
          }}
        />
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          maxWidth="sm"
          fullWidth
          id="create-group-dialog"
          aria-label="create-group-dialog"
        >
          <Box
            sx={{
              border: "1px solid #EAEBEA",
              margin: "1.5vw",
              minWidth: "30vw",
              borderRadius: "6px",
              padding: 2,
            }}
          >
            <CreateGroup
              onClose={() => {
                setOpen(false);
              }}
              editData={editData}
              onGroupCreate={onGroupCreate}
            />
          </Box>
        </Dialog>
      </Box>
    </PageContainer>
  );
};

export default Groups;
