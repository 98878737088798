import { EndpointBuilder } from "@reduxjs/toolkit/query";
import { baseNPLUrl } from "../..";

const getCohortDescription = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<any, string>({
    query: (payload) => ({
      url: `${baseNPLUrl}/filter_summary`,
      method: "POST",
      body: payload,
    }),
  });

export default getCohortDescription;
