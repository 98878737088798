import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Grid, Stack, Typography, styled } from "@mui/material";

const ImageBox = styled(Box)({
  height: "75%",
  width: "15%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export interface DetailCardProps {
  title: string;
  description: string;
  image: string;
  onClick: () => any;
  isSelected?: boolean;
}

const DetailCard = ({
  title,
  description,
  isSelected,
  image,
  onClick,
}: DetailCardProps) => {
  return (
    <Stack
      sx={(theme) => ({
        cursor: "pointer",
        boxShadow: "0px 2px 6px #00000026",
        borderRadius: "6px",
        display: "flex",
        pt: "2vh",
        // px: "0.5vw",
        backgroundColor: "white",
        height: "98%",
        width: "100%",
        // alignItems: "center",
        border: isSelected ? `1px solid ${theme.palette.primary.main}` : "",
        "&:hover": {
          "& .MuiSvgIcon-root": {
            color:
              title === "Patient Insights Dashboards"
                ? theme.palette.primary.main
                : "transparent",
          },
        },
        "& .MuiSvgIcon-root": {
          color: isSelected ? "primary" : "transparent",
        },
      })}
      onClick={onClick}
    >
      <Box sx={{ height: "95%" }}>
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", pb: 2, px: "0.5vw" }}
          gap={1}
        >
          <ImageBox>
            <img src={image} height="85%" alt="aLOGO"></img>
          </ImageBox>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.primary,
              fontSize:"0.833vw"
            })}
            variant="h4r"
          >
            {title}
          </Typography>
        </Stack>
        <Stack px={"1vw"}>
          <Typography sx={{ textAlign: "left" }} variant="body1">
            {description}
          </Typography>
        </Stack>
      </Box>
      <ArrowDropDownIcon
        color="primary"
        sx={{
          fontSize: "2.5vw",
          alignSelf: "center",
          alignContent: "center",
        }}
      />
    </Stack>
  );
};

export default DetailCard;
