import { EndpointBuilder } from "@reduxjs/toolkit/query";

const getRoles = (build: EndpointBuilder<any, any, any>) =>
  build.query<string[], void>({
    query: () => ({
      url: "/get_role",
      method: "GET",
    }),
  });

export default getRoles;
